import { createApp } from 'vue';

export function add_vueApp2(id, component, to_use = null) {
    const ele = document.getElementById(id);
    if (ele) {
        const props = {};
        for (const [key, value] of Object.entries(ele.dataset)) {
            try {
                props[key] = JSON.parse(value);
            } catch (e) {
                props[key] = value;
            }
        }
        const app = createApp(component, props);

        if (to_use) {
            for (const plugin of to_use) {

                app.use(plugin);
            }
        }


        app.mount('#' + id);
    }
}