<script>
import { ref, defineComponent, watch } from 'vue';

import moment from 'moment';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';


export default defineComponent({
  name: 'oj-date-picker',
  props: {
    name: String,
    value: String
  },
  components: {
   VueDatePicker
  },
  setup(props) {
    const parsedDate = moment(props.value, 'DD.MM.YYYY HH:mm').toDate();
    console.log('datum: ',props.value, parsedDate);
    const date = ref(parsedDate);
    const name = ref(props.name);


    return {

      date, name

    };
  }
});
</script>

<template>
  <VueDatePicker v-model="date" :format="`d.M.yyyy  HH:mm`  "  locale="cs" />
 <input :name="name" :value="date" type="hidden"/>
</template>

<style scoped>
</style>