<script>
import { VueFinalModal } from 'vue-final-modal'
import modalKkMixin from './modal-kk-mixin'
export default {
    mixins:[modalKkMixin],
  name: "modal-publicate"

  ,data(){
    return({
      showM: false,
      publishType: 'now',
      publishTime: '',
      error:''
    })
  },
  methods:{
    showModal(){
      this.showM=true
    },
    hideModal(){
      this.showM=false
    },
    publicate(){

      if (this.publishType!='now' && (this.publishTime=='' || this.publishTime==null)){
        this.error = "Není vyplněný čas zveřejnění"
        return
      }

      this.error=''


      console.log('publicate', this.publishType, this.publishTime)
      this.$emit('submit', this.publishType, this.publishTime)
    },



  }
}
</script>

<template>
<vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f">
    <div class="modal-header">
      <b>Zveřejnit rozvrh </b>
        <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>

    </div>

    <div class="modal__content">

      <div v-if="error!=''" class="alert alert-danger">
        {{error}}
      </div>

      <label><input type="radio" v-model="publishType" value="now" /> Zveřejnit hned</label>

      <label><input type="radio" v-model="publishType" value="later" /> Nastavit datum a čas zveřejnění</label>

      <input type="datetime-local"  class="form-control"  v-model="publishTime"/>

  <div class="text-center mt-3">
    <button class="btn btn-can-public" @click.prevent="publicate">
      <span class="material-icons-outlined mia">watch_later</span>Zveřejnit</button>
  </div>

    </div>

</vue-final-modal>
</template>

<style scoped>
label{
  display: block;
  margin-bottom: 10px;
}

</style>