


import Courses from '@/components/oj/courses/courses.vue';
import Course from '@/components/oj/courses/course.vue';
import Structure from '@/components/oj/courses/structure.vue';
import CourseOptions from "@/components/oj/courses/course-options.vue";
import Lessons from "@/components/oj/courses/lessons.vue";

const routes = [
    { path: '/oj/courses/:cid', component: Structure, props: true },
    { path: '/oj/courses/:cid/options', component: CourseOptions, props: true },
    { path: '/oj/courses/:cid/week/:wid/lessons', component: Lessons, props: true, name: 'lessons' },

];

export default routes;