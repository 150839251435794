<script >
//import CoolLightBox from 'vue-cool-lightbox'
//import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

// upload nekolika obrazku budou tam nahledy
export default {
  name: "FInputImage",
  props: ['options', 'item'],
  components: {
   //   CoolLightBox
  },
  data(){
    return({
      images: [],
      imagesfm:[],
      lb_items:[],
      lbimages1: [   ],
      index: null


    })
  },
  mounted(){
    //console.log('mounted', this.item[this.options.codename+'_storages'])
    this.lb_items = this.lbimages()
    //console.log('mounted', this.lb_items)
  },

  methods:{
    handleUploadImage(){
      // aktifuje input typu filee a potom uploaduje obrazky
      document.getElementById("myFile").click();

    },
    onUploadFileInputChange(){
      // zpracovani obrazku
      const files = document.getElementById('myFile').files;
      if (files.length > 0) {
        this.createImage(files[0]);
        //this.item[this.options.codename] = files

      }
      console.log('emituju', this.imagesfm)
      this.$emit('change', this.imagesfm)
      this.item[this.options.codename]=this.imagesfm

    },
    createImage(file) {
      // vytvori nahled obrazku
      const image = new Image();
      const reader = new FileReader();
      const vm = this;
      reader.onload = (e) => {
        vm.images.push(e.target.result)
      };
      reader.readAsDataURL(file);
      this.imagesfm.push(file)


    },
    lbimages(){
      // vraci pole obrazku pro modal
      let lbimages = []
      for (let i = 0; i < this.item[this.options.codename+'_storages'].length; i++) {
        lbimages.push({
          thumb: this.item[this.options.codename+'_storages'][i].thumb_url,
          src: this.item[this.options.codename+'_storages'][i].url,
          description: this.item[this.options.codename+'_storages'][i].original_filename
        })
      }
      return lbimages
    }

  }
}
</script>

<template>
<div class="form-group">
  <label>{{options.label}}  </label>



    <CoolLightBox
      :items="lb_items"
      :index="index"
      @close="index = null">
    </CoolLightBox>

  <div class="fg-images">
    <figure v-for="image in images">
      <img :src="image" alt="image" width="200px" height="200px" />
    </figure>


    <figure v-for="(img,i) in item[options.codename+'_storages']" :key="img.id">
      <img :src="img.thumb_url" :alt="img.original_filename" width="200px" height="200px"    :key="i"    @click="index = i"/>
    </figure>

    <div class="clearfix" ></div>
    <input type="file" id="myFile" name="filename" @change="onUploadFileInputChange"/>
    <button @click.prevent="handleUploadImage">Add image</button>
  </div>
</div>
</template>

<style scoped>
#myFile{ visibility: hidden; height:0; width: 100%;}
</style>