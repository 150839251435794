<template>
  <vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f mcw1">

  <div class="modal-header">
  <span class="modal__title">Detail lekce

  </span>

      <div class="ml-auto ">
          <span v-if="lesson.public" class="badge badge-success">veřejné</span>
          <span v-else class="badge badge-danger">neveřejné</span>
      </div>

      <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
      </button>


  </div>
  <div class="modal__content" >
    <div class="row">
      <div class="col-md-6">
        <div>Úroveň kurzu</div>

        <level-switch :level="lesson.level" v-on:update="v => {lesson.level = v}" v-if="admin" :disabled="!editable"></level-switch>
          <div v-else>
              <span  :class="`badge badge-primary big`" >Level {{lesson.level}}</span>
          </div>


      </div>
      <div class="col-md-4">
        Datum
        <select class="form-control" v-model="lesson.day" :disabled="disabledLector || !editable">
          <option v-for="d in wdays" :value="d.format('YYYY-MM-DD')">{{d.format('dddd  DD.MM.')}}</option>
        </select>
      </div>
      <div class="col-md-2">
        Čas
        <select class="form-control" v-model="lesson.hour" :disabled="disabledLector || !editable">
          <option v-for="i in 14" :value="i+7">{{i+7}}:00</option>
        </select>
      </div>

    </div>

  <div class="row mt-3">
    <div class="col-md-6">
      <div class="form-group">
        <label>Téma klubu</label>
        <select class="form-control" v-model="lesson.conversation_club_topic_id" :disabled="disabledLector || !editable">
          <option></option>
          <option v-for="t in topics" :value="t.id">#{{t.numer}} {{t.name}}</option>
        </select>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label>Kapacita</label>
        <input type="number" v-model="lesson.capacity" class="form-control w-auto" :disabled="disabledLector || !editable"/>
      </div>

    </div>

    <div class="col-md-6">
      <div class="form-group"><label>Lektor</label>
      <select class="form-control" v-model="lesson.lector_id" :disabled="!editable">
        <option v-for="t in lectors" :value="t.id">{{t.first_name }}  {{t.last_name }}</option>
      </select>
      </div>
    </div>




    <div class="col-md-12 mt-3">
      <label><input type="checkbox"  v-model="lesson_expand" v-if="admin" :disabled="!editable"/>  Při uložení propsat do všech lekcí stejné úrovně</label>
    </div>
    <div class="col-md-12">
      <label><input type="checkbox" v-model="lesson.native_speaker" :disabled="disabledLector || !editable"/> Rodilý mluvčí</label>
    </div>

  </div>


    <div v-if="v_minulosti">

      <div class="mt-2"><strong>Průběh lekce</strong></div>

      <div><label><input type="radio" v-model="lesson.lector_status" value="ok" @change="lectorStatusChanged"/>    Lekce proběhla</label></div>

      <div><label><input type="radio" v-model="lesson.lector_status" value="skipped" @change="lectorStatusChanged"/> Studenti se nedostavili</label></div>


    </div>

  <div class="mt-3 h5">Studenti ({{lesson_students.length}}/{{lesson.capacity}})</div>


  <div class="d-flex" v-if="admin">
    <div style="min-width: 70%">
      <v-select v-model="selected_student" @search="onSearch" :filterable="false" :options="options" >

        <template #option="option">
          <div class="d-center row">
            <div class="col-1">
              <img :src="option.profile_image_url" v-if="option.profile_image_url" class="icoproduct">

            </div>
            <div class="col-5">{{ option.email }}</div>
            <div class="col-6">{{ option.name }} {{ option.surname }}</div>
          </div>
        </template>
        <template #selected-option="option">
          <div class="selected d-center">
            <img :src="option.profile_image_url" v-if="option.profile_image_url" class="icoproduct"> {{ option.email }}
          </div>
        </template>
      </v-select>
    </div>
    <button class="btn btn-outline-dark btn-sm ml-2"
            @click.prevent="addStudent"
            :disabled="volnaPole() < 1"
            v-if="editable">Přidat studenta</button>
  </div>

    <table class="table table-striped mt-2">
      <tr v-for="s in lesson_students">
        <td>{{s.name}} {{s.surname}}</td>
        <td>{{s.email}}</td>
        <td><a v-if="admin" href="#" @click.prevent="removeStudent(s)"><span class="material-symbols-outlined">delete</span></a></td>
      </tr>
      <tr v-for="i in volnaPole()"  >
        <td><i>volné</i></td>
        <td></td>
        <td></td>
      </tr>
    </table>


</div>

  <div class="modal__action">
    <button  class="btn btn-primary" @click="handleSaveChanges">Uložit změny</button>
    <button class="btn btn-outline-dark ml-4" @click="handleCancel">zrušit</button>
      <button class="btn btn-outline-danger ml-4" @click="handleRemoveLesson" v-if="lesson.id>0" :disabled="!admin">Smazat lekci</button>
  </div>
  </vue-final-modal>
</template>

<script>
import axios from "axios";
import LevelSwitch from "./level-switch.vue";
import modalKkMixin from "./modal-kk-mixin.js";
import _ from "lodash";
import moment from "moment";
import vSelect from 'vue-select'
import {toast} from "vue3-toastify";
export default {
  components: {LevelSwitch, vSelect},
  props:['lesson', 'close', 'wdays', 'lesson_students', 'admin'],
  name: "lesson-form",
  mixins:[modalKkMixin],
  data(){
    return {
      selected_student:{},
      options:[],
      topics:[],
      lectors:[],
      lesson_expand: false

    }
  },
  computed:{
    disabledLector(){
      return !this.admin
    },

    editable(){
      if (this.lesson.day==null || this.lesson.hour==null) return true
      let tl = moment(`${this.lesson.day} ${this.lesson.hour}:00:00`).unix()+3600
      let ted = moment().unix()
      return (ted < tl)
    },
    v_minulosti(){
      let tl = moment(`${this.lesson.day} ${this.lesson.hour}:00:00`).unix()+(45*60)
      let ted = moment().unix()
      console.log('minulost? ', tl, ted, (ted - tl)/60)
      return (ted > tl)
    }
  },

  watch:{
    'lesson.level': 'reloadSelectItems',
  },
  async mounted () {
    this.reloadSelectItems();
  },
  methods:{
    lectorStatusChanged(){
    },
    updateLectorStatus(){
      axios.put('/conversation_club/lessons/update-lector-status',{lesson_id: this.lesson.id, lector_status: this.lesson.lector_status}).then(resp=>{
        this.$emit('close')
        this.$emit('calendarUpdated')
        this.hideModal()
      })
    },

    handleRemoveLesson(){
      if (confirm("Opravdu smazat?")){

        axios.delete(`/conversation_club/remove-lesson/${this.lesson.id}`).then( response =>{

          this.$emit('calendarUpdated')
          this.$emit('close')
          this.hideModal()


        }).catch(err => {

          toast('Chyba: '+ err.response.data.errors.join('<br>'), {type: 'error', dangerouslyHTMLString: true});

        })

      }
    },


    async reloadSelectItems(){
      const response = await axios.get('/conversation_club/topics/index.json', { params:{
          level: this.lesson.level
        }})
      this.topics = response.data.items

      const r2 = await axios.get('/conversation-clubs/lectors.json',{ params:{
          //level: this.lesson.level
          per: 100
        }})
      this.lectors = r2.data.items

    },


    volnaPole(){
      if (!this.lesson.capacity) return 0
      if (parseInt(this.lesson.capacity)<=this.lesson_students.length) return 0
      return parseInt(this.lesson.capacity) - this.lesson_students.length
    },

    handleCancel(){
      this.$emit('close')
      this.hideModal()
    },

    addStudent(){

      for(let i in this.lesson_students){
        if (this.lesson_students[i].id == this.selected_student.id){
          this.selected_student = {}
          return
        }
      }

      this.lesson_students.push(
          {
            id: this.selected_student.id,
            name: this.selected_student.name,
            surname: this.selected_student.surname,
            email: this.selected_student.email
        })
      this.selected_student = {}


    },

    removeStudent(s){

      if (confirm(`Opravdu smazat ${s.first_name ? s.first_name:''}, ${s.nickname ? s.nickname : s.email} ? (student se odebere ihned, i bez potvrzování změn lekce)`)) {

        axios.delete(`/conversation_club/lessons/${this.lesson.id}/student/${s.id}`).then(response => {

          for (let i = 0; i < this.lesson_students.length; i++) {
            if (s.id == this.lesson_students[i].id) {
              this.lesson_students.splice(i, 1)
            }
          }

          this.$emit('calendarUpdated')
        })

      }

    },

    onSearch(search, loading) {
      if(search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },

    search: _.debounce((loading, search, vm) => {
      fetch(
          `/conversation-clubs/students?q=${escape(search)}`
      ).then(res => {
        res.json().then(json => (vm.options = json.items));
        loading(false);
      });
    }, 350),

    handleSaveChanges(){
      if (this.v_minulosti){
        // lektor uklada stav prubehu
        return this.updateLectorStatus()
      }


      const data = {
        lesson_expand: this.lesson_expand,
        lesson: this.lesson,
        students: this.lesson_students
      }

      axios.post('/conversation-clubs/update-lesson', data).then( response =>{
        this.$emit('calendarUpdated')
        this.$emit('close')
        this.hideModal()
      }).catch(err => {

        toast('Chyba: '+ err.response.data.errors.join('<br>'), {type: 'error', dangerouslyHTMLString: true});
      })
    }


  }
}
</script>

<style scoped>



.icoproduct{
  width:40px;
  height:40px;
}

</style>