<script>
import {VueFinalModal} from "vue-final-modal";
import FInputCategory from "../fields/FInputCategory.vue";
import draggable from 'vuedraggable'
function generateRandomString(length) {
  const characters = 'abcdefghijklmnopqrstuvwxyz';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}
export default {
  name: "FGInputCategory",
  props: ['options'] ,
  components: {
    VueFinalModal, FInputCategory, draggable
  },
  data(){
    return({
      showM: false,
      new_item:'',
      optskye:28800,
      color_list: [
      "F3F3F3" ,    "FFD1D1",    "CFFCC7",    "B5E3FF",    "FAF2C4",    "E3D6FF",    "FFD5B8",
    "BDF0ED",    "C8C8C8" ,    "FF7373" ,    "8FDE6B" ,    "7DC7FF",    "FFD652" ,    "CCADFF" ,    "FF8B38" ,    "36C2CC" ]
    })
  },
  methods:{
    hideModal(){
      this.showM=false

    },
    addCatItem(){
      this.options.items.push( {
        t: this.new_item.toString(),
        color:"FAF2C4",
        value: this.getMaxVal()+1,
        objid: generateRandomString(10)
      })
      this.new_item=''
    },
    getMaxVal(){
      let max=0
      for (let i in this.options.items){
        if (this.options.items[i].value){
          if (this.options.items[i].value > max){
            max = this.options.items[i].value
          }
        }
      }
      return max
    },

    removeItem(it){

      for(let i=0; i< this.options.items.length; i++){
        if (this.options.items[i] == it){
          this.options.items.splice(i,1);
          break;
        }
      }

    },
    showColorpikr(el){
      console.log(';showColorpikr', el);
      const e = this.$refs[  `colop${el.objid}` ]
      e[0].style.display=''
    },

    onPickColor(it, color){
      console.log('onColorPick',it, color)
      it.color=color
      const e = this.$refs[  `colop${it.objid}` ]
      e[0].style.display='none'
      this.optskye+=1
    },
    cancelPickColor(it){
      const e = this.$refs[  `colop${it.objid}` ]
      e[0].style.display='none'
    }

  },
  mounted() {
    if (this.options.multiple == undefined) this.options.multiple = false
    for(let i=0; i< this.options.items.length; i++) {
      if (this.options.items[i].objid == undefined)  this.options.items[i].objid = generateRandomString(10)+i
    }
    this.optskye+=1
  },
  watch: {

  }
}
</script>

<template>
  <div class="row">



        <div class=" col-md-2">

          <div class="form-group">
            <input type="text" class="form-control" v-model="options.label"/>
          </div>

          <div class="form-group">
            <label>
              <input type="checkbox" v-model="options.multiple" /> Multiple
            </label>
          </div>


          <div class="form-group">
            <label class="cb"><input type="checkbox" name="required" v-model="options.required" /> Required field </label>
          </div>



        </div>

        <div class="form-group col-md-10" :key="optskye">


          <draggable v-model="options.items" handle=".handle2">
            <div v-for="it in options.items" class="category-item-ed d-flex"  >
              <div class="handle2"><span class="material-icons-outlined">drag_indicator</span></div>
              <input type="text" v-model="it.t" class="border-0 form-control h-auto" />

              <div class="ml-auto">
                <div class="color-sample" :style="`background-color: #${it.color}`" @click.prevent="showColorpikr(it)" >
                  <ul class="colorpicker1" style="display: none" :ref="`colop${it.objid}`">
                    <li v-for="c in color_list" :style="`background: #${c}`"  @click.prevent.stop="onPickColor(it, c)" :class="`${c==it.color ? 'selected': ''}`"></li>
                    <li class="cancel" @click.prevent.stop="cancelPickColor(it)" title="zrusit">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="1" y1="19" x2="19" y2="1" stroke="black" stroke-width="1"/>
                        <line x1="1" y1="1" x2="19" y2="19" stroke="black" stroke-width="1"/>
                      </svg>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="ml-2">
                <a @click.prevent="removeItem(it)" href="#" class="delete delete-category"><span class="material-symbols-outlined">delete</span></a>

              </div>
            </div>
            </draggable>

          <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="Kategorie" v-model="new_item">
            <div class="input-group-append">
              <button class="btn btn2 btn-success" type="button" @click.prevent="addCatItem">Add</button>
            </div>
          </div>


        </div>




  </div>
</template>

<style scoped>
  li.selected{
    border: 2px solid #000;
  }
  .handle2{
    cursor: move;
    margin-top: 5px;
  }
</style>