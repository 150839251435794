<script>
import axios from 'axios'
export default {
  name: "dashboard-eshops",
  props: ['month', 'year'],
  data() {
    return ({
      eshops: [],
      total_sum: 0,
      total_sum_year_ago: 0
    })
  },
  mounted() {
    this.loadEshops()
  },
  methods: {
    loadEshops() {
      axios.get('/students_stats/dashboard-eshops.json', {params: {month: this.month, year: this.year}}).then(response => {
        this.eshops = response.data.eshops

        this.total_sum = this.eshops.reduce((acc, e) => acc + e.sum, 0)
        this.total_sum_year_ago = this.eshops.reduce((acc, e) => acc + e.sum_year_ago, 0)


      })
    }
  }

}
</script>

<template>
  <table class="minitable">
  <thead>
    <tr>
      <th>Name</th>
      <th>{{ year }}</th>
      <th>{{ year - 1}}</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="e in eshops">
      <td>{{ e.prefix }}</td>
      <td>{{ e.sum }}</td>
      <td>{{ e.sum_year_ago }}</td>
    </tr>


  </tbody>
    <tfoot>
      <tr>
        <td>Total</td>
        <td>{{ total_sum }}</td>
        <td>{{ total_sum_year_ago }}</td>
      </tr>
    </tfoot>
</table>
</template>

<style scoped>
tfoot{
  border-top: 1px solid black;
}
tfoot td {

  font-weight: bold;
}
</style>