<script>
import studentStatsMixin from './student-stats-mixin'
export default {
  props: ['stats'],
  name: "top-courses-table",
  mixins: [studentStatsMixin],

  methods: {
    /*
{
	"0": {
		"month": "2024-08-01",
		"courses": [
			{
				"id": 1,
				"month": "2024-08-01",
				"course_id": 473,
				"students_count": 421,
				"created_at": "2024-08-24T15:07:28.249+02:00",
				"updated_at": "2024-08-24T15:07:28.266+02:00",
				"course_name": "Angličtina pro začátečníky A1-"
			},
			{
				"id": 4,
				"month": "2024-08-01",
				"course_id": 469,
				"students_count": 319,
				"created_at": "2024-08-24T15:07:28.317+02:00",
				"updated_at": "2024-08-24T15:07:28.324+02:00",
				"course_name": "Angličtina pro mírně pokročilé B1-"
			},
			{
				"id": 31,
				"month": "2024-08-01",
				"course_id": 468,
				"students_count": 189,
				"created_at": "2024-08-24T16:36:13.608+02:00",
				"updated_at": "2024-08-24T16:36:13.618+02:00",
				"course_name": "Angličtina pro mírně pokročilé B1+"
			},
			{
				"id": 35,
				"month": "2024-08-01",
				"course_id": 471,
				"students_count": 179,
				"created_at": "2024-08-24T16:36:13.676+02:00",
				"updated_at": "2024-08-24T16:36:13.682+02:00",
				"course_name": "Angličtina pro znalé začátečníky A2-"
			},
			{
				"id": 34,
				"month": "2024-08-01",
				"course_id": 472,
				"students_count": 166,
				"created_at": "2024-08-24T16:36:13.660+02:00",
				"updated_at": "2024-08-24T16:36:13.666+02:00",
				"course_name": "Angličtina pro začátečníky A1+"
			},
			{
				"id": 37,
				"month": "2024-08-01",
				"course_id": 470,
				"students_count": 162,
				"created_at": "2024-08-24T16:36:13.706+02:00",
				"updated_at": "2024-08-24T16:36:13.712+02:00",
				"course_name": "Angličtina pro znalé začátečníky A2+"
			},
			{
				"id": 10,
				"month": "2024-08-01",
				"course_id": 511,
				"students_count": 148,
				"created_at": "2024-08-24T15:07:28.424+02:00",
				"updated_at": "2024-08-24T15:07:28.430+02:00",
				"course_name": "Španělština pro začátečníky A1"
			},
			{
				"id": 8,
				"month": "2024-08-01",
				"course_id": 453,
				"students_count": 140,
				"created_at": "2024-08-24T15:07:28.391+02:00",
				"updated_at": "2024-08-24T15:07:28.398+02:00",
				"course_name": "Němčina pro začátečníky A1-"
			},
			{
				"id": 36,
				"month": "2024-08-01",
				"course_id": 467,
				"students_count": 134,
				"created_at": "2024-08-24T16:36:13.691+02:00",
				"updated_at": "2024-08-24T16:36:13.697+02:00",
				"course_name": "Angličtina pro pokročilé B2-"
			},
			{
				"id": 38,
				"month": "2024-08-01",
				"course_id": 520,
				"students_count": 107,
				"created_at": "2024-08-24T16:36:13.728+02:00",
				"updated_at": "2024-08-24T16:36:13.734+02:00",
				"course_name": "Angličtina pro pokročilé B2+"
			},
			{
				"id": 32,
				"month": "2024-08-01",
				"course_id": 476,
				"students_count": 100,
				"created_at": "2024-08-24T16:36:13.629+02:00",
				"updated_at": "2024-08-24T16:36:13.635+02:00",
				"course_name": "Italština pro začátečníky A1-"
			},
			{
				"id": 2,
				"month": "2024-08-01",
				"course_id": 510,
				"students_count": 55,
				"created_at": "2024-08-24T15:07:28.277+02:00",
				"updated_at": "2024-08-24T15:07:28.284+02:00",
				"course_name": "Němčina pro znalé začátečníky A2"
			},
			{
				"id": 40,
				"month": "2024-08-01",
				"course_id": 646,
				"students_count": 40,
				"created_at": "2024-08-24T16:36:13.759+02:00",
				"updated_at": "2024-08-24T16:36:13.765+02:00",
				"course_name": "Angličtina pre začiatočníkov A1-"
			},
			{
				"id": 9,
				"month": "2024-08-01",
				"course_id": 506,
				"students_count": 40,
				"created_at": "2024-08-24T15:07:28.408+02:00",
				"updated_at": "2024-08-24T15:07:28.414+02:00",
				"course_name": "Španělština pro znalé začátečníky A2"
			},
			{
				"id": 7,
				"month": "2024-08-01",
				"course_id": 642,
				"students_count": 23,
				"created_at": "2024-08-24T15:07:28.373+02:00",
				"updated_at": "2024-08-24T15:07:28.379+02:00",
				"course_name": "Angličtina pre mierne pokročilých B1-"
			},
			{
				"id": 33,
				"month": "2024-08-01",
				"course_id": 463,
				"students_count": 13,
				"created_at": "2024-08-24T16:36:13.644+02:00",
				"updated_at": "2024-08-24T16:36:13.650+02:00",
				"course_name": "Angličtina pro žáky 3. třídy"
			},
			{
				"id": 3,
				"month": "2024-08-01",
				"course_id": 509,
				"students_count": 13,
				"created_at": "2024-08-24T15:07:28.298+02:00",
				"updated_at": "2024-08-24T15:07:28.306+02:00",
				"course_name": "Francouzština pro znalé začátečníky A2"
			},
			{
				"id": 5,
				"month": "2024-08-01",
				"course_id": 420,
				"students_count": 12,
				"created_at": "2024-08-24T15:07:28.334+02:00",
				"updated_at": "2024-08-24T15:07:28.341+02:00",
				"course_name": "Angličtina pro experty C2"
			},
			{
				"id": 39,
				"month": "2024-08-01",
				"course_id": 462,
				"students_count": 6,
				"created_at": "2024-08-24T16:36:13.743+02:00",
				"updated_at": "2024-08-24T16:36:13.749+02:00",
				"course_name": "Angličtina pro žáky 4. třídy"
			},
			{
				"id": 6,
				"month": "2024-08-01",
				"course_id": 421,
				"students_count": 1,
				"created_at": "2024-08-24T15:07:28.354+02:00",
				"updated_at": "2024-08-24T15:07:28.361+02:00",
				"course_name": "NJ - Slovíčka pro vedení lidí B1"
			}
		]
	}
}*/


    trend(month, index) {
      let current = this.stats[month].courses[index - 1].students_count;
      let cid = this.stats[month].courses[index - 1].course_id;
      let ten_produktvminulemmesici = this.stats[month + 1].courses.find(x => x.course_id === cid);

      if (ten_produktvminulemmesici == null) {
        return "---";
      }
      let previous = ten_produktvminulemmesici.students_count;
      if (previous == null) {
        return "-";
      }
      let v = (100 * (current - previous) / current).toFixed(1);

      return `${v}%`;
    }
  }
}
</script>

<template>

  <div class="card card-body">
    <table class="table table-bordered" v-if="stats[0] && stats[1].courses.length>1 && stats[2].courses.length>1">
<thead>
      <tr>
        <td></td>
        <th colspan="3">{{mesic_z_datumu(stats[0].month)}}</th>
        <th colspan="3">{{mesic_z_datumu(stats[1].month)}}</th>
        <th colspan="3">{{mesic_z_datumu(stats[2].month)}}</th>
      </tr>

      <tr>
        <td></td>
        <td>Kurz</td>
        <td>počet</td>
        <td></td>

        <td>Kurz</td>
        <td>počet</td>
        <td>trend</td>

        <td>Kurz</td>
        <td>počet</td>
        <td>trend</td>


      </tr>

</thead>
      <tbody>
      <tr v-for="index in 20" :key="index">
        <td>{{index}}</td>
        <td>{{stats[0].courses[index-1].course_name}}</td>
        <td>{{stats[0].courses[index-1].students_count}}</td>
        <td></td>


        <td>{{stats[1].courses[index-1].course_name}}</td>
        <td>{{stats[1].courses[index-1].students_count}}</td>
        <td>{{ trend(1, index) }}</td>

        <td>{{stats[2].courses[index-1].course_name}}</td>
        <td>{{stats[2].courses[index-1].students_count}}</td>
        <td>{{ trend(2, index) }}</td>

      </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>

</style>