<script>

export default {
  name: "FGInputText",
  props: ['options'] ,
  data(){
    return{

    }

  },
  methods:{

  }
}
</script>

<template>
  <div><div class="form-group">
    <label>Label</label>
    <input type="text" class="form-control" v-model="options.label"/>
  </div>
    <div class="form-group">
      <label class="cb"><input type="checkbox" name="required" v-model="options.required" /> Required field </label>
    </div>
  </div>

</template>
