<script>

  import Multiselect from 'vue-multiselect'
export default {
  props: ["collection", "label", "name", 'value', 'name_col'],
  name: "oj-multiselect",
  components: { Multiselect },
  data() {
    return {
      value1: [],
    }
  },
  mounted() {

    this.value1 = []


    if (this.value ) {
      this.collection.forEach((c) => {
        if (this.value.indexOf(c.id) !== -1) {
          this.value1.push({id: c.id, name: c[this.name_col || 'name']})
        }

      })
    }


    if (this.name_col!='name' && this.name_col){
      this.collection.forEach((c) => {
          c.name = c[this.name_col]
      })
    }

  }
}
</script>

<template>
<label for="group_lecturer_ids" class="label" v-if="label">{{ label  }} </label>
  <input type="hidden" :name="name" value=""/>
  <select :name="name" multiple="multiple" style="visibility: hidden; height:0;position:absolute;"><option v-for="v in value1" selected :value="v.id">{{v.name}}</option></select>
    <multiselect v-model="value1"
                 :options="collection"
                 track-by="id"
                 label="name"
                 :multiple="true"
                 :close-on-select="true"></multiselect>

</template>

<style scoped>

</style>