<script>

import axios from "axios";

export default {
  name: "FGRelation",
  props: ['options'] ,
  components: {
  },
  data(){
    return({
      apps: []
    })
  },
  methods:{
    loadApps(){
      axios.get(`/formgenerator/forms`).then(response=>{
            this.apps = response.data.apps
      })
    }


          },
  mounted(){
    this.loadApps()
  }
}

</script>

<template>
<div class="row">
    <div class="form-group cl-md-6">
      <label>Label</label>
      <input type="text" class="form-control" v-model="options.label"/>
    </div>

    <div class="form-group col-md-6">
      <label>Related app</label>
      <select v-model="options.rel_app" class="form-control">
        <option v-for="a in apps" :value="a.codename">
          {{a.name}}
        </option>
      </select>
    </div>

  <div class="form-group col-md-12">
    <label class="cb"><input type="checkbox" name="required" v-model="options.required" /> Required field </label>
  </div>

</div>
</template>

<style scoped>

</style>