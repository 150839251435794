<template>
  <a href='#' @click.prevent="show_student" class="student-detailinfo"
     data-student="<%=@student.to_json%>" style="background: #ACB1C0; padding:5px;">i</a>



<vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f w600">

    <div class="modbod">
        <pre :key="aa">{{ student_info }}</pre>
      </div>
</vue-final-modal>

</template>

<script>

const cols=[

"id", "name",  "surname","email", "mailing","status","created_at", "updated_at","id_study","active_campaign_contact_id",
    ,"entry_form_done",
    "monthly_allowance",
    "language_id",
    "courses_dirty",
    "ac_profile_dirty",
    "ac_courses_dirty",
    "affiliate_partner",
    "affiliate_id",
    "brand",
    "new_trial",
    "new_course",

    "valid_email",
    "ac_sync_failed",
    "profile_dirty",
    "source",
    "partner_id",
    "partner_company",
    "new_gift",
    "allow_sending_credentials",
    "sort_order",
    "should_send_activation", 'courses_helper'

]
import {VueFinalModal} from "vue-final-modal";
export default {
  props:['student'],
  components: {VueFinalModal},

  name: "StudentsTable",
  data(){
    return({
      aa:111,
      showM:false,
      student_info:{}
    })
  },
  computed:{

  },
  methods:{
    show_student(){
      this.showM=true
      this.student_info_gen(this.student)
    },

    show_student_modal(student){
      this.student_info_gen(student)
      this.aa++;
    },

    student_info_gen(student){

      var arr={}
      for (let n in cols){
        let c = cols[n]
        //console.log('c', c, this.student[c])
        arr[c] = student[c];
      }
      this.student_info=student  // arr
      return arr
    }
  }
}
</script>

<style scoped>
.modbod{
  text-align: left;
  height:450px;
  overflow-y: scroll;
  margin:20px;
  background: #e0e0e0;
}
</style>