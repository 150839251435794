<script>


import modalKkMixin from "./conversation-club/modal-kk-mixin";
import axios from "axios";
export default {
    mixins:[modalKkMixin],
    name: "marketing-detail",
    props:['row', 'eshops'],
    data(){
      return {
        stats:{
          samostatne: {count:0, amount:0},
          mesicne: {count:0, amount:0},
          pulrocne: {count:0, amount:0},
          rocne: {count:0, amount:0},
          ostatni: {count:0, amount:0}

        }
      }
    },
  watch:{
      row(){
        this.loadStats()
      }


  },
  methods:{
    loadStats(){
      this.stats = {
        samostatne: {count:0, amount:0},
        mesicne: {count:0, amount:0},
        pulrocne: {count:0, amount:0},
        rocne: {count:0, amount:0},
        ostatni: {count:0, amount:0}
      }

      let eshops = []
      for( let i in this.eshops){
          eshops.push(this.eshops[i].symbol)
      }

      axios.get(`/marketing/orders-month-detail/${this.row.m_date}`, {params:{
        eshops: eshops.join(',')
        }} ).then(response =>{
        this.stats = response.data.stats
      })

    }

  }


}
</script>

<template>
  <vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f">
    <div class="modal-header">
      <b>Podrobnosti za období</b><button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    {{row}}

    <table>
      <tr>
        <th></th>
        <th>celkový počet objednávek</th>
        <th> částka za tyhle objednávky</th>
      </tr>
      <tr>
        <th>samostatných kurzů</th>
        <td>{{stats.samostatne.count}}</td>
        <td>{{stats.samostatne.amount }}</td>

      </tr>

        <tr>
          <th>měsíčního předplatné</th>
          <td>{{stats.mesicne.count}}</td>
          <td>{{stats.mesicne.amount}}</td>
        </tr>
        <tr>
          <th>půlročního předplatné</th>
          <td>{{stats.pulrocne.count}}</td>
          <td>{{stats.pulrocne.amount}}</td>
        </tr>
        <tr>
          <th>ročního předplatné</th>
          <td>{{stats.rocne.count}}</td>
          <td>{{stats.rocne.amount}}</td>

        </tr>
        <tr>
          <th>ostatní</th>
          <td>{{stats.ostatni.count}}</td>
          <td>{{stats.ostatni.amount}}</td>

        </tr>


    </table>




  </vue-final-modal>
</template>

<style scoped>

</style>