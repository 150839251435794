import moment from "moment/moment";

const lessonFormMixin={
   methods:{

     wdays(){

       let s = this.current_week_start.format('YYYY-MM-DD')
       let days=[]
       var d = moment(s).startOf('week');


       for(let i=0; i<7; i++){
         days.push(d.clone())
         d.add(1,'day')
       }
       return days
     },

   }


}
export default lessonFormMixin