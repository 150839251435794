<template>
  <vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f w600">
    <div class="modal-header">
      <b>Seznam lekcí studenta</b>
        <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>


      <div class="row">
          <div class="col-md-12">
              <h5>Budoucí lekce</h5><div class="towf">
              <table class="table table-condensed">
                <tr v-for="l in bude">
                    <td>{{df(l.day)}}</td>
                    <td  class="text-right">{{l.hour}}:00</td>
                    <td><span :class="`badge badge-kk level${l.level}`">{{l.level}}</span> </td>
                </tr>
              </table></div>
          </div>
          <div class="col-md-12">
              <h5>Absolvované lekce</h5>
            <div class="towf">
              <table class="table table-condensed">
                <tr v-for="l in bylo">
                    <td><a href="#" @click.prevent="handleLessonClicked(l)">{{df(l.day)}}</a></td>
                    <td class="text-right">{{l.hour}}:00</td>
                    <td><span :class="`badge badge-kk level${l.level}`">{{l.level}}</span> </td>
                </tr>
              </table>

            </div>

          </div>
      </div>


      <lesson-detail-student ref="modalLessonStudent"
                             :lesson="current_l"
                             :lesson_students="lesson_students"
                             :student_id="student.id"
                             @subscriptionsChanged="onSubscriptionsChanged"
                             ></lesson-detail-student>

  </vue-final-modal>
</template>

<script>
import modalKkMixin from "./modal-kk-mixin";
import {VueFinalModal} from "vue-final-modal";
import axios from "axios";
import LessonDetailStudent from "./lesson-detail-student.vue";
import moment from "moment";
export default {
  components: {LessonDetailStudent, VueFinalModal },
  name: "modalSudentLessons",
  mixins:[modalKkMixin],
  data(){
    return {
      current_l:{},
      lesson_students:[],
      bylo: [],
      bude:[],
      student:{}
    }
  },
  methods:{
    onSubscriptionsChanged(){
      this.showStudent(this.student)
    },

    handleLessonClicked(l){
      this.current_l=l

      if (l.id > 0) {
        axios.get(`/conversation-clubs/lesson/${l.id}`).then(response => {
          this.lesson_students = response.data.students
        })
      }

      if (this.user_id>0) {
        //this.$refs.modelLesson.showModal()
      }else{
        this.$refs.modalLessonStudent.showModal()
      }
    },



    showStudent(s){
      axios.get(`/conversation_club/students/${s.id}/lessons` ).then( response =>{
        this.bylo = response.data.bylo
        this.bude = response.data.bude
        this.student = response.data.student
      })
      this.showModal()
    }
  }
}
</script>

<style scoped>
.towf{
  max-height: 400px;
  overflow-y: auto ;
}
</style>