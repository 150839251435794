<script>
import axios from 'axios'
export default {
  data(){
    return {
      week:["Po", "Út", "St", "Čt", "Pá", "So", "Ne"],
      stats:{},
      week_stats:{},
      alltime_stats: {},
      d1:'',
      current_week_start:'2023-11-27',
      loading: false,

      fill_rate_in_week:{},
      levels_all: {},
      levels_12w:{},
      levels_now:{},
      timeslots_12w:{},
      timeslots_all: {}
    }

  },

  methods: {

    loadStats(){
      this.loading = true
      axios.get('/conversation_club/stats/lesson_fill_rate', {params:{ week: this.current_week_start}}).then(r => {

        this.alltime_stats = r.data.all_time_stats
        this.week_stats = r.data.week_time_stats
        this.d1 = r.data.now_start_day
        /* hotovo: hotovo,
       all_time_stats: get_all_time_stats_timeslots,
       week_time_stats: fill_rate_timeslots_in_week(d1)*/


        /* fill_rate_in_week:'',
      levels_all: {},
      levels_12w:{},
    levels_now:{},
        timeslots_12w:{},
    timeslots_all: {}*/
        this.levels_all = r.data.levels_all
        this.levels_12w = r.data.levels_12w
        this.levels_now = r.data.levels_now

        this.timeslots_12w = r.data.timeslots_12w
        this.timeslots_all = r.data.timeslots_all
        this.fill_rate_in_week = r.data.fill_rate_in_week


        this.loading = false
      })
    }
  }
  ,
  mounted() {
    this.loadStats()
  }


}

</script>

<template>
<!--<div>-->





<!--  zobrazeni dat , tyden {{d1}} na zkousku: <pre>-->


<!--      fill_rate_in_week: {{fill_rate_in_week}}-->
<!--      levels_all: {{levels_all}}-->
<!--      levels_12w: {{levels_12w}}-->
<!--      levels_now: {{levels_now}}-->
<!--      timeslots_12w:{{timeslots_12w}}-->
<!--      timeslots_all: {{timeslots_all}}-->


<!--  </pre>-->
<!--</div>-->

  <div class = "container-fluid float-left">
    <img v-if="loading" :src="`/img/1495.gif`" alt="loading" />
    <div class="row">

      <div class="col-md-auto box">
        <h4 class="title">TENTO TÝDEN</h4>
        <div class="card card-body">
          <table class="table table-bordered">
            <thead>
            <tr>
              <th> </th>
              <th class="title">DEN</th>
              <th class="title">ČAS</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in fill_rate_in_week">
              <td class="title">{{ fill_rate_in_week.indexOf(item)+1 }}.</td>
              <td class="title">{{ week[parseInt(item.wday)-1] }}</td>
              <td class="title">{{ item.hour }}:00</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="col-md-auto box">
        <h4 class="title">POSLEDNÍCH 12 TÝDNŮ</h4>
        <div class="card card-body">
          <table class="table table-bordered">
            <thead>
            <tr>
              <th> </th>
              <th class="title">DEN</th>
              <th class="title">ČAS</th>
              <th class="title">PLNO</th>
              <th class="title">OBSAZENOST</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in timeslots_12w">
              <td class="title">{{ timeslots_12w.indexOf(item)+1 }}.</td>
              <td class="title">{{ week[parseInt(item.den)-1] }}</td>
              <td class="title">{{ item.hodina }}:00</td>
              <td class="title">{{ item.kolikrat_naplnen.toString() }}/{{ item.kolikrat_vypsan.toString() }}</td>
              <td class="title">{{ item.obsazenost }}%</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="col-md-auto box">
        <h4 class="title">CELKEM</h4>
        <div class="card card-body">
          <table class="table table-bordered">
            <thead>
            <tr>
              <th> </th>
              <th class="title">DEN</th>
              <th class="title">ČAS</th>
              <th class="title">PLNO</th>
              <th class="title">OBSAZENOST</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in timeslots_all">
              <td class="title">{{ timeslots_all.indexOf(item)+1 }}.</td>
              <td class="title">{{ week[parseInt(item.den)-1] }}</td>
              <td class="title">{{ item.hodina }}:00</td>
              <td class="title">{{ item.kolikrat_naplnen.toString() }}/{{ item.kolikrat_vypsan.toString() }}</td>
              <td class="title">{{ item.obsazenost }}%</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="col-md-auto box">
        <div class="row inner_box1">
          <div class="col-md-auto">
            <h4 class="title">TENTO TÝDEN</h4>
            <div class="card card-body">
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th class="title">ÚROVEŇ</th>
                  <th class="title">OBSAZENOST</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in levels_now">
                  <td class="title">{{ item.level }}</td>
                  <td class="title">{{ item.fill_rate_index }}%</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row inner_box2">
          <div class="col-md-auto">
            <h4 class="title">12 TÝDNŮ</h4>
            <div class="card card-body">
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th class="title">ÚROVEŇ</th>
                  <th class="title">OBSAZENOST</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in levels_12w">
                  <td class="title">{{ item.level }}</td>
                  <td class="title">{{ item.fill_rate_index }}%</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row inner_box2">
          <div class="col-md-auto">
            <h4 class="title">CELKEM</h4>
            <div class="card card-body">
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th class="title">ÚROVEŇ</th>
                  <th class="title">OBSAZENOST</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in levels_all">
                  <td class="title">{{ item.level }}</td>
                  <td class="title">{{ item.fill_rate_index }}%</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<style scoped>

.title {
  text-align: center;
}

.box {
  padding: 15px 15px 15px 15px;
}

.inner_box1 {
  padding: 0 15px 15px 0px;
}

.inner_box2 {
  padding: 15px 15px 15px 0px;
}

</style>