<template>
  <span>

    <button class="btn btn-outline-primary" @click.prevent="showM=true">hromadné prodloužení expirace</button>

      <vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f w800">

        <div class="m-head p-3">
          <a class="close"  href=# @click.prevent="showM=false"><span class="material-icons-outlined">close</span></a>
          <h4>Hromadné prodloužení expirace</h4>
        </div>

        <div class="modbod p-3">
          <div class="d-flex">
            <input type="date" id="batch-date-preset" class="form-control" style="" v-model="batch_expiration" />
            <button class="btn-outline-info btn btn-sm" @click="update_expirations">Nastavit</button>
          </div>
          <div><i>Prázdné datum nastaví nekonečnou expiraci</i></div>
        </div>
      </vue-final-modal>
  </span>
</template>

<script>
import axios from "axios";
import {toast} from "vue3-toastify";
import {VueFinalModal} from "vue-final-modal";
export default {
  components: {VueFinalModal},
  name: "organization-helpers",
  props:['org'],
  data(){ return {
      batch_expiration: '',
    showM: false
    }
  },
  mounted() {
    const csfr_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csfr_token;
    axios.defaults.withCredentials=true
  },
  methods:{
    update_expirations(){
      const url = `/organizations/${this.org.id}/assignments/batch-update-expiration`
      axios.put(url, { date: this.batch_expiration }).then(response => {

        if (response.status == 200){

          toast('Nastavení se spustilo', {type: 'success'});
          //this.$modal.hide('modall')
          this.showM = false

        }else{

          toast('nic', {type: 'error'});
        }

      }).catch((r)=>{

        toast('chyba: '+ r.response.data.error , {type: 'error'});

      })
    }
  }
}
</script>


<style scoped>
.modal {
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

}

h4{
  margin: 0;
  font-size: 1.5rem;
  font-weight: 500;
}

.m-head {
  background-color: #e0e0e0;
  border-bottom: 1px solid #ccc;
}

.modbod {
  padding: 20px;
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: white;
}
</style>