<template>
  <vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f w800">
    <div class="modal-header">
      <b>Detail studenta - info o balíčku a předplatném</b>
        <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

      <table>
        <thead>
          <tr>
            <th>Objednávka</th>
            <th>Produkt</th>
            <th>Délka</th>
            <th>Start</th>
            <th>Zaplaceno do</th>
          </tr>
        </thead>
        <tr v-for="s in subs">
            <td><a :href="`/subscriptions/${s.id}`">{{s.id_woo}}</a>  </td>
            <td>{{s.product.name}}</td>
            <td>{{s.months}}</td>
            <td>{{df(s.start)}}</td>
            <td>{{df(s.paid_until)}}</td>
        </tr>
      </table>


    <div v-if="student.cc_until">
      Manuálně nastavené datum platnosti do: {{df(student.cc_until)}}
    </div>


  </vue-final-modal>
</template>

<script>
import modalKkMixin from "./modal-kk-mixin";
import axios from "axios";

export default {
  name: "modal-student-kk-subs",

  mixins:[modalKkMixin],
  data(){
    return {
      subs: [],
      student: {}

    }
  },
  methods:{
    showStudent(s){
      this.student = s
      axios.get(`/conversation_club/students/${s.id}/subs` ).then( response =>{
        this.subs = response.data.subscriptions
      })

      this.showModal()

    }
  }
}
</script>

<style scoped>

</style>