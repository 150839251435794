<template>

    <div class="card card-body index p-0">
    <div class="d-flex ">

        <div class="py-2 mx-2 mt-2"> Filtrování:</div>
        <div class=" mt-2">
          <select class="form-control">
              <option>aktivní i neaktivní</option>
              <option>ještě aktivní</option>
              <option>už není aktivní</option>
          </select>
        </div>
        <div class="py-2 mx-2 mt-2"> Eshop:</div>
        <div class="mt-2">
          <select  class="form-control ">
              <option value="">Všechny</option>
              <option v-for="e in eshops" :value="e.symbol">{{e.json_name}}</option>
          </select>
        </div>


        <div class="ml-auto p-2">
          <button @click.prevent="handleAddCode" class="btn-primary btn">
                     přidat kód
          </button>
        </div>
    </div>

    <table class="table">
            <thead>
            <tr>
                <th colspan="" >Název kódu</th>

                <th>sleva</th>
                <th>sleva v %</th>
                <th>měna</th>
                <th>počet použití</th>
                <th>nastavení</th>
                <th>ID</th>
            </tr>
            </thead>
            <tr v-for="c in codes">
                <td>
                    {{c.name}}
                </td>

                <td>{{c.discount}}</td>
                <td>{{c.discount_percent}}</td>
                <td>{{c.currency}}</td>
                <td>
                  {{c.usage_counter}}/{{c.usage_limit}}
                </td>

                <td>
                  <a href="#" @click.prevent="handleEditCode(c)"><span class="material-icons-outlined">mode_edit_outline</span></a>

                  <a href="#" @click.prevent="destroyCode(c)" class=""><span  class="material-symbols-outlined smazat">delete</span></a>

                </td>

                <td>{{c.id}}</td>

            </tr>

        </table>


      <discount-code-form ref="modalDiscountForm" :code="dcode" :eshops="eshops" @codesChanged="handleCodesChanged"></discount-code-form>

  </div>




</template>

<script>



import axios from "axios";

import DiscountCodeForm from "./discount-code-form.vue";

const empty_code={
  name:'',
  reason:'',
  note:'',
  discount_percent: 0,
  discount: 0,
  start_date: '',
  end_date: '',
  kind: '',
  only_product: null,
  only_category: null,
  exclude_product: null,
  exclude_category:null,
  usage_limit: null,
  limit_student: null,
  disable_combine: true
}


export default {
  name: "discount-codes",
  components: {DiscountCodeForm},
  data() {
    return {
      dcode: {},
      codes:[],
      eshops:[],
    }
  },

  mounted() {

    this.loadCodes()

     axios.get('/api/woo/eshops.json' ).then(response =>{

        this.eshops = response.data.eshops
      })


  },
  methods: {
    destroyCode(c){
      if (!confirm('Opravdu smazat kód '+c.name+'?')) return

      axios.delete('/discount_codes/'+c.id+'.json').then(response =>{
        this.loadCodes()
      })
    },
    handleCodesChanged(){
      console.log('codes changed', this.dcode)
      this.loadCodes()
    },

    loadCodes(){
      axios.get('/discount_codes.json' ).then(response =>{

        this.codes = response.data.discount_codes
      })

    },

    handleAddCode(){
      this.dcode=Object.assign({},  empty_code)
      this.$refs.modalDiscountForm.showModal()

    },

    handleEditCode(c){
      this.dcode = c
      this.$refs.modalDiscountForm.showModal()
    }
  }
}
</script>



<style scoped>

.smazat{
  color: red;
}



</style>