<script>
import axios from "axios";
import {VueFinalModal} from "vue-final-modal";
import modalKkMixin from "../conversation-club/modal-kk-mixin";

export default {
  name: "dialog-send-notification",
  components: {VueFinalModal},
  props: ['token'],
  mixins: [modalKkMixin],
  data() {
    return ({
      organizations: [],
      msg_content:''
    })
  },
  mounted() {

  },
  methods:{
    handleSend(){

      const data={
        device_id: this.token.id,
        msg: this.msg_content
      }
      axios.post('/devices/send_notification', data).then(resp =>{


        this.msg_content=''
      })

    }
  }
}
</script>

<template>
  <vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f w600">
    <div class="modal-header">
      <b>Poslat zkušební notifikaci</b>
      <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal__content">
      <div class="form-group">
        <label>Obsah</label>
        <textarea v-model="msg_content" class="form-control"></textarea>
      </div>
    </div>
    <div class="modal__action">
      <button  class="btn btn-primary" @click="handleSend">Poslat</button>
      <button class="btn btn-outline-dark ml-4" @click="showM=false">zrušit</button>
    </div>
  </vue-final-modal>
</template>

<style scoped>

</style>