<script>
import axios from 'axios'
export default {
  name: "dashboard-konvergence",
  props: ['month', 'year'],
  data(){
    return {
      konvergence:[],
    }
  },
  mounted() {
    this.getKonvergence()
  },
  methods:{
    getKonvergence(){
      axios.get('/students_stats/dashboard_konvergence.json', {params: { month: this.month, year: this.year }})
      .then(response => {
        this.konvergence = response.data.konvergences;
      })
      .catch(error => {
        console.log(error);
      });
    }

  }
}
</script>

<template>
  <table class="minitable">
    <thead>
    <tr>
      <th>Zdroj</th>
      <th>{{ year }}</th>
      <th>{{ year - 1}}</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="e in konvergence">
      <td>{{ e.source }}</td>
      <td>{{ e.k }}</td>
      <td>{{ e.k_year_ago }}</td>
    </tr>


    </tbody>
    <tfoot>
    </tfoot>
  </table>
</template>

<style scoped>

</style>