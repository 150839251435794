<script>
export default {
  name: "SortWords",
  props: ['lesson'],
}
</script>

<template>

</template>

<style scoped>

</style>