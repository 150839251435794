<script>
import axios from "axios";
import {VueFinalModal} from "vue-final-modal";
import modalKkMixin from "../conversation-club/modal-kk-mixin";

export default {
  name: "modal-templates" ,
  components: { VueFinalModal },
  props:['student', 'kind'],
  mixins:[modalKkMixin],
  data(){
    return({
      content: '',
      nema:'',
      current_tpl: {},
      editting: false,
      templates:[]
    })
  },
    mounted() {
      this.loadTemlplates()
    },
    methods:{
    addTemplate(){
      this.current_tpl = {}
      this.editting = true
    },
    editTemplate(t){
      this.current_tpl = t
      this.editting = true
      this.name = new String( t.name)
      this.content = new String(t.content)
      this.pretext = new String(t.pretext)
    },

      loadTemlplates(){
        axios.get(`/ai_chat_templates?kind=${this.kind}`).then(response =>{
          this.templates = response.data.ai_chat_templates
          for(let i in this.templates){
            if (this.templates[i].active==true) this.$emit('detectTemplate', this.templates[i])
          }
          this.editting=false
        })
      },

      saveChanges(){
        this.current_tpl.name = this.name
        this.current_tpl.content = this.content
        this.current_tpl.pretext = this.pretext

        const data ={
            ai_chat_template: this.current_tpl
        }
        data.ai_chat_template.kind = this.kind
        if (this.current_tpl.id>0){
            axios.put(`/ai_chat_templates/${this.current_tpl.id}`, data).then(response =>{
                this.current_tpl = response.data.ai_chat_template
                this.editting=false
            })
        }else{
            axios.post('/ai_chat_templates', data).then(response =>{
                this.current_tpl = response.data.ai_chat_template
                this.editting=false
                this.loadTemlplates()
            })
        }
      },

      onCheckboxActive(t){

        if (t.active){
          for(let i in this.templates){
            if (this.templates[i].id != t.id) this.templates[i].active=false
          }

        }

        const data ={
          ai_chat_template: { active: t.active }
        }

        axios.put(`/ai_chat_templates/${t.id}`, data).then(response =>{
          this.$emit('detectTemplate', t)
        })


        console.log('Akt cb', t.id, t.active)
      },

      destroyTemplate(t){
        if (confirm("Opravdu smazat?")){
          axios.delete(`/ai_chat_templates/${t.id}`).then(response =>{
             this.loadTemlplates()
          })
        }

      }

  }
}
</script>

<template>
  <vue-final-modal drag
    v-model="showM"
    classes="modal-container"
    content-class="modal-content-f w800"
  >
    <div class="modal-header">
      <b>Šablony AI dotazů</b>
      <button
        type="button"
        class="close"
        @click.prevent="hideModal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal__content">

        <div class="text-right mb-2" v-if="!editting">
            <button @click.prevent="addTemplate" class="btn btn-primary"><span class="material-icons mia">add_circle</span> Přidat novou šablonu</button>
        </div>

      <div class="tplform" v-if="editting">
        <div class="form-group">
          <label>Název</label>
          <input type="text" class="form-control" v-model="name" />
        </div>

        <div class="form-group">
          <label>Povinná část, (neměnit)</label>
          <textarea
              class="form-control"
              rows="2"
              v-model="pretext"
          ></textarea>
        </div>

        <div class="form-group">
          <label>Obsah</label>
          <textarea
            class="form-control"
            rows="6"
            v-model="content"
          ></textarea>
        </div>

        <div class="text-right">
          <button class="btn btn-primary" @click.prevent="saveChanges">
            Uložit změny
          </button>
            <button class="btn btn-default" @click.prevent="editting=false">Zrušit</button>
        </div>
            <div v-if="kind == 'vocabulary'">

            </div>
      </div>

      <div v-else>
        <table class="table table table-striped">
            <thead>
              <tr>
                  <td>ID</td>
                  <td>Název</td>
                  <td>Aktivní</td>
                  <td></td>

              </tr>
            </thead>
          <tr v-for="t in templates">
              <td>{{t.id}}</td>
              <td :class="t.active ? 'active' : ''">{{t.name}}</td>
              <td><input type="checkbox" @change="onCheckboxActive(t)" v-model="t.active"/></td>
              <td><a href=# title='Upravit' @click.prevent="editTemplate(t)"><span class="material-icons-outlined">mode_edit_outline</span></a></td>
              <td><a href=# title='Smazat' @click.prevent="destroyTemplate(t)"><span class="material-icons-outlined">delete</span></a></td>
          </tr>
        </table>
      </div>
    </div>
  </vue-final-modal>
</template>

<style scoped>
td.active{
    font-weight: bold;
}

</style>