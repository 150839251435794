<script>
import axios from 'axios';
import ModalKkMixin from '@/components/conversation-club/modal-kk-mixin.js';
export default {
  name: "exercise-type",
  mixins: [ModalKkMixin],
  props:['exercise_type', 'week'],
  data(){
    return {
      tab:'obsah',
      exercise_types:[],
      content_types:[],
    }
  },
  mounted(){
    this.loadTypes();
  },
  methods:{
    loadTypes(){
      axios.get('/oj/lessons/content_types.json')
      .then(response => {
        this.exercise_types = response.data.exercise_types;
        this.content_types= response.data.content_types;
      })
      .catch(error => {
        console.log(error);
      });
    },

  }
}
</script>

<template>

  <vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f w90">
    <div class="modal-header">
      <b>Novy obsah nebo cviceni</b>
      <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal__content" id="wizardContentTypeSelect">


      <ul class="nav nav-tabs">
        <li :class="tab === 'obsah' ? 'active':''"><a data-toggle="tab" href="#content-editor" @click.prevent="tab='obsah'">Obsah</a></li>
        <li :class="tab === 'cv' ? 'active':''"><a data-toggle="tab" href="#excersise-editor" @click.prevent="tab='cv'">Cvičení</a></li>
        <li :class="tab === 'slov' ? 'active':''"><a data-toggle="tab" href="#vocabulary-editor" @click.prevent="tab='slov'">Slovíčka</a></li>
        <li :class="tab === 'fraze' ? 'active':''"><a data-toggle="tab" href="#phrase-editor" @click.prevent="tab='fraze'">Fráze</a></li>
      </ul>

      <div v-if="tab=='obsah'">

      <ul class="thumbnails" >
        <li class="span4" v-for="t in content_types">
          <div class="thumbnail">
            <div class="caption">
              <h4>{{t.name}}</h4>
              <p class="description">{{ t.description }}</p>
              <p class="actions">
                <a href="#" class="btn3 btn-primary">               Vybrat            </a>
              </p>
            </div>
          </div>
        </li>
      </ul>

      </div>
      <div v-if="tab=='cv'">

        <ul class="thumbnails" >
          <li class="span4" v-for="t in exercise_types">
            <div class="thumbnail">
              <div class="caption">
                <h4>{{t.name}}</h4>
                <p class="description">{{ t.description }}</p>
                <p class="actions">
                  <a href="#" class="btn3 btn-primary">               Vybrat            </a>
                </p>
              </div>
            </div>
          </li>
        </ul>

      </div>

    </div>
  </vue-final-modal>

</template>

<style scoped>
.span4{
    width: 31%;
    margin-right: 2%;
    float: left;
    list-style: none;

}

.thumbnail .caption {
    padding: 9px;
    color: #555;
  text-align: center;

}

#wizardContentTypeSelect .thumbnails li {
    margin: 0 10px 10px 0;
}
#wizardContentTypeSelect .thumbnails .thumbnail h4 {
    text-align: center;
    margin: 0 0 5px 0;
}
#wizardContentTypeSelect .thumbnails .thumbnail {

    height: 150px;
    padding: 10px;
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;

}




</style>