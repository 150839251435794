<script>

  import Multiselect from 'vue-multiselect'



export default {
  components: { Multiselect },
  name: "organization-group-supervisors-component",
  props:['supervisors', 'lecturers', 'hrmanagers'],
  data(){
    return({
      value1: [],
      value2: [],
    })
  },
  mounted() {
    console.log('Component mounted.', this.hrmanagers, this.lecturers)
    this.value1 =[]
    this.hrmanagers.forEach((v) => {
      this.value1.push({id: v.id, name: `${v.name} ${v.surname}`})
    })


    this.value2 = []
    this.lecturers.forEach((v) => {
      this.value2.push({id: v.id, name: `${v.name} ${v.surname}`})
    })

  }
}
</script>

<template>
  <div class="col-md-6">
    <label class="label">Personalisté</label>
    <multiselect v-model="value1"
                 track-by="id"
                 :options="supervisors"
                 label="name"
                 :multiple="true"
                  :close-on-select="true"></multiselect>
    <input type="hidden" name="group[hr_manager_ids][]" value=""/>
    <select name="group[hr_manager_ids][]"
            id="group_hr_manager_ids"
            multiple="multiple" style="visibility: hidden">
      <option v-for="v in value1" selected :value="v.id">{{v.name}}</option></select>
  </div>
  <div class="col-md-6">
    <label for="group_lecturer_ids" class="label">Lektoři</label>
    <multiselect v-model="value2"
                 :options="supervisors"
                   track-by="id"
                 label="name"
                 :multiple="true"
                 :close-on-select="true"></multiselect>

    <input type="hidden" name="group[lecturer_ids][]" value=""/>
    <select name="group[lecturer_ids][]"
            id="group_lecturer_ids"
            multiple="multiple" style="visibility: hidden">
      <option v-for="v in value2" selected :value="v.id">{{v.name}}</option></select>

  </div>

</template>

<style scoped>

</style>