<script>
export default {
  name: "gopay-return-kk"
}
</script>

<template>
<div class="container item topdiv">
  <div class="text-center item colourBackground">

    <div class="text-center item">
      <img style="height:220px" :src="`/img/logo.png`"/>
    </div>

    <div class="text-center item">
      <h5>Vaše objednávka byla úspěšně zaplacena.</h5>
    </div>

    <div class="text-center item">
      <h5>Jste-li nový uživatel, přihlašovací údaje dorazí během pár minut na Váš email.</h5>
      <h2 style="padding-top: 20px">Pro přihlášení <a href="https://kk.onlinejazyky.cz/" target=”_blank”>klikněte sem.</a><br></h2>
    </div>

    <div class="text-center item">
      <h5>Tyto údaje slouží také k přihlašování do naší studovny:</h5>
      <h5><a href="https://www.onlinejazyky.cz/" target=”_blank”>www.onlinejazyky.cz</a></h5>
      <h5>Najdete tam Váš nově aktivovaný kurz <b>Příprava na konverzační klub</b>, který je výborným doplňkem skype lekcí.</h5>
    </div>

    <div class="text-center item">
      <h5>Pokud přihlašovací údaje nedorazí do 15 minut, <a href="https://www.onlinejazyky.cz/kontakty.html" target=”_blank”>kontaktujte nás.</a></h5>
    </div>

  </div>
</div>
</template>

<style scoped>
    .item {
      flex-grow: 1;
      padding: 20px 10px 10px 0px;
    }

    .colourBackground {
      background-color: lightcyan;
      height: 900px;
    }

   .container {
     display: flex;
     flex-wrap: wrap;
     width: 450px
   }

</style>