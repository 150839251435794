<script>
import _ from "lodash";
import axios from "axios";
import OjMixin from "../oj-mixin";
import Phrases from "./phrases.vue";
import Exercises from "./exercises.vue";
import Explanations from "./explanations.vue";
export default {
  name: "courses-translator",
  components:{ Phrases, Exercises, Explanations },
  mixins:[OjMixin],
   data(){
    return{
      nav:1,
        courses:[],
        course: null,
      search:'',
      status:null,
      items:[],
      selected_course:{}
    }
  },
  mounted() {
    this.loadCourses()

  },
  watch:{


    search(){
      this.page=1
      this.searchWord(this.search, this)
    },
    course(){
      this.page=1
      this.te={}


    }

  },

  methods: {
      onCourseSelect(c){

          this.course = c.id

      },
    async loadCourses() {
      const r2 = await axios.get('/oj/courses.json', {
        params: {status: ['STATUS_APPROVED']}
      })
      this.courses = r2.data.items

    },






  }
}
</script>

<template>
<div class=" card card-body">
    <strong>Pája dělá překlady kurzů</strong>
    <div class="d-flex">
        <div style="width:400px;">
          <v-select label="name" :filterable="true" :options="courses"  v-model="selected_course" placeholder="Vyber kurz" @input="onCourseSelect">
            <template #option="option">
              <div class="d-flex">
                <div style="min-width: 300px">{{ option.name }}</div>
              </div>
            </template>
            <template #selected-option="option">
              <div class="selected d-center">
                {{ option.name }}
              </div>
            </template>
          </v-select>
        </div>

        <div style="width:200px;">
            <select v-model="status" class="form-control">
                <option value=""> Nezpracované</option>
                <option value="ai_created">Přeložené AI</option>
                <option value="approved">Potvrzené lektorem</option>
            </select>
        </div>
        <div class="ml-3">
            <input type="text" placeholder="Hledej" v-model="search" class="form-control"/>
        </div>

        <div class="ml-auto">

        </div>
    </div>


  <ul class="nav nav-tabs mt-3">
    <li :class="`nav-item `">
      <a :class="`nav-link ${nav==1 ? 'active': ''}`" href="#" @click.prevent="nav=1">Výklad</a>
    </li>
    <li :class="`nav-item`">
      <a :class="`nav-link ${nav==2 ? 'active': ''}` " href="#" @click.prevent="nav=2">Cvičení</a>
    </li>
    <li :class="`nav-item`">
      <a :class="`nav-link ${nav==3 ? 'active': ''}`" href="#" @click.prevent="nav=3">Fráze</a>
    </li>

  </ul>


  <div v-if="nav==1">
    <explanations  :course="course" :status="status"></explanations>

  </div>

    <div v-if="nav==2">
      <exercises :course="course" :status="status"></exercises>
    </div>

  <div v-if="nav==3">
    <phrases :course="course" :status="status"></phrases>
  </div>







</div>



</template>

<style scoped>

</style>