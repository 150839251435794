<script>
import SelectCourse from "@/components/select-course.vue";
import axios from 'axios'
import {toast} from "vue3-toastify";

/*
*
* group: {
  "id": 1400,
  "organization_id": 15,
  "name": "2.S",
  "created_at": "2022-09-07T10:51:08.808+02:00",
  "updated_at": "2024-05-13T13:21:05.001+02:00",
  "studovna_dirty": true,
  "inactive_students_group": false,
  "unassigned_students_group": false
}
* */

export default {
  name: "mass-courses=assignment.vue" ,
  components: {SelectCourse},
  props:['group'],
  data(){
    return {
      courses_id_in:[],
      courses:[],
      expiration:''
    }
  },
  methods:{
    couseSelect(courses){
      this.courses = courses
      this.courses_id_in = courses.map(c=>c.id)
      console.log('courses', courses)
    },


    masAssign(){
      console.log('masAssign')
// POST     /organizations/:organization_id/groups/:id/courses(.:format)                                      organizations/groups#mass_courses_update

      const data = {
        courses_id_in: this.courses_id_in,
        expiration: this.expiration
      }

      axios.post(`/organizations/${this.group.organization_id}/groups/${this.group.id}/courses.json`, data).then(r=>{
        console.log('mass assign', r)
        if (r.data.status=='ok') {
          toast('Kurzy byly přiřazeny', {type: 'success'})
        }
      }).catch(e=>{
        console.log('mass assign error', e.response.data)
        toast( e.response.data.message, {type: 'error'})
      })



    }

  }
}
</script>

<template>



  <div class="d-flex">
    <div style="width:80%">
      <label>Kurzy</label>
      <select-course @couseSelect="couseSelect"
                   :multiple="true"
                   :course-id="courses_id_in"
                   name="q[courses_id_in][]"
                   ref="q_courses_id_in"></select-course>
    </div>
    <div>
      <label>Expirace</label>
      <input type="date" v-model="expiration"  class="form-control"/>
    </div>
  </div>
  <button class="btn btn-primary" @click.prevent="masAssign">Přiřadit</button>



</template>

<style scoped>

</style>