<script>

import upsell from "./upsell.vue";
import Upsell from "./upsell.vue";
import GopayReturnDefault from "./gopay-return-default.vue";
import GopayReturnKk from "./gopay-return-kk.vue";
export default {
  name: "app-gopay-return-upsell",
  components: {GopayReturnKk, GopayReturnDefault, Upsell},
  props: ['cartid', 'haskk'],
  data() {
    return {
      cart_obj:{},
      p: "upsell"
    }
  },
  mounted() {
    console.log('loadovat upsely pro cart', this.cartid)
    this.$refs.upsells.loadUpsell(this.cartid)
  },
  methods: {
    handleSkipUpsell() {
      console.log('skip upsell', this.haskk)
      if (this.haskk=='true') {
        this.p = 'kk'
      } else {
        this.p = ''
      }


    }
  }
}
</script>

<template>
  <div>
    <upsell v-if="p=='upsell' " ref="upsells" :cartid="cartid" @skipUpsell="handleSkipUpsell"></upsell>
    <gopay-return-default v-if="p==''"></gopay-return-default>
    <gopay-return-kk v-if="p=='kk'"></gopay-return-kk>
  </div>
</template>

<style scoped>

</style>