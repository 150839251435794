<script>
import axios from "axios";
export default {
  name: "fgapps-toolbar",

  props: {
    app: Object
  },
  data() {
    return {
      apps: {}
    };
  },
  methods: {
    async loadApps() {
      const response = await axios.get("/formgenerator/forms");
      this.apps = response.data.apps;
    }
  },
  mounted() {
    this.loadApps();
  }


}
</script>

<template>
<div class="d-flex ft-toolbar">
  <div v-for="a in apps" :class="`toolbar-item ${a.id == app.id ? 'active':''}`">
    <a :href="'/formgenerator/app/'+a.codename">{{a.name}}</a>
  </div>
</div>
</template>

<style scoped lang="scss">
.active{
  background: #0095DA;
  color: white;
   a{ color: white}
}
.ft-toolbar{
  margin-bottom: 10px;
  background: #f0f0f0;
}
.toolbar-item{
  margin-right: 10px;
  border-radius: 3px;
  padding: 3px 5px;
  border: #7DC7FF 1px solid;

}

</style>