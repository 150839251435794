<script>
import moment from "moment/moment";
import {VueFinalModal} from "vue-final-modal";
import modalKkMixin from "../conversation-club/modal-kk-mixin";

export default {
  name: "TranslatorEventStatisModal"  ,
  components: { VueFinalModal },
  props:['ev'],
  mixins:[modalKkMixin],
}
</script>

<template>
<vue-final-modal drag
    v-model="showM"
    classes="modal-container"
    content-class="modal-content-f w900"
  >
    <div class="modal-header">
      <b>Info o překladu  ({{ev.id}})</b>
      <button
          type="button"
          class="close"
          @click.prevent="hideModal"
          aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
      <table class="table table-bordered">
        <tr>
          <th>Prikaz pro AI</th>
          <td>{{ev.template_content}}</td>
        </tr>

        <tr>
          <th>Stav</th>
          <td>{{ev.status}}</td>
        </tr>

        <tr>
          <th>Updated at</th>
          <td>{{dt(ev.updated_at)}}</td>
        </tr>

        <tr>
          <th>Created at</th>
          <td>{{dt(ev.created_at)}}</td>
        </tr>
      </table>
<div id="blokyukazka">

  <div v-for="log in ev.translator_request_logs" style="border:1px dotted silver">
    <b>Dotaz  {{log.id}}</b>
    <pre>{{log.req}}</pre>
    <b>odpoved #{{log.id}}</b>
    <pre>{{log.resp}}</pre>
    <hr>
  </div>

</div>
</vue-final-modal>
</template>

<style scoped lang="scss">
#blokyukazka{
  max-height:300px;
  overflow-y: scroll;
  pre{
    padding:10px;
    background: rgba(125, 199, 255, 0.2);

    white-space: pre-wrap;       /* css-3 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
  }
}
</style>