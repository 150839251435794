<script>
export default {
  props: ['lesson'],
  name: "AssignToGroups",
  data() {
    return {
      d: this.lesson.exercise.data_object,
      out:{},
      tmp_content: {},
    }
  },
  mounted() {
    this.prepare_content()


  },
  watch: {
    lesson() {
      this.d = this.lesson.exercise.data_object
      this.prepare_content()
    },
  },

  methods: {
    prepare_content(){
      for(let i in this.d.groups){
        if (Array.isArray(this.d.groups[i].content)) {
          this.tmp_content[i] = this.d.groups[i].content.join("\n")
        }
      }
    },
    prepare_data_for_save() {
      const groups = {}
      for (let i in this.tmp_content) {
        groups[i] = {
          active: this.d.groups[i].active,
          name: this.d.groups[i].name,
          content: this.tmp_content[i].split("\n")
        }
      }
      this.out = {
        groups: groups
      }
    }



  }
}

/* : {
      "groups": {
        "1": {
          "active": true,
          "name": "In the company",
          "content": [
            "to work for a company",
            "to be promoted",
            "to do overtime",
            "a boss",
            "an employee",
            "an employer",
            "to work hard",
            "a director",
            "a full-time",
            "a colleague",
            "a company car"
          ]
        },
        "2": {
          "active": true,
          "name": "Looking for a job",
          "content": [
            "to apply for a job",
            "to have an interview",
            "to get a job",
            "a CV",
            "an application form",
            "a job advert"
          ]
        },
        "3": {
          "active": "1",
          "name": "Without a job",
          "content": [
            "to be fired",
            "to be unemployed",
            "no salary",
            "a redundancy",
            "no contract"
          ]
        },
        "4": {
          "active": "0",
          "name": "",
          "content": ""
        }
      }
    },
    */
</script>




<template>
<div class="well">
    <div class="row-fluid">
      <div class="span6">
        <div class="control-group"><label for="aboveText" class="control-label optional">Text nad cvičením</label>
          <div class="controls">
            <textarea v-model="lesson.exercise.aboveText"  name="aboveText" id="aboveText" rows="8" class="span12" cols="80"></textarea>
            <p class="help-block" style="color: #999;">Text, který se zobrazí nad cvičením. Měl by obsahovat zadání cvičení, kde studenta informujeme o tom, co od něho ve cvičení požadujeme a jak má postupovat.</p></div></div>
      </div>
      <div class="span6">
        <div class="control-group"><label for="secondaryAboveText" class="control-label optional">Sekundární text nad cvičením</label>
          <div class="controls">
            <textarea v-model="lesson.exercise.secondaryAboveText" name="secondaryAboveText" id="secondaryAboveText" rows="8" class="span12" cols="80"></textarea>
            <p class="help-block" style="color: #999;">Sekundární text nad cvičením - slouží zejména pro text ve vyučovaném jazyce</p></div></div>
      </div>
    </div>
  </div>

<div>

  <table class="table table-striped table-bordered table-condensed table-exercise4">
    <thead>
    <tr>
      <th colspan="4">
        Obsah cvičení:
      </th>
    </tr>
    </thead>
    <tfoot>
    <tr>
      <td colspan="4">
        <div class="alert alert-info">
          <h4 class="alert-heading">Skupiny</h4>
          Do pole s obsahem skupiny vkládejte slovíčka, či krátké fráze, které bude mít za úkol student zařadit do správně skupiny. Jedno slovíčko či fráze musí být na jednom řádku.
        </div>
        <div class="alert alert-warning">
          <h4 class="alert-heading">Správný počet slovíček</h4>
          Počet slovíček ve skupině není omezen, vhodné maximum kvůli přehlednosti cvičení na všech zařízeních je cca 10 slovíček na skupinu, nebo 30 slovíček celkem.
        </div>
      </td>
    </tr>
    </tfoot>
    <tbody>
    <tr>
      <td></td>
      <td></td>
      <td>
        <dt id="groupActive3-label">
          <label for="groupActive3" class="optional">Je skupina aktivní?</label>
        </dt>
        <dd id="groupActive3-element">
          <input type="checkbox" v-model="d.groups[3].active">
        </dd>
      </td>
      <td>
        <dt id="groupActive4-label"><label for="groupActive4" class="optional">Je skupina aktivní?</label></dt>
        <dd id="groupActive4-element">
          <input type="checkbox" v-model="d.groups[4].active">
        </dd>
      </td>
    </tr>
    <tr>
      <td v-for="(g,i) in d.groups">
        <dt id="groupName1-label"><label for="groupName1" class="required">Název {{i}} skupiny</label></dt>
        <dd id="groupName1-element">
          <input type="text" name="groupName1" id="groupName1" value="In the company" class="span12 required" v-model="g.name"/>
        </dd>
      </td>

    </tr>
    <tr>
      <td v-for="(g,i) in d.groups">
        <dt id="groupContent1-label"><label for="groupContent1" class="required">Obsah {{ i}} skupiny</label></dt>
        <dd id="groupContent1-element">
        <textarea v-model="tmp_content[i]" rows="15" ></textarea></dd>
      </td>

    </tr>
    <tr>
      <td>
        <dt id="groupExplanation1-label"><label for="groupExplanation1" class="optional">Vysvětlení první skupiny</label></dt>
        <dd id="groupExplanation1-element">
          <textarea name="groupExplanation1" id="groupExplanation1" rows="15" class="span12" ></textarea></dd>
      </td>
      <td>
        <dt id="groupExplanation2-label"><label for="groupExplanation2" class="optional">Vysvětlení druhé skupiny</label></dt>
        <dd id="groupExplanation2-element">
          <textarea name="groupExplanation2" id="groupExplanation2" rows="15" class="span12" ></textarea></dd>
      </td>
      <td>
        <dt id="groupExplanation3-label"><label for="groupExplanation3" class="optional">Vysvětlení třetí skupiny</label></dt>
        <dd id="groupExplanation3-element">
          <textarea name="groupExplanation3" id="groupExplanation3" rows="15" class="span12" :disabled="!d.groups[3 ].active"></textarea></dd>
      </td>
      <td>
        <dt id="groupExplanation4-label"><label for="groupExplanation4" class="optional">Vysvětlení čtvrté skupiny</label></dt>
        <dd id="groupExplanation4-element">
          <textarea name="groupExplanation4" id="groupExplanation4" rows="15" class="span12 muted"  :disabled="!d.groups[4].active"></textarea></dd>
      </td>
    </tr>
    </tbody>
  </table>

</div>
  <button @click.prevent="prepare_data_for_save">make content</button>
  <pre>
    out: {{out}}
  </pre>
  <pre>
    {{d}}
  </pre>






</template>

<style scoped lang="scss">
.table-exercise4{
  td{
    width:300px;
  }
}
</style>