<template>
  <div class="ml-auto">
    <a href="#" @click.prevent="open_apilog">woo api log</a>


     <!--<modal name="modal1" :resizable="false" :adaptive="true" :height="550" :width="800">-->
       <vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f w800">

          <div class="m-head">
            <a class="close"  href=# @click.prevent="showM=false"><span class="material-icons-outlined">close</span></a>
            <div class="p-2">WOO</div>
          </div>

          <div class="p-1 " style="overflow: scroll; height:500px;">

            <div>
              <input type="text" v-model="search_id" />
            </div>

           <table class="table table-striped">
             <tbody>
               <tr v-for="l in log" :key="l.id" @click.prevent="detail(l)" :class="l.id == current_id ? 'active' : ''">
                 <td>{{l.id_woo}}</td>
                 <td>{{l.eshop}}</td>
                 <td>{{dateTime(l.created_at)  }}</td>
                 <td>{{l.email}}</td>
                 <td>{{l.status}}</td>
                 <td>{{l.process_status}}</td>
               </tr>
             </tbody>
           </table>

          </div>
       </vue-final-modal>

      <vue-final-modal v-model="showM2" classes="modal-container" content-class="modal-content-f w800">

      <div class="m-head">
        <a class="close"  href=# @click.prevent="showM2=false;current_id=0"><span class="material-icons-outlined">close</span></a>
      </div>
      <div style="overflow-y: scroll; height:650px; background: #ffffbd">
        <pre>{{ current_data }}</pre>
      </div>
      </vue-final-modal>
  </div>
</template>

<script>
import moment from "moment";
import {VueFinalModal} from "vue-final-modal";
import 'moment/locale/cs';
moment.locale('cs');

import axios from "axios";
import _ from "lodash";
export default {
  name: "woo-log2",
  components: {
    VueFinalModal
  },

  watch:{
    search_id(){
      this.search( this);
    }
  },

  methods:{
    date(value) {
      value = moment(value).format('DD. MM. YYYY')
      return value
    },

    dateTime(value) {
      value = moment(value).format('DD. MM. YYYY, H:mm:ss')
      return value
    },
    detail(item){
      this.current_id=item.id
      //this.$modal.show('modal2')
      this.showM2=true
      this.current_data = JSON.parse(item.content)
    },

    open_apilog(){


      //this.$modal.show('modal1')
      this.showM=true
      this.load_logs()

    },

    search: _.debounce((vm) => {
      vm.load_logs()
    }, 350),

    load_logs(){
      const url = `/logs/woo`
      axios.get(url, {params:{id: this.search_id}}).then(response => {
        this.log=response.data.items
      })
    }

  } ,
  mounted() {
    const csfr_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csfr_token;
    axios.defaults.withCredentials=true
  },
  data(){
    return({showM:false,
      showM2:false,
      log: [],
      current_data:'',
      current_id:0,
      search_id:''
    });
  },

}
</script>

<style scoped>
tr.active td{ background: #0095DA}

</style>