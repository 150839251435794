<script >

import AppTable from "../form-generator/AppTable.vue";

export default {
  name: "app-ac-list-log",
  components: {AppTable},
  props: [],

  data() {
    return ({
      fields: [

        {
          "label": "ID WOO",
          "type": "text",
          "codename": "id_woo",
          display_filter: 'strong'
        },
        {
          "label": "E-mail",
          "type": "text",
          display_filter:'student',
          "codename": "student"
        },
        {
          'label': 'Eshop',
          'type': 'text',
          'codename': 'eshop'
        },
        {
          label: 'Typ',
          type: 'text',
          codename: 'event',
          display_filter: 'order_event_name'
        },
        {
          label: 'Stav',
          type: 'text',
          display_filter: 'order_status_name',
          codename: 'status'
        }
        ,
        {
          label: 'Slevový kód',
          type: 'text',
          codename: 'discount_code'
        }
        ,
        {
          label: 'Měna',
          type: 'text',
          codename: 'currency_code'
        }

      ],
      filters:[
        {
          "label": "Datum a čas zaplacení",
          "type": "date-range",
          "codename": "paid_on"
        },

//Cena celkem větší nebo rovno než
//Cena celkem menší nebo rovno než
        //q[total_gteq]
          // q[total_lteq]
        {
          "label": "Cena celkem větší nebo rovno než",
          "type": "number",
          "codename": "q[total_gteq]"
        }  ,
        {
          "label": "Cena celkem menší nebo rovno než",
          "type": "number",
          "codename": "q[total_lteq]"

        },
        {
          label: 'Kurz ID',
          type: 'course_id',
          codename: 'q[order_courses_id_or_subscriptions_courses_id_in]'
        },
        {
          label: 'Produkt ID',
          type: 'product_id',
          codename: 'q[order_products_id_or_subscriptions_product_id_in]'
        },
        {
          label: 'Woo eshop',
          type: 'woo_eshop_id',
          codename: 'q[woo_eshop_id_eq]'
        },

        {label: 'Slevový kód obsahuje', type: 'text', codename: 'q[discount_code_cont]'},

        {label: 'Stav', type: 'select',
          codename: 'q[status_eq]',
          options: {all: 'Všechny', 0: 'Zpracovává se', 1: 'Vytvořena', 2: 'Čeká na platbu', 3: 'Platba selhala',
            4: 'Zaplacena',
            5: 'Zrušena', 6: 'Vrácená', 7: 'Vypršela', 8: 'Aktivní', 9: 'Pozastavena', 10: 'Probíhá zrušení'}
        },
        {
          label: 'Typ',
          type: 'select',
          codename: 'q[event_eq]',
          options: {'': 'Všechny', 0: 'Jednorázová objednávka', 1: 'Objednávka a předplatné', 2: 'Prodloužení předplatného', 3: 'Vratka'}
        },

        {
          label: 'Způsob platby',
          type: 'select',
          codename: 'q[payment_method_eq]',
          options: {
            all: 'Všechny',
            1: 'Manual',
            2: 'Free',
            3: 'Card',
            4: 'Wire Transfer',
            5: 'Other',
            6: 'Gopay',
            7: 'Paypal',
            8: 'Stripe',
            9: 'Gopay Inline',
            10: 'Benefity',
            104: 'Transfer',
            110: 'Benefit'
          }

        },

        {
          type: 'search'
        }
      ]

      ,
      app: {
        data_url: '/payments2.json',
        destroy_url: '/payments/:id',
        actions: ['edit', 'delete'],
        hidden_id: true,
        filter_default_hidden: true,

      }
    })
  },
  methods:{

    openDetail(item){
      window.location.href=`/payments/${item.id}`
    }
  }

}

</script>

<template>
<div>
  <div class="row mt-2">
    <h1 class="col flex-grow-1 heading">  Seznam plateb
    </h1>
  </div>

  <div class="card card-body index p-0">
    <AppTable :app="app"
              :fields="fields"
              :default_per="50"
              :enable_search="true"
              :filters="filters"
              :actions="[{label:'edit', method: openDetail}]"

    />
  </div>
</div>
</template>

<style scoped>

</style>