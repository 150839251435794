<script>

import axios from "axios";
export default {
  name: "FRelation",
  props: ['options', 'item'],
  data(){
    return({
      select_opts: []
    })
  },
  methods:{
    loadOpts(){
      // /formgenerator/app/zkouskaxx/index?per=50&page=1&search=&filter={}
      axios.get(`/formgenerator/app/${this.options.rel_app}/options-for-select?per=1150`).then(response=>{
        this.select_opts = response.data.items
      })
    }
  },
  mounted(){
    this.loadOpts()
  }
}
</script>

<template>
<div class="form-group">
  <label>{{options.label}}  </label>
  <select v-model="item[options.codename]">
    <option v-for="a in select_opts" :value="a.id">
      {{a.obj_alt}}
    </option>
  </select>
</div>
</template>

<style scoped>

</style>