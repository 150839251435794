<script>
import axios from 'axios'
export default {
  name: "dashboard-topseller",
  props: ['month', 'year'],

  data(){
    return({
      top_sellers: [],

    })
  },
  mounted() {
    this.loadTopSellers()
  },
  methods:{
    loadTopSellers(){
      axios.get('/students_stats/top10.json', {params: {month: this.month, year: this.year}}).then(response =>{
        this.top_sellers = response.data.top10


      })
    } ,

  }
}
</script>

<template>
<table class="minitable">
  <thead>
    <tr>
      <th>Name</th>
      <th>{{ year }}</th>
      <th>{{ year - 1}}</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="seller in top_sellers">
      <td>{{ seller.name }}</td>
      <td>{{ seller.count }}</td>
      <td>{{ seller.count_year_ago }}</td>
    </tr>
  </tbody>
</table>
</template>

<style  lang="scss">
.chart {
  .minitable {
    width: 100%;
    margin:8px;
    font-size: 80%;

    td {

    }
  }
}
</style>