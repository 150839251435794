<script>
import './fe.scss'
import axios from "axios";
import FInputText from "./fields/FInputText.vue";
import FInputDate from "./fields/FInputDate.vue";
import FInputCategory from "./fields/FInputCategory.vue";
import FUsers from "./fields/FUsers.vue";
import FInputImage from "./fields/FInputImage.vue";
import FInputLongText from "./fields/FInputLongText.vue";

import AppTable from "./AppTable.vue";
import FRelation from "./fields/FRelation.vue";
import FgappsToolbar from "./fgapps-toolbar.vue";
export default {
  name: 'form-generator-app',
  components:{FInputText, FInputDate, FInputCategory, AppTable, FUsers, FInputImage , FInputLongText, FRelation, FgappsToolbar},
  mounted() {

    if (window.location.hash) {
      if (window.location.hash.indexOf('#edit')>-1) {
        let id = window.location.hash.split('#edit/')[1]
        this.k_otevreni.push(id)
        window.location.hash=''
      }
    }


    //this.slug = location.href.split("/").pop()

    //# rozparsovat poradne to url a ziskat slug
    let a = location.href.split("/")
    console.log("SLUG parse url", a, a.indexOf('formgenerator'))
    let fg = a.indexOf('formgenerator')
    this.slug = a[fg+2]

    this.loadFormApp(this.slug)


  },
  data(){
    return{
      vv: 'table',
      k_otevreni:[],
      ch:5000,
      slug:'',
      app:{},
      fields:[],
      item:{}

    }
  },

  methods: {
    async handleSaveChanges() {
      const data = {item: this.item};
      const url = `/formgenerator/app/${this.slug}/${this.item.id ? this.item.id : ''}`;
      const method = this.item.id > 0 ? 'put' : 'post';

      try {
        const response = await axios({method, url, data});
        if (!this.item.id) {
          this.item.id = response.data.item.id;
        }
        this.uploadAttachments();
      } catch (error) {
        console.error(error);
      }
    },

    uploadAttachments() {
      this.fields.filter(f => f.type == 'image' && this.item[f.codename]).forEach(f => {
        console.log('uploading', this.item[f.codename]);

        let formData = new FormData();
        formData.append('file_list_len', this.item[f.codename].length);
        this.item[f.codename].forEach(file => formData.append('file[]', file));

        axios.post(`/formgenerator/app/${this.slug}/${this.item.id}/upload/${f.codename}`, formData);
      });
    },


    loadFormApp(id) {
      axios.get(`/formgenerator/forms/${id}`).then(response => {
        this.fields = response.data.fields
        this.app = response.data.app

        for (let i in this.fields) {
          let f = this.fields[i]
          //this.item[f.codename] = ''
        }


      })

    },

    handleAddItem() {
      this.item = {}

      for (let i in this.fields) {
        let f = this.fields[i]
        this.item[f.codename] = null
      }

      this.vv = 'form'
    },
    handleCancelForm() {
      this.vv = 'table'
    },

    itemClick(it) {
      this.item = it
      this.vv = 'form'

    },
    handleTableItemsLoaded(items) {
      console.log('items loaded', items)

      if (this.k_otevreni.length > 0) {
        let id = this.k_otevreni.pop()
        this.openItem(id, items)
      }
    },
    openItem(id, items) {
      console.log(' a ankonec otevru', this.k_otevreni)
      for (let i in items) {
        let it = items[i]
        if (it.id == id) {
          this.item = it
          this.vv = 'form'
          return
        }
      }
    }
  }



}
</script>

<template>
<div class="big-blok">
  <FgappsToolbar :app="app" />

  <div v-if="app.id && vv =='table'">




    <button @click.prevent="handleAddItem">new item</button>
    <AppTable :fields="fields" :app="app" @itemClick="itemClick" @itemsLoaded="handleTableItemsLoaded" ref="appTable"/>
  </div>
  <div v-else>
     <div v-for="f in fields">
        <FInputText :options="f" v-if="f.type=='text'" :item="item"></FInputText>

        <FInputLongText :options="f" v-if="f.type=='longtext'" :item="item"></FInputLongText>

        <FInputDate :options="f" v-if="f.type=='date'"  :item="item" ></FInputDate>

       <FUsers :options="f" v-if="f.type=='user'"  :item="item" @change="ch++"></FUsers>

        <FInputCategory :options="f" v-if="f.type=='category'" :item="item" @change="ch++"></FInputCategory>

       <FInputImage :options="f" v-if="f.type=='image'" :item="item" @change="ch++"></FInputImage>

       <FRelation :options="f" v-if="f.type=='relation'" :item="item" @change="ch++"></FRelation>

      </div>



    <hr>
    <button class="btn btn-success" @click.prevent="handleSaveChanges">Save changes</button>
    <button class="btn btn-outline-dark" @click.prevent="handleCancelForm">cancel</button>

  </div>
  <hr>
  <div> <a :href="`/formgenerator/editor/index#${app.codename}`" >Modify Template</a> </div>


  <div :key="ch">[[  {{item}} ]]</div>
</div>
</template>

<style scoped>

</style>