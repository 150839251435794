<script >
import axios from "axios";
import {VueFinalModal} from "vue-final-modal";
import modalKkMixin from "../conversation-club/modal-kk-mixin";
export default {
  name: "new-app",
  mixins: [modalKkMixin],
  data(){
    return({
      name:'',
      codename: ''
    })
  },
  methods:{
    btnCreate(){
      let err=0
      if (this.name == ''){
        this.$toasted.show("Nazev je povinny", {type: 'error'});
        err++
      }

      if (this.codename == '' || this.codename.length < 4){
        this.$toasted.show("Systemovy nazev je povinny a musi mit minimalne 4 znaky", {type: 'error'});
        err++
      }

      if (err>0) return


      this.$emit('create', {name: this.name, codename: this.codename})
      this.hideModal()

    },
    normalizeCodename(){
      let s = this.codename.toLowerCase().trim()
      s=s.replaceAll(' ', '_')
      for(let i in s){
        let c = s.charCodeAt(i)
        console.log(">>>", i, s[i], c)
        if ((c>122 || c<97) && s[i]!='_'){ // a-z
          s = s.replaceAll(s[i], "")
        }
      }
      s=s.replaceAll('__', '_')
      this.codename = s
    }

  },


}

</script>

<template>
  <vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f w800">
    <div class="modal-header">
      <b>Nová aplikace</b>
      <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>

    </div>

    <div class="modal__content">


      <div class="form-group">
        <label for="exampleInput1">Zobrazený název</label>
        <input type="text" class="form-control" id="exampleInput1" aria-describedby="aHelp" placeholder="" v-model="name" />
        <small id="aHelp" class="form-text text-muted"></small>
      </div>


      <div class="form-group">
        <label for="exampleInputEmail1">Systémový název</label>
        <input type="text" class="form-control" id="exampleInput2" aria-describedby="bHelp" v-model="codename" @keyup="normalizeCodename" @change="normalizeCodename"/>
        <small id="bHelp" class="form-text text-muted">Součást url adresy a názvu db tabulky. Do budoucna nelze změnit.</small>
      </div>


      <button @click.prevent="btnCreate" class="btn2 btn-success" > Vytvořit</button>
      <button @click.prevent="hideModal" class="btn2 btn-outline-dark">Cancel</button>
    </div>
  </vue-final-modal>

</template>

<style scoped>

</style>