<script>
import axios from "axios";
import OjPagination from "../OjPagination.vue";
import translatorMixin from "./translator-mixin";
import ModalTemplates from "./modal-templates.vue";
export default{
  name: "phrases",
  props:['course', 'status'],
  mixins:[translatorMixin],
  components:{ModalTemplates, OjPagination },
  data(){
    return({
      active_template:{},
      search:'',
      page: 1,
      total:0,
      per: 30,
      items:[],
      pp_ids:[],
      all_ids:[]
    })
  },
  watch:{
    course(){
      this.load_items()
    },
    per(){
       this.load_items()
    },
    status(){
      this.page=1
      this.load_items()
    },




  },
  mounted() {
    this.load_items()
  },
  methods:{
    pageChanged(p){
      this.page = p
      this.load_items()
    },

    runTranslation_zob() {
      this.runTranslation( this.pp_ids, 'phrases')
    },
    runTranslation_all() {
      this.runTranslation( this.all_ids, 'phrases')
    },


     load_items(){
      axios.get('/paja/courses_translator/phrases', {
          params:
              {
                status: this.status,
                page: this.page,
                search: this.search,
                per: this.per,
                course_id: this.course
              }
        }).then(response => {
          this.items = response.data.items
          this.total = response.data.total

          let ids = []
          for(let i in this.items){
            ids.push(this.items[i].id)
          }
          this.pp_ids = ids
        })
    },

    pajaTranslationChange(item){

        if (item.paja1_id && item.paja_target) {
          axios.put(`/paja/courses_translator/translation/${item.paja1_id}`, {translation: item.paja_target})
        }else{
          alert('neni obsah')
        }

    }
  }
}
</script>

<template>
<div>

  <modal-templates ref="aiTemplatesModal" v-on:detectTemplate="detectTpl" :kind="'courses_phrases'"></modal-templates>

  <div class="row mt-2">
    <div class="col-sm-4">
      <a @click.prevent="handleTplModal" href="#">nastavení AI dotazu</a>
      šablona dotazu: <b>{{active_template.name}}</b>
    </div>
    <div class="col-sm-5">
      <button @click.prevent="runTranslation_zob" class="btn btn-sm btn-info">prelozit zobrazene ({{pp_ids.length}})</button>
      <button @click.prevent="runTranslation_all"  class="btn btn-sm btn-info">prelozit vybrane ({{all_ids.length}})</button>
    </div>
  </div>



  <div class="d-flex" v-if="items.length>0">
    <div class=" mr-3">
      <select v-model="per" class="form-control w-auto mt-2">
        <option>10</option>
        <option>20</option>
        <option>30</option>
        <option>40</option>
        <option>50</option>
      </select>
    </div>


    <OjPagination class="mt-2"
    :totalItems="total"
    :currentPage="page"
    :perPage="per"
    @pageChanged="pageChanged" />
  </div>

    <table class="table table-bordered">
      <thead>
      <tr>
        <th>ID</th>
        <th>phrase_id</th>
        <th>source</th>
        <th>target</th>
        <th>pája target</th>
      </tr>
      </thead>
      <tr v-for="item in items">
        <td>{{item.id}}</td>
        <td>{{item.phrase_id}}</td>
        <td>{{item.source}}</td>
        <td>{{item.target}}</td>
        <td style="width: 28%"><textarea v-model="item.paja_target" class="form-control" @change="pajaTranslationChange(item)"></textarea></td>
      </tr>
    </table>

</div>
</template>

<style scoped>

</style>