<template>
<div class="card card-body index p-3">
    <div class="row">
        <div class="col-md-6">
            <div class="d-flex">
                <h5> Chat</h5>
                <div class="ml-auto"><a href="#" @click.prevent="handleResetChat">nova konverzace</a></div>
            </div>


            <table  class=" table table-striped">

                <tbody>
                <tr v-for="m in old_messages">
                    <td>{{m.role}}</td>
                    <td>{{m.content}}</td>
                </tr>
                </tbody>
            </table>

            <textarea v-model="msg" class="form-control"></textarea>

            <button @click.prevent="handleChatReq">Odeslat</button>

            <hr>

            <p v-for="ch in resp.choices"  v-html="nl2br(ch.message.content)"></p>

        </div>

        <div class="col-md-6">
            <h5> Edits</h5>
            <label>The input text to use as a starting point for the edit.</label>
            <textarea v-model="edit_msg" class="form-control"></textarea>

            <label>The instruction that tells the model how to edit the prompt.</label>
            <textarea v-model="instruction" class="form-control"></textarea>
            <button @click.prevent="handleEditReq">Odeslat</button>

            <hr>

            <div v-if="loading_ed"><img :src="`/img/1495.gif`"></div>
            <p v-for="ch in resp2.choices" v-html="nl2br(ch.text)"></p>


        </div>
    </div>


</div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
export default {
      data(){
        return({
            loading_ed:false,
          msg:'',
          temperature: 0.7,
            resp:{},
            resp2:{},
            instruction:'',
            edit_msg:'',
            session_id:null,
            old_messages:[]
        })
      },
    mounted(){

    },

    methods:{
        handleResetChat(){
          this.msg = ''
          this.session_id=null
            this.old_messages =[]
            this.resp={}
        },

      nl2br(text){
        return text.replaceAll("\n", "<br>")
      },
    handleChatReq(){
      const data={
        message: this.msg,
        temperature: this.temperature,
        session: this.session_id
      }
      axios.post('/open_ai/chat', data).then(response =>{
          this.resp = response.data.ai
          this.session_id = response.data.session_id
            this.old_messages = response.data.messages

          this.msg=''
      })
    },




    handleEditReq(){
        const data={
            input: this.edit_msg,
            instruction: this.instruction,
            temperature: this.temperature
        }
        this.resp2={}
        this.loading_ed=true
        axios.post('/open_ai/edit', data).then(response =>{
          this.resp2 = response.data
            this.loading_ed=false
        })
    }

  }





}

</script>

<style scoped>

</style>