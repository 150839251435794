<script>
import axios from 'axios';
import AppTable from "../form-generator/AppTable.vue";
import {VueFinalModal} from "vue-final-modal";
/*
b) byla by to tabulka ve které:

    v 1. sloupci: by byl uvedený celý kód poukazu DARMJ a dále... (název sloupce: Kód poukazu)
    2. sloupec: datum expirace poukazu (název sloupce: Expirace) - aby šla prosím expirace editovat, je možné, že budeme muset jednotlivé poukazy zapomětlivým studentům prodloužit
    3. sloupec: číslo objednávky ke které patří (ve které byl koupený), bude se stávat že jedno číslo objednávky bude v seznamu 2x a pokaždé s jiným poukazem, to právě kvůli tomu, že 1 objednávka vygeneruje 2 dárkové poukazy (název sloupce: Zdrojová objednávka)
    4. sloupec: hodnota poukazu v Kč nebo EUR. (název sloupce: Hodnota poukazu; hodnoty ve sloupci: to jsou hodnoty toho poukazu a ne částka za kterou byly koupené, to se může lišit, protože je to akce 1+1, jeden poukaz zaplatí a druhý ve stejné hodnotě má zdarma. Takže například zaplatí 3 000 Kč, vygenerují se 2 poukazy, poukaz 1 nebude mít hodnotu 1500 Kč, ale 3 000 Kč.  Ty hodnoty poukazů jsou 890 Kč, 2 490 Kč, 1 000 Kč, 3 000 Kč, 5 000 Kč a možná bude i 10 000 Kč.)
    5. sloupec: stav, zda byl uplatněný nebo ne (tohle by bylo ideální hlídat na základě přijatých objednávek do ruby - v ruby máme všechny objednávky ze všech e-shopů, kde by se to mohlo hlídat na základě kolonky Slevový kód, kam se vpisuje právě uplatněný poukaz, pokud byl v objednávce nějaký použitý. U každé objednávky takový slevový kód jde vložit jen jeden, jakmile by tedy v nějaké objednávce byl uplatněný slevový kód s názvem DARMJ... tak by se to zaznamenalo právě do toho stavu v té nové tabulce.  (název sloupce: Uplatněno; hodnoty ve sloupci stačí NE - černě, ANO - barevně odlišit a viditelně udělat jako odkaz)
    5. sloupec - stav ANO - by byl odkaz - proklik v případě, že by byl poukaz již uplatněn, tak by byl odkaz veden na tu objednávku, kde byl použitý
    6. sloupec - ten asi není úplně nutný, ale byl by perfektní, kdyby u těch uplatněných se tam zobrazovaly názvy produktů na které byl uplatněný (název sloupce: Aktivované produkty)
    přidat nahoru vyhledávání, kde půjde hledat podle kódu poukazu, ale i podle čísla objednávky
    přidat filtr - kde půjde filtrovat podle hodnoty poukazu nebo stavu (uplatněno/ neuplatněno) a pokud tam přidáme i ten 6. sloupec tak i podle toho produktu, na který bylo uplatněno)
    možnost exportu do xlsx nebo csv (nevím, který formát je pro tebe jednodušší), jen vyfiltrovaných údajů nebo úplně všech údajů
*/

import qs from 'qs';

export default {
  name: "gift_vouchers",
  components: {AppTable, VueFinalModal},
  data(){
    return {
      app:{
          name: 'gift_vouchers',
          data_url: '/gift_vouchers.json',
          actions: ['edit'],
          hidden_id: true,
          filter_default_hidden: true,

        },

       fields: [

          {
            "label": "Kód poukazu",
            "type": "text",
            "codename": "code",

          },
          {
            "label": "Expirace",
            "type": "text",
            "codename": "expiration",
            'display_filter': 'date'
          },
         {
           "label": "Zdrojová objednávka",
            "type": "text",
           "codename": "order_number",
           'display_filter': 'order'
         },
         {
            "label": "Hodnota poukazu",
            "type": "text",
            "codename": "value"
          },

          {
            "label": "uplatněno",
            "type": "text",
            "codename": "used_order_id",
            'display_filter': function (item) {

              if(item.used_order_id && parseInt(item.used_order_id)>0){
                return `<a href="/orders/${item.used_order_id}" class="code-used">ANO</a>`
              }
              return 'NE'

            }
          },
         {
           label: "Datum uplatnění",
            type: "text",
            codename: "used_at",
           display_filter: 'date'
         },
          {
            "label": "Aktivované produkty",
            "type": "text",
            "codename": "used_product_names"
         }
       ],
        filters:[
            {
              type: 'search'
            },
            { //  půjde filtrovat podle hodnoty poukazu nebo stavu (uplatněno/ neuplatněno)
              // a pokud tam přidáme i ten 6. sloupec tak i podle toho produktu, na který bylo uplatněno)

              label: "uplatněno",
              type: "select",
              codename: "used",
              default:'',
              options: [
                  {label: '- nefiltrovat -', value: ''},
                  {label: 'Ano', value: 'true'},
                  {label: 'Ne', value: 'false'}
              ]
            },
            {
              label: "Hodnota poukazu",
              type: "select",
              codename: "value",
              default:'',
              options: [
                  {label: '- nefiltrovat -', value: ''},
                  {label: '890 Kč', value: '890'},

                  {label: '1 000 Kč', value: '1000'},
                  {label: '3 000 Kč', value: '3000'},
                  {label: '5 000 Kč', value: '5000'},

              ]
            },{
              label: "Datum uplatnění",
             "type": "date-range",
              codename: "paid_on",

          }

          ],


      current_item: null,
      showModal: false,

    }
  },
  mounted(){
    this.load_collections()
  },
  methods: {
    export_xls(){
      let f = this.$refs.table.get_filters()
      let url = `/gift_vouchers/export_xls`
      const aa = document.getElementById('dld')
      const queryString = qs.stringify(f);
      aa.href = `${url}?${queryString}`;
      aa.click()




    },
    load_collections(){
      // gift_vouchers/values_collection
      axios.get('/gift_vouchers/values_collection.json').then((response) => {
        console.log('response', response.data)
        const options = [ {label: '- nefiltrovat -', value: ''}]
        for(let i in response.data){
          if (response.data[i] === null) continue
          options.push({label: `${response.data[i]} Kč`  , value: response.data[i]})
        }
        this.filters[2].options = options

      })

    },


    openDetail(item){
      console.log('openDetail', item)
      this.current_item = { expiration: item.expiration, id: item.id , code: item.code}
      this.showModal = true

    },
    handleSaveChanges(){
      console.log('handleSaveChanges', this.current_item)
      axios.put(`/gift_vouchers/${this.current_item.id}.json`, {gift_voucher: this.current_item}).then((response) => {
        console.log('response', response)
        this.showModal = false
        this.$refs.table.loadItems()

      })
    }
  }


}
</script>

<template>
    <div class="card card-body index p-2">
      <h1>Dárkové poukazy 1+1</h1>

      <vue-final-modal v-model="showModal" classes="modal-container" content-class="modal-content-f mcw1">
        <div class="modal-header">
          <span class="modal__title fg-modal-title">Voucher {{current_item.code}}</span>
          <button type="button" class="close" @click.prevent="showModal=false" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal__content" >
         <label>Expirace</label>
         <input type="date" v-model="current_item.expiration" class="form-control" >
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click.prevent="showModal=false">Zavřít</button>
          <button type="button" class="btn btn-primary" @click.prevent="handleSaveChanges">Uložit</button>
        </div>

      </vue-final-modal>

      <div class="" style="text-align:right">
            <button @click.prevent="export_xls">Export XLSX</button>
        <a id="dld"></a>
      </div>
      <AppTable :app="app"
                :fields="fields"
                :default_per="50"
                :enable_search="true"
                :filters="filters"
                :actions="[{label:'Upravit', method: openDetail}]"
                ref="table"

        />
    </div>
</template>

<style >
  a.code-used{
    font-weight: bold;
    color: green;
  }
</style>