<script>

import axios from "axios";
export default {
  name: "app-product-upsell-editor" ,
  props: ['product'],
  mounted() {
    this.loadProducts()
    this.load_upsells()
  },
  data(){
    return {
      products: [],
      selected_product: {},
      upsell_ids: [],
      new_upsells: [],
      upsells: [],
      discount: 0


    }
  },
  methods:{
    onProductSelect(){

    },
    deleteUpsell(up_id){

      if (this.product==null || this.product==undefined || this.product=='') {
        this.upsells = this.upsells.filter((up) => up.id !== up_id)
        this.upsell_ids = this.upsell_ids.filter((up) => up !== up_id)
        return
      }


      axios.delete(`/products/${this.product}/upsells/${up_id}`).then((response) => {
        this.load_upsells()
      })
    },
    loadProducts(){
      axios.get('/api/products.json').then((response) => {
        this.products = response.data
      })
    },

    load_upsells(){
      axios.get(`/products/${this.product}/upsells`).then((response) => {
        this.upsells = response.data
      })
    },

    handleAddItem(){

      if (!this.upsell_ids.includes(this.selected_product.id)) {
        // If it doesn't, add it to the array
        this.upsell_ids.push(this.selected_product.id);

        console.log(this.upsell_ids, 'pridavame', this.product)

        if (this.product==null || this.product==undefined || this.product=='') {
          this.upsells.push(this.selected_product)
        }else{
          axios.post(`/products/${this.product}/upsells`, {
            product_id: this.product,
            upsell_id: this.selected_product.id,
            discount: this.discount
          }).then((response) => {
            this.load_upsells()
          })
        }
      }
      this.$emit('add-item', this.selected_product)
      this.selected_product = {}
    }
  },
}
</script>

<template>
  <div class="well">
    <h4>Upsell produkty</h4>
    <input type="hidden" id="new_upsells" v-model="upsell_ids" name="upsell_ids">
    <div class="d-flex w-100">
      <div class="w-50">
       <v-select label="name"
              v-model="selected_product"
              :filterable="true"
              :multiple="false"
              :options="products"
              placeholder="Add Product"

              @input="onProductSelect">

      <template #option="option">
        <div class="d-center">
          <img :src="option.small_image_url" v-if="option.small_image_url" class="icoproduct"> {{ option.name }}
        </div>
      </template>
      <template #selected-option="option">
        <div class="selected d-center">
          <img :src="option.small_image_url" v-if="option.small_image_url" class="icoproduct"> {{ option.name }}
        </div>
      </template>
    </v-select>
      </div>


      <div class="input-group mb-3" style="width: auto; margin-left:30px;">
        <div class="input-group-prepend">
          <span class="input-group-text">sleva</span>
        </div>
        <input type="text" class="form-control" v-model="discount" style="width: 80px">
        <div class="input-group-append">
          <span class="input-group-text">%</span>
        </div>
      </div>

      <div style="margin-left:30px;">
        <button class="btn btn-primary btn-sm ml-2" @click.prevent="handleAddItem">Přidat</button>
      </div>
    </div>

    <div class="row mt-2">

      <div v-for="up in upsells" class="col-md-4">
          <div class="card">
            <button class="delete-button" @click.prevent="deleteUpsell(up.id)" title="smazat" type="button"><svg  aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="" class="svg-inline--fa fa-trash fa-w-14"><path  fill="currentColor" d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"></path></svg></button>

            <div class="card-body text-center">
              <img :src="up.small_image_url" v-if="up.small_image_url" class="icoproduct2">
              <div><b class="card-title">{{up.name}}</b></div>
              <p class="card-text">{{up.description}}</p>
              <p v-if="up.discount>0">sleva: {{ up.discount }} %</p>
            </div>
          </div>
      </div>

    </div>


  </div>
</template>

<style scoped>

.delete-button {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    color: red;
    border: none;
    padding: 2px 5px;
    cursor: pointer;
  }

.delete-button:hover {
  background-color: salmon;
}

  .icoproduct2 {
    max-width: 90px;
    max-height: 60px;
    margin: 0 auto;
  }
</style>