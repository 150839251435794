<script>

import {VueFinalModal} from "vue-final-modal";
export default {
  name: "NotInGroup",
  components: {VueFinalModal},
  props: ['lesson'],
  data() {
    return {
      d: this.lesson.exercise.data_object,
      showM: false,
      current_row: null,
      current_row_index: null
    }
  },
  watch: {
    lesson() {
      this.d = this.lesson.exercise.data_object


    },
  },
  methods: {
    labelcss_by_config(r, i) {
      return this.d.wrong[r] == i ? 'label-important' : 'label-success'
    },
    editRow(row, row_index){
      this.current_row = row
      this.current_row_index = row_index
      this.showM = true
    },
    hideModal(){
      this.showM = false
    },
    is_word_active(i){
      return i == this.d.wrong[this.current_row_index]
    },
    setTotingroup(i){
      this.d.wrong[this.current_row_index] = i
    },
    unSetTotingroup(i){
      this.d.wrong[this.current_row_index] = null
    },
    addWord(){
      this.current_row.push('')

    },
    removeWord(index){
      this.current_row.splice(index, 1)
    }

  }
}
/*
* "d": {
      "timer": "0",
      "aboveText": "Z každých dvou vět vyberte tu, kde chybí čárky ve větě.",
      "secondaryAboveText": "Choose the sentence where are missing comas.",
      "exerciseText": "",
      "belowText": "",
      "tab": "editor-tab",
      "word": [
        [
          "I live in a house which was built in 2005.",
          "Their house which is new is too big."
        ],
        [
          "The book which you lent me is boring.",
          "My new book which I bought on Monday is amusing."
        ],
        [
          "In my parents' house where I often sleep is always cold.",
          "I would like to visit the place where turtles live."
        ],
        [
          "The cat which I saw yesterday has only three legs.",
          "My cat which is lost is probably dead now."
        ],
        [
          "The singer who sings with Linkin Park is seriously ill.",
          "New singer in our band who loves to wear black clothes is seriously ill."
        ]
      ],
      "wrong": [
        "1",
        "1",
        "0",
        "1",
        "1"
      ]
    },
  */
</script>

<template>

  <div class="well">
    <div class="row-fluid">
      <div class="span6">
        <div class="control-group"><label for="aboveText" class="control-label optional">Text nad cvičením</label>
          <div class="controls">
            <textarea v-model="lesson.exercise.aboveText"  name="aboveText" id="aboveText" rows="8" class="span12" cols="80">Je čas, abyste si nacvičili správný pravopis nových slov. Poslechněte si nahrávku a napište slova do křížovky.</textarea>
            <p class="help-block" style="color: #999;">Text, který se zobrazí nad cvičením. Měl by obsahovat zadání cvičení, kde studenta informujeme o tom, co od něho ve cvičení požadujeme a jak má postupovat.</p>
          </div>
        </div>
      </div>
      <div class="span6">
        <div class="control-group"><label for="secondaryAboveText" class="control-label optional">Sekundární text nad cvičením</label>
          <div class="controls">
            <textarea v-model="lesson.exercise.secondaryAboveText" name="secondaryAboveText" id="secondaryAboveText" rows="8" class="span12" cols="80"></textarea>
            <p class="help-block" style="color: #999;">Sekundární text nad cvičením - slouží zejména pro text ve vyučovaném jazyce</p>
          </div>
        </div>
      </div>
    </div>
  </div>



  <div class="well" id="not-in-group">
    <div id="nig_rows">
      <p>
        <a class="add btn btn-info" data-toggle="modal" href="#nig_edit-row"><i class="icon-plus-sign icon-white"></i> Přidat řádek</a>
      </p>
      <hr>

      <div id="nig_row-container">
        <div class="row words-row" v-for="(row,r) in d.word">
          <div class="pull-left words">
            <span :class="`word label ${labelcss_by_config(r,i)}`" v-for="(w,i) in row">{{w}} </span>
          </div>

          <div class="pull-right">
            <a class="edit btn btn" data-toggle="modal" href="#nig_edit-row"  @click.prevent="editRow(row, r)"><i class="icon-edit"></i></a>
            <a href="#" class="remove btn btn-danger" ><i class="icon-remove icon-white"></i></a>
          </div>
          <div class="clearfix"></div>
          <hr>
        </div>
      </div>
      <div class="alert alert-info">
        Po kliknutí na tlačítko "přidat řádek" se zobrazí modální okno pro přidání skupiny slov.
      </div>
      <p>
        <a class="add btn btn-info" data-toggle="modal" href="#nig_edit-row"><i class="icon-plus-sign icon-white"></i> Přidat řádek</a>
      </p>
    </div>



  </div>

<div class="control-group"><label for="aboveText" class="control-label optional">Text nad cvičením</label>
          <div class="controls">
            <textarea v-model="lesson.exercise.belowText"  rows="8" class="span12" cols="80"></textarea>
            <p class="help-block" style="color: #999;">Text, který se zobrazí pod cvičením. Může obsahovat další doplňující text, například zopakování výuky, apod.</p>
                      </div>
        </div>



<pre>
  {{d}}
</pre>

  <vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f w800 ">
    <div class="modal-header">
      <b>Řádek</b>
      <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">

    <div class="alert alert-info">
      Do formulářových polí níže napište jednotlivá slova. Kliknutím na tlačítko "nepatří" označíte slovo, které nepatří do skupiny.
    </div>



    <table style="width: 100%;">

      <tbody class="nig_container" v-for="(w,i) in current_row">
        <tr>
          <td>
            <input class="word form-control" type="text" v-model="current_row[i]">

          </td>
          <td>
            <div class="btn-group" data-toggle="buttons-radio">
              `                                           <a href="#" :class="`btn btn-success ${!is_word_active(i) ? 'active':''}  in`" @click.prevent="unSetTotingroup(i)">Patří</a>
              <a href="#" :class="`btn btn-danger wrong ${is_word_active(i) ? 'active':''}`"  @click.prevent="setTotingroup(i)">Nepatří</a>
              <a href="#" class="remove btn btn-danger" @click.prevent="removeWord(i)"><i class="icon-remove icon-white"></i></a>
            </div>
          </td>
        </tr>
        <tr class="bottomrowtr">
            <td colspan="2">
              <label><strong>Vysvětlení</strong>  </label>
              <textarea class="explanation form-control" rows="2" cols="10"></textarea>
            </td>
        </tr>
      </tbody>
    </table>
    </div>
<div class="modal-footer">
    <a href="#" class="add btn btn-info" @click.prevent="addWord">
      <i class="icon-plus-sign icon-white"></i> Přidat slovo</a>
</div>

  </vue-final-modal>


</template>

<style scoped lang="scss">
.btn{
  line-height: 20px;

  height: 34px;
}


#nig_row-container .word {
  font-size: 2em;

  margin-left: 30px;



}


.words{
  .label, .badge {
    font-size: 11.844px;
    font-weight: bold;
    line-height: 14px;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
    white-space: nowrap;
    vertical-align: baseline;

    padding: 1px 4px 2px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;

  }

}


.word {
  display: inline;

}
.label-success, .badge-success {
  background-color: #468847;

}
.label-important, .badge-important {
  background-color: #b94a48;

}
.btn-danger {
  color: #fff;

}

.words-row{
  margin-bottom: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #ccc;
}


.btn.active, .btn:active {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.6) inset, 0 1px 2px rgba(0, 0, 0, 0.05);
}

.bottomrowtr{

  td{
    padding-bottom: 20px;
  }

}


.nig_container {
  .btn{
    line-height: 20px;

    height: 34px;
  }
  label{
    margin:0;
  }
}

.modal-body {
  max-height: 500px;
  padding: 15px;
  overflow-y: auto;
}
</style>