<script>
import AppTable from "../form-generator/AppTable.vue";

export default {
  name: "products",
  components: {
    AppTable
  },
  data() {
    return {
      fields: [
           // Název 	počet kurzů 	Typ produktu 	V prodeji na OnlineJazyky
        {
          label: "Název",
          codename: "name",
        },
        { label:"var",
          codename:'variant_name'
        },

        {
          label: "Typ produktu",
          codename: "human_kind_name",
        },
        {
          label: "	V prodeji na OnlineJazyky",
          codename: "oj_sold",
          display_filter: 'boolean',
        },
        {
          label: "počet kurzů",
          codename: "courses_count",
        }

      ],
      rows: [],
      filters:[

        ],
      app:{
        name:'products',
        data_url: '/products.json',
        hidden_id: false,
        filter_default_hidden: true
      },
    };
  },
  methods: {
    productClicked(item){
      window.location.href='/products/'+item.id;
    }
  }
}
</script>

<template>
 <div class="card card-body index p-0">
    <app-table :app="app"
              :fields="fields"
              :default_per="50"
              :enable_search="true"
              :filters="filters"
               @itemClick="productClicked"

    ></app-table>
 </div>
</template>

<style>
  .cell-name, .cell-courses_count, .cell-human_kind_name, .cell-oj_sold{
    cursor: pointer;
  }
</style>