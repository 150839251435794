<script>

import AppTable from "./form-generator/AppTable.vue";
import {VueFinalModal} from "vue-final-modal";
import modalKkMixin from "./conversation-club/modal-kk-mixin";
export default {
  name: "student-devices-tokens",
  components: {AppTable, VueFinalModal},
  props: ['student'],
  mixins:[modalKkMixin],
  data(){
    return({
        organizations:[],


      filters: [
          {type: 'search'},
        /*  { type:'select',
              label: 'system',
              codename: 'kind',
              options:['All','apple', 'android']
          }*/
      ],
      fields:[

        {"label":"Student",
          "type":"text",
          "codename":"email"},

        {"label":"Token",
          "type":"user",
          "codename":"token"
        },
        {
          "label":"System",
          "type":"",
          "codename":"kind"
        },
        {"label":"Vytvorene",
          "type":"datetime",
          "display_filter":'dateTime',
          "codename":"created_at"
        }

      ]

      ,
      app:{
        data_url: `/devices?student_id=${this.student}`,
        destroy_url: '/devices/:id',
        actions: "delete"
      }

    })
  },
  methods:{
    handleBtnOpen(){
      this.app.data_url = `/devices?student_id=${this.student}`
      console.log('open modal', this.student)
      this.showModal()
    }
  },
  mounted() {
    console.log('mount ap devs', this.student)
  }
}


</script>

<template>
<span class="app-token-modal">
  <a href="#" class="ml-2"  @click.prevent="handleBtnOpen"> <span class="material-icons-outlined mala-ikona">
phone_iphone
</span>devices</a>

  <vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f w900">
    <div class="modal-header">
      <b>Detail studenta</b>
        <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>

    </div>

    <div class="modal__content" v-if="student>0 && showM">

      <AppTable :app="app" :fields="fields" :enable_search="false" :filters="filters"></AppTable>

    </div>
  </vue-final-modal>


</span>
</template>

<style lang="scss">

.mala-ikona{
  font-size: 100%;
  position: relative;
  top:2px
}

.app-token-modal{
  .vfm{
    font-weight: normal;
    font-size:90%;
  }


  .cell-token{
    overflow-wrap: anywhere;
  }

}

</style>