import axios from "axios";
import {toast} from "vue3-toastify";
const translatorMixin = {

  data(){
    return{
      total:0,
      page:1,
      per:30,
    }
  },

  methods:{
    handleTplModal() {
      this.$refs.aiTemplatesModal.showModal()
    },

    detectTpl(tpl){
      this.active_template = tpl
    },

    pageChanged(p) {
      this.page = p
      this.loadItems()
    },

    runTranslation(ids, kind){

      const data={
        course_id: this.course,
        kind: kind,
        ids: ids
      }

    if ( confirm(`Opravdu chcete odeslat požadavek k překladu ${ids.length} položek?`)) {
      axios.post('/paja/courses_translator/run_translator', data).then((resp) => {
        //this.loadWords()
        //this.$toasted.show("Spustil se překlad, výsledky se brzy objeví", {type: 'info'});
        toast("Spustil se překlad, výsledky se brzy objeví", {type: 'info'});
        this.translator_evnt_id = resp.data.translator_event.id
        setTimeout(this.getTranslatorStatus, 2000)
      })
    }
  },

    getTranslatorStatus(){
      if (!this.translator_evnt_id) return
      axios.get('/paja/courses_translator/translator_status', {params: {id: this.translator_evnt_id}}).then((resp) =>{

        this.te = resp.data.translator_event
        this.waiting_count = resp.data.waiting_count
        if (this.te.status=='created'){
          setTimeout(this.getTranslatorStatus, 1000)
        }
        if (this.te.status=='done'){
          //this.$toasted.show("Překlad dokončen", {type: 'success'});
          toast("Překlad dokončen", {type: 'success'});
          this.status='ai_created'
          this.waiting_count = 0
          this.loadItems()
        }
      })
    },
  }

}
export default translatorMixin