<script>
import studentStatsMixin from "./student-stats-mixin.js";
import moment from "moment";

const sipecky = (v) => {
  // co kdybysme to vsude udelali ze trendy budou vyjadreny nasledovne:
  // ↑99+% vysoky rust, ↑18% normalni rust, ↓16% normalni pokles, ↓99+% vysoky pokles

  let s = "";
  if (v>0){
    s = "↑&nbsp;";
  }
  if (v<0){
    s = "↓&nbsp;";
  }

  if (v>99){
    return s + "99+%";
  }
  if (v<-99){
    return s + "99-%";
  }

  return s + Math.abs(v) + "%";
}

export default {
  name: "trend-table",
  props: {
    stats: Object,
    level: Number
  },
  mixins: [studentStatsMixin],
  methods:{

    trend_level(m, kind_name, level){
      let current = this.findValue(m.students, kind_name, level);
      if (current == null || current == 0){
        return "";
      }

      let previous_m = this.stats.months.find(x => x.month === moment(m.month).subtract(1, 'months').format('YYYY-MM-DD'));
      if (previous_m == null){
        return "---";
      }
      let previous = this.findValue(previous_m.students, kind_name, level);

      if (previous== null){
        return "-";
      }

      let v = Math.round(100 *(current - previous) / current)
      let css=''
      if (v > 0)
        css = "color_plus";
      else
        css = "color_minus";



      return  [sipecky(v), css];
    },
    trend_level_allkind(m, level) {
      let current = this.allKindSum(m.students, level);
      if (current == null || current == 0) {
        return "";
      }

      let previous_m = this.stats.months.find(x => x.month === moment(m.month).subtract(1, 'months').format('YYYY-MM-DD'));
      if (previous_m == null) {
        return "---";
      }
      let previous = this.allKindSum(previous_m.students, level);

      if (previous == null) {
        return "-";
      }

      let v = Math.round(100 * (current - previous) / current)
      let css = ''
      if (v > 0)
        css = "color_plus";
      else
        css = "color_minus";

      return [sipecky(v), css];
    },



  }
}
</script>

<template>
  <table class="table-bordered table">
    <tr>
      <th>Období</th>
      <th>Celkem</th>
      <th>Trend</th>
      <th v-for="s in sources" colspan="2" class="text-center">
        {{s.label}}
      </th>
    </tr>
    <tr v-for="m in getShortenedMonths()">
      <td>{{ mesic_z_datumu(m.month)}}</td>
      <td>{{allKindSum(m.students, level)}}</td>
      <td :class="trend_level_allkind(m, level)[1]" v-html="trend_level_allkind(m, level)[0]"></td>

      <template v-for="s in sources">
        <td>{{findValue(m.students, s.name, level) }}</td>
        <td :class="trend_level(m, s.name, level)[1]" v-html="trend_level(m, s.name, level)[0]"></td>
      </template>
    </tr>
  </table>
</template>

<style scoped>
td.color_plus {
  color: green;
  background: #e0ffe0;
}
td.color_minus{
  color: darkred;
  background: #ffe0e0;
}
</style>