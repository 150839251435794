<template>
<span>
  <button class='btn btn-danger' @click.prevent="showM=true">Vystavit vratku</button>

  <vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f w600">


    <div class="m-head p-3">
      <a class="close"  href=# @click.prevent="showM=false"><span class="material-icons-outlined">close</span></a>

      <h4>Opravdu chcete vystavit vratku pro objednávku {{order.id_woo}} ?</h4>
    </div>
    <div class="m-body">
      <div>
        <input type="radio" id="one" value="1" v-model="deact" />
        <label for="one">Vystavit vratku a deaktivovat kurzy z objednávky</label>
      </div>
      <div>
        <input type="radio" id="two" value="0" v-model="deact" />
        <label for="two">Vystavit vratku a kurzy nechat</label>
      </div>

      <div class="text-center pt-3">
        <button class="btn btn-danger"  @click="do_refund">Vystavit</button>
      </div>
    </div>

  </vue-final-modal>

</span>
</template>

<script>

import {VueFinalModal} from "vue-final-modal";
export default {
  components: {VueFinalModal},
  name: "refund-dialog" ,
  props: ['order', 'path'],
  data(){
    return {
      showM: false,
      deact: 0
    }
  },
  methods:{

    do_refund(){

      var f = document.getElementById('button_refund_form')
      f.submit();

    }

  }
}
</script>

<style scoped>

</style>