<template>

  <div class="card card-body index p-3">
    <div class="row">
        <div class="col-md-12">
 <pre>
{{items}}
 </pre>
        </div>
    </div>
  </div>


</template>
<script>

import axios from "axios";
export default {
  name: "paja-requestlog",
  data(){
    return {
      items: [],

    }
  },

  mounted() {
    this.loadRequests()
  },
  methods:{

    async loadRequests(){
      const r2 = await axios.get('/openai/requestlog.json',{ params:{}})
      this.items = r2.data.items


    }
  }
}
</script>



<style scoped>

</style>