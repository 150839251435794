<script>
import axios from "axios";

/*
*                                           product_paths GET      /product_paths(.:format)                                                                          product_paths#index
                                                          POST     /product_paths(.:format)                                                                          product_paths#create
                                             product_path PATCH    /product_paths/:id(.:format)                                                                      product_paths#update
                                                          PUT      /product_paths/:id(.:format)                                                                      product_paths#update
                                                          DELETE   /product_paths/:id(.:format)                                                                      product_paths#destroy

* */

export default {
  props: ['product'],
  data() {
    return {
      paths: [],
      pp:{
        path: '',
        months: 1,
      }
    }
  },
  mounted() {
    this.loadPaths()
  },
  methods: {

    loadPaths(){
      axios.get('/product_paths', {
        params: {
          product_id: this.product
        }
      }).then(response => {
        this.paths = response.data.product_paths
      })
    },


    handleAddPath(){

      axios.post('/product_paths', {
        product_id: this.product,
        product_path: {
          product_id: this.product,
          path: this.pp.path,
          months: this.pp.months
        }
      })

      this.paths.push(this.pp)
      this.pp = {
        path: '',
        months: 1,
      }
    },

    handleRemovePath(path){
      if (!confirm('Opravdu smazat?')) return

      axios.delete('/product_paths/' + path.id, {params: {product_id: this.product}})
      this.paths = this.paths.filter(p => p.id !== path.id)
    }

  },


  name: "ProductPathEditor"
}
</script>

<template>
  <div class="well">
    <h4>Cesty pro nákup produktu v různých délkách předplatného</h4>

    <table class="table table-striped bg-light">
      <thead>
        <tr>
          <th>Path</th>
          <th></th>
          <th>Délka předplatného / months</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
      <tr v-for="path in paths">
        <td>{{path.path}}</td>
        <td><a :href="`/objednavka/${path.path}`" target="_blank">{{`/objednavka/${path.path}`}}</a></td>
        <td>{{path.months}}</td>
        <td><a class="rm" title="smazat" href="#" @click.prevent="handleRemovePath(path)"><svg class="svg-inline--fa fa-trash fa-w-14" aria-hidden="true" focusable="false"
                                        data-prefix="fas" data-icon="trash" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"></path></svg></a></td>
      </tr>
      </tbody>
    </table>

    <div class="d-flex">
        <label class='label mt-2 ml-4 mr-1' for="path">Path</label>
        <input type="text" name="path" v-model="pp.path" class="form-control"/>


        <label class='label mt-2 ml-4 mr-1'  for="months">Months</label>
        <input type="number" name="months" class="form-control" v-model="pp.months"/>


        <button class="btn btn-primary btn-sm ml-2" @click.prevent="handleAddPath">Přidat</button>



    </div>

  </div>
</template>

<style scoped>

a.rm{
  color:red;
  cursor: pointer;
}

</style>