<script >
import { VueFinalModal } from 'vue-final-modal'
import axios from "axios";

import modalKkMixin from "./modal-kk-mixin";



export default {
  props: ['student'],
  components: { VueFinalModal },
  mixins: [modalKkMixin],
  name: "begginers",
  data(){
    return {

    }
  },
  methods:{
    disableDialog(){
      axios.put(`/conversation_club/students/${this.student}`, { student: {
          cc_intro_flag: true
        } }).then(response =>{
        this.hideModal()
      })



    },

    btnOpenProfil(){
      this.hideModal()
      this.$emit('openProfil')
    }
  }
}


</script>

<template>
  <vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f">


    <p>Před první rezervací si v Profilu nastavte:</p>

<ul>



  <li>Jméno</li>
      <li>Příjmení</li>
        <li>Přezdívku</li>
          <li>Telefonní číslo</li>

</ul>

    <p>Děkujeme!</p>

    <div class="modal__action px-3">
      <button  class="btn btn-primary" @click="btnOpenProfil">jít na nastavení profiu</button>
      <button class="btn btn-outline-dark ml-4" @click="disableDialog">ok, již nezobrazovat</button>
    </div>
  </vue-final-modal>
</template>

<style scoped>

</style>