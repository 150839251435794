<script>
import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip'
import {VueFinalModal} from "vue-final-modal";
import axios from "axios";
import moment from "moment/moment";
//require('../stylesheets/modal.scss')
//import '../stylesheets/modal.scss'

import vSelect from 'vue-select'
import {toast} from "vue3-toastify";
function broofa() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
    return v.toString(16);
  });
}

export default {
  props: ['assignments', 'student', 'courses', 'organization', 'bundles', 'student_tmp_id'],
  name: "student-assignments",
  components: {VueFinalModal, vSelect, VueCustomTooltip},

  data(){
    return({
      modalAddPackage:false,
      modalAassignmentDetail:false,
      new_course:{},
      include_in_hr_report: true,
      expiration: "",
      formNewCourse:false,
      assignments2:[],
      selected_bundle:{},
      budnel_expiration:'',
      bundle_trial:false,
      batch_expiration:'',
      selested_count:0,
      allSelected: true,
      current_assignment:null
    })
  },
  mounted() {
    const csfr_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csfr_token;
    axios.defaults.withCredentials=true
    this.assignments2 = this.assignments
    this.selectAllRows(true)
  },
  watch:{
    new_course: 'newCourseSelected'
  },
  computed:{
    assignments_json(){
      return JSON.stringify(this.assignments2)
    },
    assignments2_trial(){
      var arr=[]
      for (var i = 0; i < this.assignments2.length; i++) {
       // console.log('assignments2_trial()', this.assignments2[i])

        if (this.assignments2[i] && this.assignments2[i].trial) {
          arr.push(this.assignments2[i])
        }
      }
      return arr
    },
    assignments2_notrial(){
      var arr=[]
      for (var i = 0; i < this.assignments2.length; i++) {
        if (!this.assignments2[i].trial) {
          arr.push(this.assignments2[i])
        }
      }
      return arr
    }

  },
  methods:{
    dateTime(value) {
      value = moment(value).format('DD. MM. YYYY, H:mm:ss')
      return value
    },
    show_assignment_detail(c){
      if (!c) return false
      this.current_assignment = c
      //this.$modal.show('assignment-detail')
      this.modalAassignmentDetail = true
    },


    selectedIds(){
      var changes=[]
      for( var i in this.assignments2){
        if (this.assignments2[i].selected_global_op  ){
          if (this.assignments2[i].id>0){
            changes.push(this.assignments2[i].id)
          }
        }
      }
      return changes
    },

    batchSetOrganization(){

      const ids = this.selectedIds();
      this.setOrganization(ids)
    },

    setOrganization(ids){
      const url = `/students/${this.student.id}/update_assignment_organization`
      axios.put(url, {
        assignment_ids: ids,
        organization_id: this.organization ? this.organization.id : null}
      ).then(response => {
        var arr = response.data.success
        for(var i in arr){
          for( var j in this.assignments2){
            if (this.assignments2[j].id == arr[i].id){
              this.assignments2[j].organization_id = arr[i].organization_id
              this.assignments2[j].organization_name = arr[i].organization_name
            }
          }
        }

        if (response.data.errors.length>0){
          var et="<div><b>Vyskytly se chyby</b></div>"
          for (var j in response.data.errors){
            var e = response.data.errors[j]
            et+= `<div>${e.name}: ${e.error}</div>`
          }

          toast(et, {type: 'error',  "dangerouslyHTMLString": true});
        }else{

          toast("Přiřazení proběhlo", {type: 'success'});
        }

      })
    },

    selectAllRows(val){
      for( var i in this.assignments2){
        if (!this.assignments2[i].trial)
          this.assignments2[i].selected_global_op=val
      }
      this.cbGlobalOpClicked()
    },

    cbGlobalOpClicked(){

      if (!this.assignments2) return false
      var ch=0
      for( var i in this.assignments2) {
        if (this.assignments2[i].selected_global_op) ch++
      }


      this.selested_count = ch


      this.allSelected = (ch >= this.assignments2_notrial.length)
    },

    batchRemove(){
      let selectedCount =0
      for( var i in this.assignments2) {

        if (this.assignments2[i].selected_global_op) selectedCount++
      }
      if (!confirm(`Opravdu smazat ${selectedCount} kurzů?`)){
        return false
      }


      var changes=[]
      for( var i in this.assignments2){
        if (this.assignments2[i].selected_global_op  ){

          if (this.assignments2[i].id>0){
            changes.push(this.assignments2[i].id)
          }else{
            this.assignments2.splice(i, 1)
          }
        }
      }

      if (changes.length>0){
        const url = `/students/batch_destroy_assignment`
        axios.delete(url, {withCredentials: true, params: {student_id: this.student.id, ids: changes.join(',')}}).then(response => {


          for (var j in response.data.assignments){
            let k = response.data.assignments[j]
            this.remove_assignment_by_id(k.id)
          }


          toast("Kurzy odstraněny", {type: 'success'});
        }).catch(err => {

          toast(err.response.data.error, {type: 'error'});
        })
      }

    },

    batchDateExp(){
      // batch_expiration
      var changes=[]
      for( var i in this.assignments2){
        if (this.assignments2[i].selected_global_op  ){
          // console.log('TO', i, this.assignments2[i])
          this.assignments2[i].expiration = this.batch_expiration
          if (this.assignments2[i].id>0){
            changes.push({
              id: this.assignments2[i].id,
              expiration: this.batch_expiration
            })
          }
        }
      }

      const url = `/students/${this.student.id}/update_assignment_expiration`
      axios.put(url, {changes: changes} ).then(response => {

      })



    },


    show_package_modal(){
      //this.$modal.show('add-package')
      this.modalAddPackage = true
    },

    assignBundle(){

      if (this.student.id>0) {
        const url = `/students/${this.student.id}/bundles`
        const data = {
          bundle_id: this.selected_bundle.id,
          expiration: this.budnel_expiration,
          trial: this.bundle_trial,
          organization_id: this.organization ? this.organization.id : null,
          organization_name: this.organization ? this.organization.name : null,
          bundle_name: this.selected_bundle.name,
          format: 'json'
        }
        axios.post(url, data).then(response => {
          this.assignments2 = response.data.assignments
          for( var i in this.assignments2){
            if (!this.assignments2[i].trial) this.assignments2[i].selected_global_op=true
          }

          toast("Balíček kurzů byl úspěšně přiřazen", {type: 'success'});
          //this.$modal.hide('add-package')
          this.modalAddPackage = false
        }).catch(err => {

          toast(err.response.data.error, {type: 'error'});

        })
      }else{

        for( var i in this.selected_bundle.courses){
          var c = this.selected_bundle.courses[i]
          this.assignments2.push({
            course_id: c.id,
            include_in_hr_report: true,
            expiration: this.budnel_expiration,
            student_id: this.student ? this.student.id : null,
            organization_id: this.organization ? this.organization.id : null,
            tmp_id: broofa(),
            course_name: c.name,
            selected_global_op: true
          })
        }
      }
    },

    newCourseSelected(){

      if ( this.new_course && this.new_course.id>0) {
        this.createAssignment()
      }
    },

    changeValue(col, item) {
      // console.log('ch', col, item[col], item.id)
      const url = `/students/${this.student.id}/update_assignment`
      axios.put(url, {col: col, value: item[col], assignment_id: item.id}).then(response => {
      })
    },

    remove_assignment_by_id(id){
      for (var i = 0; i < this.assignments2.length; i++) {
        if (this.assignments2[i].id == id) {
          this.assignments2.splice(i, 1)
        }
      }
    },

    destroyAssignment(c){

      if (c.id) {

        const url = `/students/destroy_assignment/${c.id}`
        axios.get(url, {withCredentials: true}).then(response => {
          // console.log('prd', response.data)

          const id = response.data.assignment.id
          this.remove_assignment_by_id(id)

          toast("Kurz odstraněn", {type: 'success'});
        }).catch(err => {

          toast(err.response.data.error, {type: 'error'});
        })

      }else{
        for (var i = 0; i < this.assignments2.length; i++) {
          if (this.assignments2[i].tmp_id == c.tmp_id) {
            this.assignments2.splice(i, 1)
          }
        }
      }

    },

    createAssignment(){

      const data = {
        course_id: this.new_course ? this.new_course.id: null,
        include_in_hr_report: this.include_in_hr_report,
        expiration: this.expiration,
        student_id: this.student ? this.student.id : null,
        organization_id: this.organization ? this.organization.id : null,
        tmp_id: broofa(),
        course_name: this.new_course.name,
        selected_global_op: true
      }

      if (this.student.id) {
        const url = `/students/create_assignment`
        axios.post(url, data).then(response => {
          if (!response.data.exist) {
            this.assignments2.unshift(response.data.assignment)
            this.new_course = {}
          } else {

            toast("Kurz tam už je", {type: 'info'});
          }

          if (response.data.errors){

              toast(response.data.errors, {type: 'error'});
          }

          this.formNewCourse = false
          this.new_course = {}

        }).catch(err => {

          toast(err.response.data.error, {type: 'error'});

        })

      }else{
        this.assignments2.unshift(data)
        this.formNewCourse = false
        this.new_course = {}
      }

    }

  }
}
</script>
<template>
  <div>
    <div class="border-bottom  mb-2" style="border-color: #0095fA10">
      <strong>Kurzy</strong>
    </div>


    <div class="row ml-2" v-if="selested_count>0" :key="`globalops${selested_count}`">
      <div class='mr-1' style="line-height: 36px;">Hromadně vyplnit datum expirace</div>
      <input type="date" id="batch-date-preset" class="form-control" style="width: 160px" v-model="batch_expiration"/>
      <button class="btn ml-1 btn-outline-success btn-sm" type="button" @click="batchDateExp">nastav</button>

      <div class="ml-3">
        <button @click.prevent="batchRemove()"  class="btn btn-smallish btn-outline-danger"><svg class="svg-inline--fa fa-trash fa-w-14" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"></path></svg> smazat označené</button>
      </div>

      <div class="col-md-2" v-if="organization && organization.id>0">
        <VueCustomTooltip :label="`Nastav organizaci u vybraných `"><button  class='btn btn-sm btn-outline-dark' @click.prevent="batchSetOrganization">
          <i  class="material-icons text-warning">supervised_user_circle</i></button></VueCustomTooltip>


      </div>

    </div>


    <div class="d-flex flex-row mt-1 mb-2">
      <button v-if="!formNewCourse" type="button" data-action="dynamic-form#add"
              class="btn btn-outline-primary btn-sm "
              @click.prevent="formNewCourse=true">
        <i class="fas fa-plus-circle"></i>
        kurz
      </button>

      <a class='btn btn-sm btn-outline-primary ml-2' @click.prevent="show_package_modal">
        <i class="fas fa-plus-circle"></i>
        balíček
      </a>
    </div>




    <table class="table table-bordered">
      <thead>
      <tr>
        <th >
          <a class="cursor-pointer" v-if="!allSelected" @click="selectAllRows(true)">Označ vše</a>

          <a  v-if="allSelected" class="cursor-pointer" @click="selectAllRows(false)">Zruš označení</a>

        </th>
        <th style="width:5%">Org.</th>
        <th>Balík</th>
        <th class="small">Objed návka</th>
        <th style="width:60%">Kurz</th>
        <th colspan="1">Expirace</th>
        <th>Report</th>

        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="formNewCourse" class="inserter">
        <td colspan="4"></td>
        <td><v-select :clearable="false"
                      v-model="new_course"
                      :options="courses"
                      :searchable="true"
                      label="name"></v-select>
        </td>
        <td><input type="date" class="form-control" v-model="expiration"></td>
        <td>
          <input type="checkbox" v-model="include_in_hr_report"/>
        </td>
        <td colspan="1">
          <button @click.prevent="createAssignment()" class="btn btn-smallish btn-outline-success">Přidat</button>
        </td>
      </tr>

      <template  v-for="c in assignments2" v-if="assignments2">
        <tr v-if="c && !c.trial">
          <td>
            <input type="checkbox" checked="checked" v-model="c.selected_global_op"  @change="cbGlobalOpClicked"/>
          </td>
          <td>


            <VueCustomTooltip v-if="c.organization_id>0"
                              :label="`V rámci organizace ${c.organization_name}`"><i  class="material-icons text-warning" >supervised_user_circle</i>
            </VueCustomTooltip>
          </td>

          <td>
            <VueCustomTooltip :label="`z  balíčku ${c.bundle_name}`" v-if="c.bundle_id>0"><span class="material-icons-outlined small" v-if="c.bundle_id>0">
              inventory_2
            </span></VueCustomTooltip>
          </td>

          <td>

        <span v-if="c.subscription_id>0">
          <a title='Předplatné' :href="`/subscriptions/${c.subscription_id}`"><span class="material-icons-outlined small">schedule</span></a>
        </span>
            <span v-else-if="c.order_id>0"><a title='Objednavka' :href="`/orders/${c.order_id}`">
          <span class="material-icons-outlined small">open_in_new</span></a>
        </span>

          </td>

          <td>{{c.course_name}}
            <a class='float-right small' @click.prevent="show_assignment_detail(c)">i</a>
          </td>

          <td>
            <input type="date" class="form-control" v-model="c.expiration" @change="changeValue('expiration',c)">
          </td>
          <td><input type="checkbox"
                     v-model="c.include_in_hr_report"
                     @change="changeValue('include_in_hr_report',c)"/> </td>

          <td><button @click.prevent="destroyAssignment(c)"  class="btn btn-smallish btn-outline-danger"><svg class="svg-inline--fa fa-trash fa-w-14" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"></path></svg></button></td>
        </tr>
      </template>
      </tbody>
    </table>

    <!------------------------------------------------------------------------------------------------------------------>

   <!-- <modal name="add-package" :resizable="true" :adaptive="false" :height="460">-->
      <vue-final-modal v-model="modalAddPackage" classes="modal-container" content-class="modal-content-f w600">

      <div class="m-head">
        <a class="close"  href=# @click.prevent="modalAddPackage=false"><span class="material-icons-outlined">close</span></a>
      </div>
      <div class="m-body">
        <div>Přiřadit balíček kurzů</div>
        <div class="d-flex">
          <div class="col-8 mx-0 px-0">
            <v-select :clearable="false" v-model="selected_bundle" :options="bundles" :searchable="true" label="name"></v-select>
          </div>
          <div>
            <input type="date" class="form-control" v-model="budnel_expiration">
          </div>
          <div>
            trial
            <input type='checkbox' v-model="bundle_trial" />
          </div>
        </div>

        <button class="btn btn-primary" @click.prevent="assignBundle"> Přiřadit</button>
      </div>


    </vue-final-modal>

    <!--<modal name="assignment-detail" :resizable="true" :adaptive="false" :height="460">-->
      <vue-final-modal v-model="modalAassignmentDetail" classes="modal-container" content-class="modal-content-f w600">

    <pre>
    {{current_assignment}}
    </pre>
      </vue-final-modal>


    <input type="hidden" name='student[assignments_json]' :value="assignments_json" />

    <div v-if="assignments2_trial.length>0" class="h5 mt-3">Trial</div>

    <table class="table table-bordered" v-if="assignments2_trial.length>0">
      <thead>
      <tr>
        <th style="width:60%">Kurz</th>
        <th>přiřazení</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="c in assignments2_trial">
        <td>{{c.course_name}}</td>
        <td>{{dateTime(c.created_at)}}</td>

        <td><button @click.prevent="destroyAssignment(c)"  class="btn btn-smallish btn-outline-danger"><svg class="svg-inline--fa fa-trash fa-w-14" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"></path></svg></button></td>
      </tr>
      </tbody>
    </table>




    <pre>

  </pre>
  </div>

</template>



<style scoped lang="scss">
.inserter{
  background: #f0f0ff;
}

.material-icons-outlined.small{
  font-size:20px;
}
</style>