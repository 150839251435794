<script>
export default {
  name: "image-picker",
  props: ['src'],
  data(){
    return {
      imgsrc: this.src ? `https://editor.onlinejazyky.cz${this.src}`: null,
    }
  },
  watch:{
    src(){
      this.imgsrc = `https://editor.onlinejazyky.cz${this.src}`
    }
  },
  methods:{
    handleClickF(){
      this.$refs.pinimagefile.click()
    },
    handleUploadImage(e) {
      let f = e.target.files[0]
      this.$emit('imageChange', f)
      this.avatar = f
      this.imgsrc = URL.createObjectURL(f)
    }
  },
}
</script>

<template>
  <img :src="imgsrc" class="form-image"/>
  <button @click="handleClickF()">nahrat obrazek</button>
  <input type="file" name="add-file-ico" class="hidden" v-on:change="handleUploadImage($event)"
         ref="pinimagefile">
</template>

<style scoped>
.form-image{
  max-width: 200px;
  display: block;
  margin: auto;
}

</style>