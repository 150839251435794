<script>

import axios from 'axios'
export default {
  name: "dashboard-topstudy",
  props: ['month', 'year'],
  data() {
    return ({
      top_study: [],

    })
  },
  mounted() {
    this.loadTopStudy()
  },
  methods: {
    loadTopStudy() { // students_stats/top10study
      axios.get('/students_stats/top10study.json', {params: {month: this.month, year: this.year}}).then(response => {
        this.top_study = response.data.top10
      })
    }
  }
}
</script>

<template>
  <table class="minitable">
    <thead>
      <tr>
        <th>Name</th>
        <th>{{ year }}</th>
        <th>{{ year - 1 }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="study in top_study">
        <td>{{ study.course_name }}</td>
        <td>{{ study.count }}</td>
        <td>{{study.count_year_ago}}</td>
      </tr>
    </tbody>
  </table>

</template>

<style scoped>

</style>