<script>

import axios from "axios";

export default {
  name: 'FilterWooEshopId',
  props: ['value', 'filter'],
  data(){
    return {
      eshops: [],
      woo_eshop_id_eq: ''
    }
  },
  mounted() {
    this.loadEshops()
  },
  watch:{
    woo_eshop_id_eq(){
      this.$emit('input', this.filter, this.woo_eshop_id_eq)
    },
    value(){
      this.woo_eshop_id_eq = this.value
    }
  },
  methods:{
    loadEshops(){
      axios.get('/api/woo/eshops.json' ).then(response =>{
        this.eshops = response.data.eshops
      })
    },
  }
}

</script>

<template>
  <div class="col-md-4"><label>Eshop</label>
    <select  class="form-control " v-model="woo_eshop_id_eq">
      <option value="">Všechny</option>
      <option v-for="e in eshops" :value="e.id ? e.id : e.symbol">{{e.json_name}}</option>
    </select>
  </div>

</template>

<style scoped>

</style>