<script>

import translatorMixin from "./translator-mixin";
import axios from "axios";
import OjPagination from "../OjPagination.vue";
import ModalTemplates from "./modal-templates.vue";
import {VueFinalModal} from "vue-final-modal";
export default {
  name: "exercises",
  props:['course', 'status'],
    mixins:[translatorMixin],
  components:{ModalTemplates, OjPagination , VueFinalModal},
  data(){
    return{
      nav:1,
      courses:[],
      explanations:[],
      exercises:[],
      search:'',
      items:[],
      lb_ids:[],
      all_ids:[],
      selected_course:{},
      active_template:{},
      show_translation_modal: false,
      current_item:{}
    }
  },
  watch:{
    course(){
      this.load_exercises()
    },

    status(){
      this.page=1
      this.load_exercises()
    },


  },

  mounted() {
    this.load_exercises()

  },

  methods:{
    pageChanged(p){
      console.log('p ch', p)
      this.page = p
      this.load_exercises()
    },

    load_exercises(){
      axios.get('/paja/courses_translator/exercises', {
        params:
            {
              status: this.status,
              page: this.page,
              search: this.search,
              course_id: this.course,
              per: this.per
            }
      }).then(response => {
        this.items = response.data.items
        this.total = response.data.total
        this.all_ids = response.data.all_ids


        let ids = []
        for(let i in this.items){
          ids.push(this.items[i].lb_id)
        }
        this.lb_ids = ids
      })
    }
,
    runTranslation_zob() {
      this.runTranslation( this.lb_ids, 'exercises')
    },
    runTranslation_all() {
      this.runTranslation( this.all_ids, 'exercices')
    },
    hideModal(){
      this.show_translation_modal=false
    },

    pajaTranslationChange(item, paja_col){
      let id = null
      const content = item[paja_col]
      if (paja_col == 'paja_lesson') id = item.paja3_id
      if (paja_col == 'paja_belowText') id = item.paja2_id
      if (paja_col == 'paja_aboveText') id = item.paja1_id
      if (paja_col == 'paja_plain_data') id = item.paja4_id
      if (paja_col == 'paja_exerciseText') id = item.paja5_id

/*
  tr1.translation as paja_aboveText,
  tr2.translation as paja_belowText,
  tr3.translation as paja_lesson,
tr4.translation as paja_plain_data,*/
      if (id && content) {
        axios.put(`/paja/courses_translator/translation/${id}`, {translation: content})
      }else{
        alert('neni obsah')
      }
    }

  }
}
</script>

<template>
<div>

    <modal-templates ref="aiTemplatesModal" v-on:detectTemplate="detectTpl" :kind="'courses_exercises'"></modal-templates>

    <div class="row mt-2">
      <div class="col-sm-4">
        <a @click.prevent="handleTplModal" href="#">nastavení AI dotazu</a>
        šablona dotazu: <b>{{active_template.name}}</b>
      </div>
      <div class="col-sm-5">
          <button @click.prevent="runTranslation_zob" class="btn btn-sm btn-info">prelozit zobrazene ({{lb_ids.length}})</button>
          <button @click.prevent="runTranslation_all"  class="btn btn-sm btn-info">prelozit vybrane ({{all_ids.length}})</button>
      </div>
    </div>





  <div class="col-md-11">
    <OjPagination
      :totalItems="total"
      :currentPage="page"
      :perPage="per"
      @pageChanged="pageChanged" />
  </div>


  <table class="table table-bordered">
    <thead>
      <tr>
        <td>e id</td>
        <td>lb_id</td>
        <td>lesson</td>
        <td>chapter</td>
        <td>plain_data</td>
        <td>description</td>
        <td>item.aboveText</td>

        <td>belowText</td>
        <td>rightText</td>
        <td>exerciseText</td>
        <td>secondaryAboveText</td>
      </tr>
    </thead>
    <tr v-for="item in items">
      <td>{{item.e_id}}</td>
      <td>{{item.lb_id}}</td>
      <td>{{item.lesson}}

      </td>
      <td>{{item.chapter}}</td>
      <td>{{item.plain_data}}</td>
      <td>{{item.description}}</td>
      <td>{{item.aboveText}}

      </td>

      <td>{{item.belowText}}

      </td>
      <td>{{item.rightText}}</td>
      <td>{{item.exerciseText}}</td>
      <td>{{item.secondaryAboveText}}</td>
      <td><a v-if="item.paja1_id || item.paja3_id " href="#" @click.prevent="show_translation_modal=true; current_item = item">Překlad</a></td>
    </tr>
  </table>




  <vue-final-modal v-model="show_translation_modal"  classes="modal-container"  content-class="modal-content-f w900">
    <div class="modal-header">
      <b>Šablony AI dotazů</b>
      <button
          type="button"
          class="close"
          @click.prevent="hideModal"
          aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal__content">
      <div class="row">
        <div class="col-md-5">{{current_item.lesson}}</div>
        <div class="col-md-5"><input class="form-control" type="text" v-model="current_item.paja_lesson" @change="pajaTranslationChange(current_item, 'paja_lesson')" /></div>
      </div>


      <div class="row mt-3">
        <div class="col-md-5">plain_data<textarea readonly class="form-control">{{current_item.plain_data}}</textarea></div>
        <div class="col-md-5"><textarea class="form-control" @change="pajaTranslationChange(current_item, 'paja_plain_data')" v-model="current_item.paja_plain_data" rows="8"/></div>
      </div>

      <div class="row mt-3">
        <div class="col-md-5">aboveText<textarea readonly class="form-control">{{current_item.aboveText}}</textarea></div>
        <div class="col-md-5"><textarea class="form-control" v-model="current_item.paja_aboveText" @change="pajaTranslationChange(current_item, 'paja_aboveText')" rows="8"/></div>
      </div>

      <div class="row mt-3">
        <div class="col-md-5">belowText
          <textarea readonly class="form-control">{{current_item.belowText}}</textarea></div>
        <div class="col-md-5"><textarea class="form-control" v-model="current_item.paja_belowText" @change="pajaTranslationChange(current_item, 'paja_belowText')" rows="8"/></div>
      </div>

      <div class="row mt-3">
        <div class="col-md-5">
          exerciseText
          <textarea readonly class="form-control">{{current_item.exerciseText}}</textarea></div>
        <div class="col-md-5"><textarea class="form-control" v-model="current_item.paja_exerciseText" @change="pajaTranslationChange(current_item, 'paja_exerciseText')" rows="8"/></div>
      </div>

    </div>
  </vue-final-modal>

</div>
</template>

<style scoped>

</style>