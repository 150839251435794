<script>
import modalKkMixin from "../conversation-club/modal-kk-mixin";
import axios from "axios";
import ProductTableDefault from "./product-table-default.vue";
import ProductTableKk from "./product-table-kk.vue";
export default {
  components: {ProductTableKk, ProductTableDefault},
  mixins: [modalKkMixin],
  props: ['cartid'],

  name: "upsell",
  data() {
    return {
      upsells: [] ,
      items: [],
      order_id: null,
      cart: {},
      iframe_gopay_url: null,
    }
  },
  mounted() {


  },
  methods:{
    changeProduct(it) {
      console.log('change product', it, it.selected_variant)
      axios.put(`/shopping_cart/change_product/${it.id}`, {
        product_id: it.selected_variant,
        objid: this.objid
      }).then((response) => {
        this.loadCart()
      })
    },

    loadCart() {
      axios.get(`/shopping_cart/cart`, {params: {objid: this.objid}}).then((response) => {
        this.items = response.data.items;
        this.cart = response.data.cart;
        this.loadUpsell(this.cart.parent_id)
      });
    },

    loadUpsell(cart_id) {
      console.log('2 loadovat upsely pro cart', cart_id)

      axios.get('/shopping_cart/upsell.json', {params: {cart_id: cart_id, upsell_cart_id: this.cart.id}}).then(response => {
        this.upsells = response.data.products


        if (this.upsells.length == 0 && this.items.length == 0) {
          //this.hideModal()
          this.$emit('skipUpsell')
        }
      })

    },
    handleSelectProduct(p, period){
      console.log('handleSelectProduct', p, period)
      axios.post('/shopping_cart/add_product', {
        upsell: true,
        original_cart_id: this.cartid,
        cart_id: this.cart.id,
        objid: this.cart.objid,
        product_id: p.id,
        period: period
      }).then(response => {
        console.log('soucasnej jako', this.objid)
        if (this.objid==undefined || this.objid==null || this.objid==""  ){
          this.objid = response.data.cart.objid
        }
        if (this.objid) this.loadCart()
      })

    },
    handleSkipUpsell(){
      this.$emit('skipUpsell')
    },
    handle_create_order() {
      const data = {
        objid: this.objid
      };
      axios.post(`/shopping_cart/create-order`, data).then((response) => {
        this.payment_id = response.data.payment.id;
        this.paymentSessionId = response.data.gopay_payment_session_id;
        this.encryptedSignature = response.data.encrypted_signature;
        const url = response.data.gopay_url
        if (url) {
          //window.location.href = url;
          this.iframe_gopay_url = url
          window._gopay.checkout({gatewayUrl: url, inline: true});
        }
      }).catch((error) => {
        console.log(error);
        this.$toasted.error("Chyba při vytváření objednávky - " + error.response.data.error, {duration: 5000});
      });
    },

  }
}


</script>

<template>

<div class="container item topdiv">
  <div class="text-center item colourBackground">


    <product-table-kk :items="items" :cart="cart" @changeProduct="changeProduct"
                      v-if="cart.table_template == 'kk'"></product-table-kk>
    <product-table-default :items="items" :cart="cart" @changeProduct="changeProduct" v-else></product-table-default>



    <div class="modal-header" v-if="upsells.length>0">
      <b>Vámi zvolený produkt skvěle funguje v kombinaci s:</b>
    </div>
      <!-- kdyz to uz dal do kosiku a  neda se nic pridat tabulka je prazdna ani senemuzi zobrazovat-->
      <table class="table " v-if="upsells.length>0">
        <thead>
        <tr>
          <th> </th>
          <th>Název produktu</th>
          <th>Doživotní přístup</th>
          <th>Předplatné</th>
        </tr>
        </thead>

        <tbody>

          <tr v-for="p in upsells">
            <td><img :src="p.small_image_url" class="product_img"></td>
            <td>{{p.name}}</td>
            <td>
              <span v-if="p.prices.basic">{{p.prices.basic}} Kč</span>
            </td>
            <td>
              <span v-if="p.prices.monthly">{{p.prices.monthly}} Kč měsíčně</span>
            </td>
          </tr>

          <tr v-for="p in upsells">
            <td> </td>
            <td> </td>
            <td >
              <button v-if="p.prices.basic" type="button" class="btn btn-primary" @click.prevent="handleSelectProduct(p, 0)">Přidat</button>
            </td>
            <td >
              <button v-if="p.prices.monthly" type="button" class="btn btn-primary" @click.prevent="handleSelectProduct(p,1)">Přidat</button>
            </td>
          </tr>

        </tbody>

      </table>


      <button v-if="items.length==0" class="btn btn-outline-primary" style="margin-top: 30px; margin-bottom: 10px" @click.prevent="handleSkipUpsell"> Děkuji, nemám zájem</button>
      <button v-else @click.prevent="handle_create_order()">Objednat</button>

  </div>
</div>
</template>

<style scoped>

.product_img{
 max-width: 100px;
}

</style>