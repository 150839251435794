<script>

import axios from 'axios';
import AppTable from "../form-generator/AppTable.vue";

export default {
  name: 'NotigicationsLog',
  components: {AppTable},
  props: [],
  data() {
    return ({
      app: {
        data_url: '/notifications/index',
        actions: ""
      },
      filters:[
        {
          "label": "Vytvorene",
          "type": "date-range",
          "codename": "created_at"
        },{
          type: 'search'
        }
      ],
      fields: [
        {
          "label": "Student",
          "type": "text",
          "codename": "email"
        },
        {
          "label": " content",
          "type": "text",
          "codename": "content"
        },
        {
          "label": "Datum odeslání",
          "display_filter": "dateTime",
          "codename": "created_at"
        },
      ]
    })
  },


  mounted() {
    this.getNotigications();
  },
  methods: {
    getNotigications() {
      axios.get('/notifications/index')
      .then(response => {
        this.notigications = response.data;
      })
      .catch(error => console.log(error))
    }
  }
}

</script>

<template>
  <div class="card card-body index p-2">
    <h2>Zaslané notifikace</h2>
    <AppTable :app="app" :fields="fields" :filters="filters" ></AppTable>
  </div>
</template>

<style scoped>

</style>