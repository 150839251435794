<script>

import { Bar } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

import axios from 'axios'
import studentStatsMixin from "./student-stats-mixin";
import dashboardTopseller from "@/components/statistics/dashboard-topseller.vue";

import DashboardTopstudy from "@/components/statistics/dashboard-topstudy.vue";
import DashboardEshops from "@/components/statistics/dashboard-eshops.vue";
import DashboardKonvergence from "@/components/statistics/dashboard-konvergence.vue";


import moment from "moment";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: "dashboard.vue",
  components: { Bar, dashboardTopseller , DashboardTopstudy, DashboardEshops, DashboardKonvergence},
  mixins: [studentStatsMixin],

  data() {
    return {
      selected_month: moment().month(),
      selected_year: moment().year(),
      barkey:21321,
      chartData: {
        labels: [ 'Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen', 'Červenec', 'Srpen', 'Září', 'Říjen', 'Listopad', 'Prosinec'],
        datasets: [
              {
                "label": 2024,
                "backgroundColor": "#916bff",
                "data": [
                  0,0,0,0,0,0,0,0,0,0,0,0
                ]
              },
              {
                "label": 2023,
                "backgroundColor": "#5aacff",
                "data": [
                  0,0,0,0,0,0,0,0,0,0,0,0
                ]
              },
              {
                "label": 2022,
                "backgroundColor": "#2777c9",
                "data": [
                  0,
                  0,
                  0,
                  0,
                  11110,
                  110,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
                ]
              }
            ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false ,
        scales: {
          y: {
            display: false
          }
        },
        plugins: {
          legend: {
            //display:false,
            labels: {
             // color: 'blue' // Optional: to change the legend text color
            }
          }
        },
        backgroundColor: 'transparent'
      },

      y_sum_czk: 0,
      y_sum_eur: 0,
      subs_czk: 0,
      subs_eur: 0,
      retence: 0,
      last_year_sum_czk: 0,
      last_year_sum_eur: 0,
      last_year_subs_czk: 0,
      last_year_subs_eur: 0,
      retence_last_year: 0,
       konvergence: '',
      konvergence_last:''
    }
  },
  mounted() {
    this.loadDashboardData()
  },
  methods: {
    mezirocni_srovnani_y_sum_czk(){
      return this.trend(this.y_sum_czk, this.last_year_sum_czk)
    },

    loadDashboardData() {
      // /students_stats/dashboard_data
        axios.get('/students_stats/dashboard_data')
            .then(response => {
                this.y_sum_czk = response.data.y_sum_czk
                this.y_sum_eur = response.data.y_sum_eur
                this.subs_czk = response.data.subs_czk
                this.subs_eur = response.data.subs_eur
                this.retence = response.data.retence
                this.last_year_sum_czk = response.data.last_year_sum_czk
                this.last_year_sum_eur = response.data.last_year_sum_eur
                this.last_year_subs_czk = response.data.last_year_subs_czk
                this.last_year_subs_eur = response.data.last_year_subs_eur
                this.retence_last_year = response.data.retence_last_year
              this.konvergence = response.data.konvergence
              this.konvergence_last = response.data.konvergence_last



                this.chartData.datasets = response.data.years_incomes
                this.barkey++

            })
            .catch(error => {
                console.log('nejaka chyba',error);
            })
    },
    trend(soucasne, minuly){
      if (minuly == 0) {
        return "n/a"
      }
      let trend = ((soucasne - minuly) / minuly)*100

      return Math.round(trend)
    },


  },
}
</script>

<template>
  <div>
    <div class="col-md-auto box">

      <div class="box-cont" style="margin-bottom: 5px;">

        <div class="high-box">
          <p class="grey">Příjmy v CZK</p>
          <div class="box-cont center">
            <h4 class="box-text light-black">{{y_sum_czk}}</h4>
          </div>
        </div>

        <div class="high-box">
          <p class="grey">Příjmy v EUR</p>
          <div class="box-cont center">
            <h4 class="box-text light-black">{{y_sum_eur}}</h4>
          </div>
        </div>

        <div class="high-box">
          <p class="grey">Předplatné CZK</p>
          <div class="box-cont center">
            <h4 class="box-text light-black">{{subs_czk}}</h4>
          </div>
        </div>

        <div class="high-box">
          <p class="grey">Předplatné EUR</p>
          <div class="box-cont center">
            <h4 class="box-text light-black">{{subs_eur}}</h4>
          </div>
        </div>

        <div class="high-box">
          <p class="grey">Konvergence</p>
          <div class="box-cont center">
            <p class="green arrow">▲</p>
            <h4 class="box-text light-black">{{konvergence}}%</h4>
          </div>
        </div>

      </div>

      <div class="box-cont" style="margin-bottom: 15px;">

        <div class="high-box">
          <p class="grey">Meziroční srovnání</p>
          <div class="box-cont center">
            <p :class="`${(y_sum_eur > last_year_sum_eur ? `green`: `red`)} arrow`">▲</p>
            <h4 :class="`box-text ${(y_sum_czk > last_year_sum_czk ? `green`: `red`)}`">{{trend(y_sum_czk, last_year_sum_czk)}}%</h4>
          </div>
        </div>

        <div class="high-box">
          <p class="grey">Meziroční srovnání</p>
          <div class="box-cont center">
            <p :class="`${(y_sum_eur > last_year_sum_eur ? `green`: `red`)} arrow`">▲</p>
            <h4 :class="`box-text ${(y_sum_eur > last_year_sum_eur ? `green`: `red`)}`">{{ trend(y_sum_eur, last_year_sum_eur)}}%</h4>
          </div>
        </div>

        <div class="high-box">
          <p class="grey">Meziroční srovnání</p>
          <div class="box-cont center">
            <p class="red arrow">▼</p>
            <h4  :class="`box-text ${(subs_czk > last_year_subs_czk ? `green`: `red`)}`">{{trend(subs_czk, last_year_subs_czk)}} %</h4>
          </div>
        </div>

        <div class="high-box">
          <p class="grey">Meziroční srovnání</p>
          <div class="box-cont center">
            <p class="green arrow">▲</p>
            <h4 :class="`box-text ${(subs_eur > last_year_subs_eur ? `green`: `red`)}`">{{ trend(subs_eur, last_year_subs_eur) }}%</h4>
          </div>
        </div>

        <div class="high-box">
          <p class="grey">Retence</p>
          <div class="box-cont center">
            <p class="red arrow">▼</p>
            <h4 class="box-text light-black">3%</h4>
          </div>
        </div>

      </div>

<!--      <div class="dd-buttons">-->

<!--        <div class="button-sm">-->
<!--          <div class="button-cont">-->
<!--            <p class="white">2024</p>-->
<!--            <p class="white">▼</p>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="button-sm">-->
<!--          <div class="button-cont">-->
<!--            <p class="white">červenec</p>-->
<!--            <p class="white">▼</p>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="button-sm">-->
<!--          <div class="button-cont">-->
<!--            <p class="white">zvolit období</p>-->
<!--          </div>-->
<!--        </div>-->

<!--      </div>-->

      <div class="charts">

        <div>
          <div class="chart-title">
            <h4 class="title">E-shopy - příjmy</h4>
          </div>
          <div class="chart">

            <DashboardEshops :month="selected_month" :year="selected_year"/>
          </div>
        </div>

        <div>
          <div class="chart-title">
            <h4 class="title">Zákazníci / konvergence</h4>
          </div>
          <div class="chart">
            <DashboardKonvergence :month="selected_month" :year="selected_year"/>
          </div>
        </div>

        <div>
          <div class="chart-title">
            <h4 class="title">Produkty - prodej</h4>
          </div>
          <div class="chart">
            <dashboard-topseller :month="selected_month" :year="selected_year"/>
          </div>
        </div>

        <div>
          <div class="chart-title">
            <h4 class="title">Kurzy - studovanost</h4>
          </div>
          <div class="chart">
            <dashboard-topstudy :month="selected_month" :year="selected_year"/>
          </div>
        </div>

      </div>



        <div class="graph-box">

          <div class="chart-title">
            <h4 class="title center">Příjmy - meziroční srovnání</h4>
          </div>

          <div class="graph">
            <Bar
                :key="barkey"
                :options="chartOptions"
                :data="chartData"
            />
          </div>

        </div>



    </div>
  </div>
</template>

<style scoped>

* {
  margin: 0;
  padding: 0;
}

.arrow {
  margin-right: 20px;
}

.center {
  justify-content: center;
  align-items: center;
}

.dd-buttons {
  display: flex;
  flex-direction: row;
}

.year-buttons {
  display: flex;
  flex-direction: row;
  margin-left: 12px;
}

.button-sm {
  height: 30px;
  width: 100px;
  margin-left: 20px;
  padding: 3px;
  border-width: thin;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(241, 242, 244, 1);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #5aacff;
}

.button-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.box-cont {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.high-box {
  font-family: "Proxima Nova";
  width: 205px;
  height: 70px;
  margin: 15px 21px 15px 22px;
  padding-top: 10px;
  border-width: thin;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(241, 242, 244, 1);
  background-color: white;
  font-weight: bold;
  text-align: center;
}

.box-text {
  font-weight: bold;
}

.charts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
}

.chart-title {
  margin-left: 20px;
  width: 100%;
  background-color: white;
}

.chart {
  width: 270px;
  height: 250px;
  margin: 15px 20px 15px 20px;
  padding-top: 12px;
  background-color: white;
  border-width: thin;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(241, 242, 244, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.years {
  width: 100%;
}

.button-year {
  height: 30px;
  width: 70px;
  margin: 10px;
  padding: 3px;
  border-width: thin;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(241, 242, 244, 1);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.graph-box {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 200px;
}

.graph {
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
  //align-items: center;
  width: 100%;
  height: 180px;
  background-color: white;
  border-width: thin;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(241, 242, 244, 1);
  margin: 15px 20px 15px 20px;
  padding-top: 12px;
}

.grey {
  color: #828283;
}

.light-black {
  color: #252626;
}

.purple {
  background-color: #916bff;
}

.blue0 {
  background-color: #3366ff;
}

.blue1 {
  background-color: #2777c9;
}

.blue2 {
  background-color: #5aacff;
}

.blue3 {
  background-color: #5ac8fa;
}

.blue4 {
  background-color: #a4e3ff ;
}

.green {
  color: #008000 ;
}

.red {
  color: #DC3545;
}

.white {
  color: white;
}
</style>