<template>

  <div>

    <div><b>Přidat ruční prodloužení</b></div>

    <div><label>Počet dní</label></div>
    <input type="number"  class="form-control" v-model="days" />

    <div class="mt-3"><label>Poznámka</label></div>
    <input type="text" class="form-control" v-model="info" />

    <div class="mt-3">
      <button class="btn btn-sm btn-success" @click.prevent="addChange">Přidat</button>
    </div>


  </div>
</template>

<script>
import axios from "axios";

export default {
  props:['subscribtion_id'],
  name: "app-subscrbtion-chnages",
  data(){
    return({
      days: 30,
      info: ''
    })
  },

  methods: {
    addChange(){
      axios.post(`/subscriptions/${this.subscribtion_id}/add-change`, {
        days: this.days,
        info: this.info
      }).then(response => {
          window.location.reload()
      })
    }
  }

}
</script>

<style scoped>

</style>