<template>

  <vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f w800">
    <div class="modal-header">
      <b>Detail lektora</b>
      <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal__content">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Jméno</label>
            <input type="text" v-model="user.first_name" class="form-control"/>
          </div>

          <div class="form-group">
            <label>příjmení</label>
            <input type="text" v-model="user.last_name" class="form-control"/>
          </div>

          <div class="form-group">
            <label>e-mail</label>
            <input type="text" v-model="user.email" class="form-control"/>
          </div>

          <div class="form-group">
            <label>heslo</label>
            <input type="password" v-model="user.password" class="form-control"/>
            <p class="small "><i>Změní se jen při vyplnění hesla</i></p>
          </div>

        </div>


        <div class="col-md-6">
          <profile-image @imageChange="handleImageChange" :src="user.profile_image_url_big"></profile-image>
        </div>

      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>sazba za lekci 45 minut </label>
            <div class="input-group">
              <input type="text" v-model="user.rate_45" class="form-control"/>
              <div class="input-group-append">
                <span class="input-group-text">CZK</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>sazba za lekci 22,5 minut</label>
            <div class="input-group">
              <input type="text" v-model="user.rate_22" class="form-control"/>
              <div class="input-group-append">
                <span class="input-group-text">CZK</span>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
    <!--
    <div class="row">
        <div class="col-md-12">
            <label class="mr-5"><input type="checkbox" v-model="l1" />Level 1</label>
            <label class="mr-5"><input type="checkbox" v-model="l2" />Level 2</label>
            <label class="mr-5"><input type="checkbox" v-model="l3" />Level 3</label>
        </div>
    </div>
    -->

    <div class="modal__action">
      <button class="btn btn-primary" @click="handleSaveChanges">uložit změny</button>
      <button class="btn btn-outline-dark ml-4" @click="hideModal">zrušit</button>
    </div>
  </vue-final-modal>
</template>

<script>
import modalKkMixin from "./modal-kk-mixin";
import ProfileImage from "./profile-image.vue";
import axios from "axios";

export default {
  components: {ProfileImage},
  mixins: [modalKkMixin],
  name: "modal-lector",
  props: ['user'],
  watch: {
   /* user() {
      this.showM = true
      if (this.user.kk_levels.indexOf('1') >= 0)
        this.l1 = true
      else
        this.l1 = false

      if (this.user.kk_levels.indexOf('2') >= 0)
        this.l2 = true
      else
        this.l2 = false

      if (this.user.kk_levels.indexOf('3') >= 0)
        this.l3 = true
      else
        this.l3 = false

    }*/
  },
  data() {
    return {
      avatar: '',
      l1: false, l2: false, l3: false
    }
  },
  methods: {


    handleSaveChanges() {
      const formData = new FormData()
      if (this.user.id && this.user.id > 0) {
        formData.append('id', this.user.id)
      }

      let levels = []
      if (this.l1) levels.push(1)
      if (this.l2) levels.push(2)
      if (this.l3) levels.push(3)


      formData.append('user[last_name]', this.user.last_name)
      formData.append('user[first_name]', this.user.first_name)
      formData.append('user[email]', this.user.email)
      formData.append('user[kk_levels]', levels.join(','))

      formData.append('user[rate_45]', this.user.rate_45)
      formData.append('user[rate_22]', this.user.rate_22)



      if (this.avatar) {
        formData.append("user[profile_image]", this.avatar, this.avatar.name)
      }
      if (this.user.password) {
        formData.append('user[password]', this.user.password)
      }

      axios.post('/conversation_club/lectors/update', formData).then(response => {
        this.user.profile_image_url = response.data.profile_image
        this.$emit('userUpdated')
        this.hideModal()
      })
    },
    handleImageChange(f) {
      this.avatar = f
    }
  }

}
</script>

<style scoped>

</style>