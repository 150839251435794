<template>
  <input type="hidden" :name="name" :value="selected_student.id" />
  <v-select label="name" :filterable="false" :options="options" @search="onSearch" v-model="selected_student">
    <template #no-options>
      začni psát pro vyhledání studenta
    </template>
    <template #option="option">
      <div class="d-center">
        <b>{{ option.name }} {{ option.surname }}</b>  {{ option.email }}
      </div>
    </template>
    <template #selected-option="option">
      <div class="selected d-center">
          <b>{{ option.name }} {{ option.surname }}</b>  {{ option.email }}
      </div>
    </template>
  </v-select>
</template>

<script>
import { ref } from 'vue';
import _ from 'lodash';
import vSelect from 'vue-select';

export default {
  name: "select-student",
  props: ['name', 'student'],
  components: { vSelect },
  setup(props) {
    const options = ref([]);
    const selected_student = ref(props.student || { name: '', surname: '', email: '' });

    const onSearch = (search, loading) => {
      if (search.length) {
        loading(true);
        searchStudents(loading, search);
      }
    };

    const searchStudents = _.debounce((loading, search) => {
      fetch(`/search/students?term=${search}`)
        .then(res => res.json())
        .then(json => {
          options.value = json;
          loading(false);
        });
    }, 350);

    return {
      options,
      selected_student,
      onSearch
    };
  }
};
</script>

<style scoped>
  .v-select {
    max-width: 95%;
  }
</style>