<script>
import PurchasetypeTable from "./purchasetype-table.vue";
import studentStatsMixin from "./student-stats-mixin";
import axios from "axios";
export default {
  name: "page-payments.vue",
  components: {PurchasetypeTable},
  mounted() {
    this.fetchStats_tab3()
  },
  mixins: [studentStatsMixin],
  data() {
    return {
      tab3_czk:[],
      tab3_eur:[],
    };
  },
  methods:{
        fetchStats_tab3() {
      axios.get("/students_stats/tab3")
          .then(response => {
            this.tab3_czk = response.data.czk;
            this.tab3_eur = response.data.eur;
          })
          .catch(error => {
            console.log(error);
          });
    },



  }
}
</script>

<template>
  <div class="col-md-auto box">

    <div class="row" style="padding-top: 50px;">
      <h1 class="col flex-grow-1 heading">Přehled plateb</h1>
    </div>

    <hr>


    <h4 class="title pad20">Měsíční přehled podle druhu platby v CZK</h4>

    <div class="card card-body">
      <purchasetype-table :stats="tab3_czk"></purchasetype-table>
    </div>

    <h4 class="title pad20">Měsíční přehled podle druhu platby v EUR</h4>

    <div class="card card-body">
      <purchasetype-table :stats="tab3_eur"></purchasetype-table>
    </div>


  </div>
</template>

<style scoped>

.pad20 {
  padding-top: 20px;
}

</style>