<script>
export default {
  name: "FInputCategory",
  props: ['options', 'item'],
  data(){
      return {
       kkk:213123
      }
  },
  methods:{
    setsel(k){
      /*for(let i in this.options.items){
        this.options.items[i].selected=false
      }*/

      if (k.selected == undefined) k.selected=false
      k.selected = !k.selected

      let arr=[]

      for(let i in this.options.items){
        if (this.options.items[i].selected) arr.push( this.options.items[i].value)
      }

      console.log('---> ', arr, arr.join(','))
      this.item[this.options.codename] = arr.join(',')
      this.$emit('change', arr.join(','))
      this.kkk++
    }

  },
  created() {
    for(let i in this.options.items){
      this.options.items[i].selected=false
    }
  },
  mounted() {
    const v = this.item[this.options.codename]
    if (v) {
      for(let i in this.options.items){
        if (v.indexOf(this.options.items[i].value)>=0) this.options.items[i].selected=true
      }
      this.kkk++
    }
  }

}
</script>

<template>

    <div class="form-group">
      <label>{{options.label}} </label>

      <span v-for="(it, k) in options.items" @click="setsel(it)" :key="k*kkk"
            :class="`ff-category-item  color-${it.color} ${it.selected ? 'selected': ''}`">{{it.t}}</span>

    </div>
</template>

<style scoped>

</style>