<script>
import modalKkMixin from "../conversation-club/modal-kk-mixin";
import axios from "axios";
import {toast} from "vue3-toastify";

export default {
  name: "word-edit-modal",
  props:['word'],
  mixins:[modalKkMixin],

  data(){
    return({
      source:'',
      translation:''   ,
      word_alternatives:'',
      alternatives:''
    })
  },
  watch:{
    word(){
      this.source = this.word.source
      this.translation = this.word.translation
      this.word_alternatives = this.word.word_alternatives
      this.alternatives = this.word.alternatives
    }
  },
  methods: {

    saveChanges(){
      const id = this.word.id
      axios.put(`/oj/vocabulary/update_word/${id}`, {
          source: this.source,
          translation: this.translation,
          word_alternatives: this.word_alternatives,
          vwt_alternatives: this.alternatives
      }).then(()=>{
        this.word.source= this.source
        this.word.translation = this.translation
        this.word.word_alternatives=this.word_alternatives
        this.word.alternatives = this.alternatives
        //this.$toasted.show("Změny jsou uloženy", {type: 'success'});
        toast('Změny jsou uloženy', {type: 'success'});
        this.hideModal()
      }).catch((r)=>{
        //this.$toasted.show('chyba: '+ r.response.data.error , {type: 'error'});
        toast('chyba: '+ r.response.data.error , {type: 'error'});
      })

    },
    handleCancel(){
      this.$emit('onCancel')
      this.hideModal()
    }

  }


}
</script>

<template>
  <vue-final-modal
      v-model="showM"
      classes="modal-container"
      content-class="modal-content-f w600"
  >
    <div class="modal-header">
      <b>Vocabulary Word</b>  &nbsp;<span>#{{word.id}}</span>
      <button
          type="button"
          class="close"
          @click.prevent="hideModal"
          aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal__content">

      <div class=" row">
          <div class="form-group col-md-6">
            <label>source</label>
            <input type="text" v-model="source" class="form-control"/>
          </div>


          <div class="form-group col-md-6">
            <label>translation</label>
            <input type="text" v-model="translation"  class="form-control" />
          </div>

      </div>

          <div class=" row">
            <div class="form-group col-md-12">
              <label>alternativy slova</label>
              <input type="text" v-model="word_alternatives"  class="form-control"/>
            </div>


            <div class="form-group col-md-12">
              <label>alternativy prekladu</label>
              <input type="text" v-model="alternatives"  class="form-control"/>
            </div>

          </div>

      <div class="text-right">
        <button class="btn btn-primary" @click.prevent="saveChanges">
          Uložit změny
        </button>
        <button class="btn btn-default" @click.prevent="handleCancel">Zrušit</button>
      </div>

    </div>
  </vue-final-modal>
</template>

<style scoped>

</style>