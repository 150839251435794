<script>

export default{
  name: "cb-gr",

  methods:{
    handleClick(){
      this.checked = !this.checked
      this.$emit('change', this.checked)
    }
  },
  data(){
    return {
      checked: false
    }
  }

}
</script>

<template>
<span class="oj-checkbox" @click.prevent="handleClick">
  <img :src="`/img/cb-ico.png`" v-if="checked" />
</span>
</template>

<style scoped lang="scss">
.oj-checkbox{
    width:25px;
    height:25px;
    background: white;
    border-radius: 3px;
    display: inline-block;
    position:relative;
    top:7px;
    margin-right: 10px;
    cursor: pointer;

    img{
      position: absolute;
      left: 4px;
      top: 6px;
    }
}



</style>