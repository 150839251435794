<script>
import {VueFinalModal} from "vue-final-modal";
import FInputDate from "../fields/FInputDate.vue";

export default {
  name: "FGInputDate",
  props: ['options', 'item'],
  components: {
    VueFinalModal,
  },
  data() {
    return ({
      showM: false
    })
  },
  methods: {
    hideModal() {
      this.showM = false

    }
  }
}
</script>

<template>
  <div>
    <div class="form-group">
      <label>Label</label>
      <input type="text" class="form-control" v-model="options.label"/>


    </div>

    <div class="form-group">
      <label><input type="checkbox" v-model="options.enable_end_date"/> Datum konce (budou to 2 datumy)</label>
    </div>

    <div class="form-group">
      <label><input type="checkbox" v-model="options.use_time"/> Pridat i cas</label>
    </div>


  </div>
</template>
