<script>
export default {
  name: "FillUp",
  props: ['lesson'],
  data() {
    return {
      d: this.lesson.exercise.data_object,
      content:'',
      vgrid: [],
      n_rows: parseInt(this.lesson.exercise.data_object.rows),
      n_cols: parseInt(this.lesson.exercise.data_object.cols),
      out: {}
    }
  },
  watch: {
    lesson() {
      this.d = this.lesson.exercise.data_object
      this.makeContent()

    },
  },
  mounted() {
    this.makeContent()
  },
  methods:{

    makeContent(){

      for (let i in this.d.fillups){
        let fillup = this.d.fillups[i]
        let content = ''

        for(let j in fillup.tokens){
          let token = fillup.tokens[j]
          if (token.type == 'text'){
            content += token.content+" "
          }else if (token.type == 'fillup'){

            if (typeof token.content === 'string') {
              content += '<b>' + token.content + '</b> ';
            } else if (Array.isArray(token.content)) {
              content += '<b>' + token.content.join('_') + '</b> ';
            }
          }
        }
        fillup.html = content
      }
    },

    reverseFillUp() {
      const fillups = {};
      let tokenid = 1;
      for(let i in this.d.fillups){
        let f = this.d.fillups[i]
        const fillupContent = document.getElementById(`fillupContent_${f.id}`);

let currentFillup = { id: f.id, tokens: [] };

fillupContent.childNodes.forEach(node => {
  if (node.nodeType === Node.TEXT_NODE) {
    currentFillup.tokens.push({ id: tokenid, type: 'text', content: node.textContent });
  } else if (node.nodeType === Node.ELEMENT_NODE) {
    if (node.tagName === 'B') {
      const content = node.textContent.includes('_') ? node.textContent.split('_') : [node.textContent];
      currentFillup.tokens.push({ type: 'fillup', content: content, id: tokenid });
    } else if (node.tagName === 'BR') {
      currentFillup.tokens.push({ id: tokenid, type: 'text', content: '<br>' });
    }
  }
  tokenid++;
});
        fillups[f.id] = currentFillup;
      }

      this.out = { fillups: fillups };
    },


    markFillUp() {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const boldElement = document.createElement('b');
        range.surroundContents(boldElement);
      }
      return false;
    },

    unmarkFillUp() {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const contents = range.cloneContents();
        const boldElements = contents.querySelectorAll('b');

        boldElements.forEach(boldElement => {
          const parent = boldElement.parentNode;
          while (boldElement.firstChild) {
            parent.insertBefore(boldElement.firstChild, boldElement);
          }
          parent.removeChild(boldElement);
        });

        range.deleteContents();
        range.insertNode(contents);
      }
      return false;
    }
/*
// fix vložení do textu, odstranení přebytečného formátování
$(".fillupContent").on("paste", function(event){

  var fillup = $(this);

  setTimeout(function() {
    fillup.html((fillup).html().replace(/<(?!\s*\/?(br|b|strong)\b)[^>]+>/ig,''));

  }, 1);

});

"d": {
      "type": "fillup",
      "fillups": {
        "1": {
          "id": 1,
          "tokens": [
            {
              "id": 1,
              "type": "text",
              "content": "1. You  "
            },
            {
              "id": 2,
              "type": "fillup",
              "content": [
                "have"
              ]
            },
            {
              "id": 3,
              "type": "text",
              "content": "    "
            },
            {
              "id": 4,
              "type": "fillup",
              "content": [
                "got"
              ]
            },
            {
              "id": 5,
              "type": "text",
              "content": "             a very nice job!    <br>2. She  "
            },
            {
              "id": 6,
              "type": "fillup",
              "content": [
                "has"
              ]
            },
            {
              "id": 7,
              "type": "text",
              "content": "    "
            },
            {
              "id": 8,
              "type": "fillup",
              "content": [
                "got"
              ]
            },
            {
              "id": 9,
              "type": "text",
              "content": "             a good qualification.  <br>  3. I  "
            },
    */

    ,removeBlok(blok){
      this.d.fillups = this.d.fillups.filter((item) => item !== blok)
    },
    addBlok(){
      let id= this.d.fillups.length + 1
      this.d.fillups[`${id}`] = {
          size:1,
          tokens:[],
          id: id,
          wrong: [""]
      }
    }

  }
}
</script>

<template>

  <div class="control-group"><label for="type" class="control-label required">Typ cvičení</label>
    <div class="controls">
      <select name="type" id="type">
        <option value="fillup" label="Vyplnění bez možností" selected="selected">Vyplnění bez možností</option>
        <option value="dragdrop" label="Vyplnění pomocí drag &amp; drop">Vyplnění pomocí drag &amp; drop</option>
      </select>
      <p class="help-block" style="color: #999;">Touto volbou můžete ovlivnit, jakým způsobem bude student vyplňovat vybrané mezery v textu.</p>
    </div>
  </div>


  <p>
    <a @click.prevent="addBlok"  id="addFillup" href="#" class="btn btn-success"><i class="icon-plus-sign icon-white"></i> Přidat blok</a>
  </p>


  <div id="exerciseFillups" class="clearfix">

    <div :id="`fillupItem_${i}`" class="fillupItem" v-for="(blok, i) in d.fillups">
      <div class="control-group">
        <label class="control-label required" for="fillupSize">Velikost polí pro vyplnění možností</label>
        <div class="controls">
          <select id="fillupSize"  v-model="blok.size">
            <option label="Úzké pole" value="4">Pole pro jedno písmeno až slabiku</option>
            <option label="Úzké pole" value="1">Úzké pole</option>
            <option label="Normální pole" value="2" selected="selected">Normální pole</option>
            <option label="Široké pole" value="3">Široké pole</option>
          </select>
          <p style="color: #999;" class="help-block">Touto volbou můžete ovlivnit, jak široká budou formulářová pole pro vyplnění možností.</p>
        </div>
      </div>


        <div style="margin-bottom: 9px" class="btn-toolbar">
          <div class="btn-group">
            <a href="#" title="Označit výběr k doplnění" class="markFillUp btn" data-fillupid="1" @click.prevent="markFillUp">Označit výběr<i class="icon-plus-sign"></i></a>
            <a href="#" title="Zrušit označení výběru" class="unmarkFillUp btn" data-fillupid="1" @click.prevent="unmarkFillUp">Zrušit označení<i class="icon-remove-sign"></i></a>
          </div>
        </div>

      <div class="btn-toolbar fillupToolbar">
        <div class="btn-group">
          <a href="#" data-fillupid="1" class="btn btn-danger" @click.prevent="removeBlok(blok)"><i class="icon-trash icon-white"></i> Odebrat tento blok</a>
        </div>
      </div>


        <div class="row">
          <div class="col-md-8">
            <div :id="`fillupContent_${blok.id}`" data-fillupid="1" class="fillupContent " contenteditable="true" v-html="blok.html"></div>
          </div>
          <div class="col-md-4 pl-3">
            <label for="fillupWrong_1">Špatné možnosti:</label>
            <textarea v-model="blok.wrong"
                      class="fillupWrong form-control"
                      rows="10"
            ></textarea>
          </div>
        </div>
    </div>


  </div>

  <button @click.prevent="reverseFillUp">generovat</button>
  <pre>
    OUT: {{out}}
    -----
    in: {{d}}
  </pre>

</template>

<style >
.fillupItem {

    position: relative;
    background: #F5F5F5;
    padding: 5px;

  select {
  width: 220px;

  background-color: #fff;

  border: 1px solid #ccc;

}

}
.fillupItem .fillupContent {
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 3px 3px 3px 3px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  padding: 8px;
  margin-bottom: 10px;
  min-height: 140px;
  font-size: 15px;
  line-height: 22px;
}
.fillupItem .fillupContent span, .fillupItem .fillupContent b, .fillupItem .fillupContent strong {
  color: #FFFFFF;
  font-size: 14px;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  vertical-align: baseline;
  white-space: nowrap;
  border-radius: 3px;
  padding: 3px 4px 3px 4px;
  background-color: #80C005;
  text-shadow: 1px 1px 1px #666666;
}

.fillupContent b{
  background: #5aacff;
}
.fillupToolbar {

    position: absolute;
    top: 5px;
    right: 5px;
    margin: 0;

}

</style>