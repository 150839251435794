<script >

import axios from "axios";
import AppTable from "./form-generator/AppTable.vue";
import DialogSendPushnotifikace from "./notifications/dialog-send-pushnotifikace.vue";
export default {
  name: "DevicesTokens",
  components:{ AppTable, DialogSendPushnotifikace },
  props:[],

  data(){
    return ({
      current_token:{},
      fields:[

        {"label":"Student",
          "type":"text",
          "codename":"email"},

        {"label":"Token",
          "type":"user",
          "codename":"token"
        },
        {
          "label":"System",
          "type":"",
          "codename":"kind"
        },
        {"label":"Vytvorene",
          "type":"datetime",
          "display_filter":'dateTime',
          "codename":"created_at"
        }

      ]

      ,
      app:{
        data_url: '/devices',
        destroy_url: '/devices/:id',
        actions: "delete"
      }
    })
  },

  methods:{

    extravurst(item){
      console.log('kokote|x', item)
      this.current_token = item
      this.$refs.dialogPosilani.showModal()
    }

  }
}
</script>

<template>
<div class="card card-body index p-0">
  <DialogSendPushnotifikace :token="current_token" ref="dialogPosilani"/>

  <AppTable :app="app" :fields="fields" :enable_search="true"
            :filters="[{type: 'search'},
                        { type:'select',
                          label: 'system',
                          codename: 'kind',
                          options:['All','apple', 'android']
                        }]"
            :actions="[
            {
            label:'poslat',
            header: 'akce',
              method: extravurst
            }
            ]"

  ></AppTable>
</div>
</template>

<style scoped>

</style>