<template>

  <div>
    <v-select label="name"
              :multiple="multiple"
              :filterable="true"
              :options="products"
              v-model="selected_course"
              placeholder="Add Product"
              @update:modelValue="onValueChange"
              @input="onProductSelect">

      <template #option="option">
        <div class="d-flex">
          <div style="min-width: 300px">{{ option.name }}</div>
        </div>
      </template>
      <template #selected-option="option">
        <div class="selected d-center">
          {{ option.name }}
        </div>
      </template>
    </v-select>
    <input type="hidden" :name="name" :value="selected_course.id" />
  </div>
</template>

<script>
import axios from "axios";
import vSelect from 'vue-select'

export default {
  props:['courseId', 'name','course' , 'multiple'],
  name: "select-course",
  components: {vSelect},
  data(){
    return {selected_course: this.multiple ? [] : {}, products:[]}
  },
  methods:{
    onValueChange(a){
      console.log('on value change ', a, this.selected_course)
      this.$emit('couseSelect', this.selected_course)
    },
    onProductSelect(a){
      console.log('on product select ', this.selected_course, a)

      this.$emit('couseSelect', this.selected_course)

    },
    loadCourses(){

      const _this= this

      axios.get('/api/courses').then( response =>{
          this.products = response.data.courses
          let cid = _this.courseId

        console.log('setovat kurz', cid, this.course)


          if ( this.course && this.course.id && !cid) {
            this.selected_course = this.course
          }




          for(let i in this.products){
            if (this.products[i].id == cid){
              this.selected_course = this.products[i]
            }
          }


      })
    }
  },
  mounted() {

    console.log('no teda ', this.courseId)

    this.loadCourses()



  }
}
</script>

<style scoped>

</style>