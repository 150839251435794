<script>
import AppTable from "../form-generator/AppTable.vue";
import axios from "axios";
import {VueFinalModal} from "vue-final-modal";

import Prism from "prismjs";
import "prismjs/themes/prism-tomorrow.css"; // import the theme you want
import "prismjs/components/prism-json"; // import the language you want


export default {
  name: "api-log-konverzky",
  components: {AppTable, VueFinalModal},
  data() {
    return ({
      fields: [
        {
          "label": "Objednavka ID",
          "type": "text",
          "codename": "order_id"
        },{
          "label": "Student",
          "type": "text",
          "codename": "email"
        },
        {
          label: 'Projekt',
          codename: 'project_name',
        },

        {
          codename: "webhook_type",
          label: "Typ webhooku",
        },
        {
          "label": "Vytvořeno",
          "type": "dateTime",
          display_filter:'dateTime',
          "codename": "created_at"
        }

      ],
      filters: [
        {
          "label": "Vytvořeno",
          "type": "date-range",
          "codename": "created_at"
        }, {
          type: 'search'
        }
      ],
      app: {
        data_url: '/api_log/konverzky.json',
        actions: ""
      },

      showModal: false,
      item: {},
      itemContent:{},
      code:''
    })
  },
  methods: {
    handleItemClick(item) {
      console.log('item', item)
      axios.get('/api_log/konverzky/' + item.id + '.json').then((response) => {
        console.log('response', response)
        this.showModal = true

        this.item = response.data
        this.itemContent = JSON.parse(this.item.content)
        this.code= JSON.stringify(this.itemContent, null, 2);
      })
    }
  },
  computed: {
    highlightedCode() {
      return Prism.highlight(this.code, Prism.languages.json, 'json');
    }
  }

}
</script>

<template>
  <div>
    <h1>Příchozí data z api konverzky</h1>
    <div class="card card-body index p-0">
      <AppTable :app="app" :fields="fields" :filters="filters" @itemClick="handleItemClick"></AppTable>
    </div>


    <vue-final-modal v-model="showModal" classes="modal-container" content-class="modal-content-f mcw1">
      <div class="modal-header">
        <span class="modal__title fg-modal-title">Data {{item.id}}</span>
        <button type="button" class="close" @click.prevent="showModal=false" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal__content" >
        <pre v-html="highlightedCode" class="tmave"></pre>
      </div>

    </vue-final-modal>

  </div>
</template>

<style scoped>
.tmave{
  background: #2E2F30;
  padding: 10px;
}
</style>