<template>
<div>

  <div class="row">

      <div class="input-group ml-auto col-md-4">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <svg class="svg-inline--fa fa-search fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
              <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg><!-- <i class="fas fa-search"></i> -->
          </div>
        </div>
        <input placeholder="Hledat" type="text" class="form-control" @keyup="searchText" v-model="search_text">
      </div>
  </div>
  <div class="row mt-2 ml-1 mr-1" style="background-color: #eee">
    <div class="col-md-5">Jméno a příjmení</div>
    <div class="col-md-5">email</div>
    <div class="col-md-2 text-right">kurzů</div>
  </div>
  <div style="min-height: 400px">


    <template v-for="group in groups" :key="`gr_${group.id}`">
    <div  v-if="studentsFromGroup(group.id).length>0">
      <h4 class="d-flex">
        <div><a :href="`/organizations/${organization.id}/groups/${group.id}`">{{group.name}}</a></div>
        <div class="ml-auto">{{group.students_count}} studentů</div>
      </h4>
      <table class="table table-sm " :key="`gr_table_${group.id}`">
        <tbody>
          <tr v-for="s in studentsFromGroup(group.id)">
            <td><a :href="`/organizations/${organization.id}/students/${s.id}`">{{s.fullname}}</a></td>
            <td><a :href="`/organizations/${organization.id}/students/${s.id}`">{{s.email}}</a></td>
            <td>{{s.courses_no_trial_count}}</td>
            <td>
              <span v-if="s.ac_profile_dirty" class="small badge-info badge" title="bude se synchronizovat do AC">AC</span>
              <span v-if="s['with_new_event?']" class="small badge-warning badge" title=" new event, bude se synchronizovat do AC">N</span>
              <span v-if="s.should_send_activation" class="small badge-warning badge" title="bude se posilat aktivace">A</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div></template>

  </div>

</div> ...
</template>

<script>
import axios from "axios";
import _ from "lodash";

export default {
  name: "organization-students",
  props:['organization'],

  data(){
    return({
      students:[],
      groups:[],
      loading:false,
      search_text:''
    })
  },

 mounted() {
    console.log('organization-students mounted')
    const csfr_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csfr_token;
    axios.defaults.withCredentials=true

   this.loadStudents()
  },

  methods:{

    searchText(){

        this.search(this.search_text, this);

    },

    search: _.debounce(( search, vm) => {
      console.log('search', search)
    }, 350),

    studentsFromGroup(group_id){
      let q = this.search_text.toLowerCase()
      var arr =[]
      for (let i in this.students){
          for(var g in this.students[i].groups){
            if (this.students[i].groups[g].id == group_id){
              let st = this.students[i]

              if (this.search_text=='' || st.fullname.toLowerCase().indexOf(q)>=0 || st.email.toLowerCase().indexOf(q)>=0)
                arr.push(st)


            }
          }
      }
      return arr
    },
    loadStudents(){

      axios.get(`/organizations/${this.organization.id}/students.json`).then(response => {
        this.groups = response.data.groups
        this.students = response.data.students

      })

    }
  }
}
</script>

<style scoped lang="scss">
 h4{
   margin-top:1em;
   background-color: #0086de;
   color:white;
   font-size:16px;
   padding: 5px 15px;
   a{
     color:white;
   }
 }
</style>