<script>
export default {
  name: "product-table-default",
  props: {
    items: Array,
    cart: Object,
  },
  data() {
    return {
      discount: false
    }
  },
  methods:{
    zaokrouhleni(c){
      return Math.round(c)
    },
    perioda(m){
      if (m==1) return 'měsíčně'
      if (m==3) return 'čtvrtletně'
      if (m==6) return 'půlročně'
      if (m==12) return 'ročně'
    },
    changeProduct(it){
      this.$emit('changeProduct', it)
    },
    hasSubscription(){
      for(let i in this.items){
        if (this.items[i].months>1){
          return true
        }
      }
      return false
    }
  },

  watch: {
    items: {
      handler: function (val) {
        for(let i in this.items){
          this.items[i].selected_variant = this.items[i].product.id
          let it = this.items[i]
          if (it.discounted_price!=it.price_with_tax){
            this.discount = true
          }
        }

        this.cart.get_total_price = parseFloat(this.cart.get_total_price)
        this.cart.get_discounted_price = parseFloat(this.cart.get_discounted_price)
        //console.log('ceny,',this.cart.get_total_price , this.cart.get_discounted_price )
      },
      deep: true
    }
  },
}
</script>

<template>
  <table class="item table">
    <thead>
    <tr>
      <th>Produkt</th>
      <th>Typ platby</th>
      <th id="right">Částka</th>
      <th v-if="hasSubscription()">Období</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="it in items">
      <td>{{ it.product.name }}</td>
      <td>{{ it.months > 0 ? 'Předplatné' : 'Jednorázová' }}</td>
      <td id="right">{{ Math.trunc(it.price_with_tax) }} Kč</td>
      <td v-if="hasSubscription()">{{perioda(it.months)}}</td>
    </tr>
    <tr v-if="discount">
      <td> </td>
      <td> Sleva </td>
      <td id="right">- {{ zaokrouhleni(cart.get_total_price - cart.get_discounted_price) }} Kč </td>
      <td v-if="hasSubscription()"> </td>
    </tr>
    </tbody>
    <tfoot v-if="discount">
    <tr id="discount-price">
      <td> </td>
      <td> <b>Cena po slevě</b> </td>
      <td id="right"> <b>{{ zaokrouhleni(cart.get_discounted_price) }} Kč </b> </td>
      <td v-if="hasSubscription()"></td>
    </tr>
    </tfoot>
  </table>

</template>

<style scoped>

#right {
  text-align: right;
}

</style>