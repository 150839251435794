<script>
import axios from "axios";
import moment from "moment";
import studentStatsMixin from "./student-stats-mixin";
import TrendTable from "./trend-table.vue";
import PurchasetypeTable from "./purchasetype-table.vue";

import SubscriptionStats from "./subscription-stats.vue";


export default {
  name: "students-stats",
  components: {SubscriptionStats, PurchasetypeTable, TrendTable},
  mixins: [studentStatsMixin],
  data() {
    return {
      stats: {
        months: [],
        students: []
      },
      tab3_czk: [],
      tab3_eur: [],
      top_selling_czk: [],
      top_selling_eur: [],
      subs: [],
      top_courses: [],
    };
  },
  created() {
    this.fetchStats();
  },
  methods: {
    fetchStats() {
      axios.get("/students_stats/tab1")
          .then(response => {
            this.stats = response.data;
          })
          .catch(error => {
            console.log(error);
          });
    },


    // students_stats/top_courses
    fetchTopCourses() {
      axios.get("/students_stats/top_courses")
          .then(response => {
            this.top_courses = response.data;
          })
          .catch(error => {
            console.log(error);
          });
    },
    row_sum(students){
          let sum = 0;
            for (let i = 0; i < students.length; i++) {

                    sum += students[i].student_count;

            }
            return sum;
    }
  }

}
</script>

<template>
  <div>
    <div class="col-md-auto box">
      <div class="row" style="padding-top: 50px;">
        <h1 class="col flex-grow-1 heading">Nově zaregistrovaní studenti</h1>
      </div>

      <hr>

      <div class="card card-body">

        <table class="table table-bordered">
          <thead>
          <tr>
            <td colspan="2"></td>
            <th>Celkem</th>
            <th v-for="s in sources">
              {{ s.label }}
            </th>
          </tr>

          </thead>
          <template v-for="m in getShortenedMonths()">
            <tr>
              <td rowspan="4
">
                {{ mesic_z_datumu(m.month) }}
              </td>
              <th>Celkem</th>
              <td>{{ allKindAllLevelSum(m.students) }}</td>
              <td v-for="s in sources">{{ levelSum(m.students, s.name) }}</td>
            </tr>
            <tr>
              <td>registrace</td>
              <td>{{ allKindSum(m.students, 1) }}</td>
              <td v-for="s in sources">{{ findValue(m.students, s.name, 1) }}</td>

            </tr>
            <tr>
              <td>trial</td>
              <td>{{ allKindSum(m.students, 2) }}</td>
              <td v-for="s in sources">{{ findValue(m.students, s.name, 2) }}</td>
            </tr>
            <tr>
              <td>produkt</td><td>{{ allKindSum(m.students, 3) }}</td>
              <td v-for="s in sources">{{ findValue(m.students, s.name, 3) }}</td>
            </tr>
          </template>

        </table>
      </div>
    </div>


    <div class="row" style="padding-top: 50px;">
      <h1 class="col flex-grow-1 heading">Měsíční trendy pro jednotlivé skupiny</h1>
    </div>

    <hr>

    <h4 class="title pad20">Jen zaregistrovaní</h4>

    <div class="card card-body">
      <trend-table :stats="stats" :level="1"></trend-table>
    </div>

    <h4 class="title pad20">Jen aktivovali trial</h4>

    <div class="card card-body">
      <trend-table :stats="stats" :level="2"></trend-table>
    </div>

    <h4 class="title pad20">Koupili produkt</h4>

    <div class="card card-body">
      <trend-table :stats="stats" :level="3"></trend-table>
    </div>


  </div>
</template>

<style scoped>

.pad20 {
  padding-top: 20px;
}

</style>