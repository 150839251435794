<template>
  <div class="alldays small-cc-calendar">


    <div class="hour-axis">
      <div class="day-header"></div>
      <div v-for="h in 14" class="hour-row">
        <span class="hour-name">{{h + 7}}:00</span>
      </div>
    </div>

    <div class="week-day " v-for="d in wdays">
      <div class="day-header">{{d.format('dddd  DD')}}</div>
      <div v-for="h in 14" :class="`hour-row hour-box hour-box-h${h}`">


      </div>
    </div>


  </div>

</template>

<script>
export default {
  name: "mini-calendar",
  data(){
    return ({
      wdays:[ moment() ]
    })
  }
}
</script>

<style scoped>

</style>