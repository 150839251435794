<script>
import readXlsxFile from "read-excel-file";
import axios from "axios";
import moment from "moment/moment";
import {VueFinalModal} from "vue-final-modal";
import modalKkMixin from "./modal-kk-mixin";
import {toast} from "vue3-toastify";

export default {
  name: "modal-student-import" ,
  components: {VueFinalModal },
  props:['student'],
  mixins:[modalKkMixin],
  data(){
    return({
      rows:[],
      langs:[],
      organizations:[]
    })
  },

  mounted() {
    this.loadOrganizations()
    this.loadLanguages()

  },

  methods:{

    fileChanged(){

      const input = document.getElementById('file_input_import_xlsx')
      // input.addEventListener('change', () => {
      this.items=[]

      readXlsxFile(input.files[0]).then((rows) => {
        this.rows = []
        for( let i in rows){

          if (rows[i][0]=='Email' || rows[i][1]=='Jméno') continue

          const exp = moment(rows[i][7])
          this.rows.push({
            email: rows[i][0],
            name: rows[i][1],
            surname: rows[i][2],
            nickname: rows[i][3],
            firma: rows[i][4],

            password_helper: rows[i][5], //Požadované heslo
            change_password_helper: (rows[i][6].trim().toLowerCase() == 'ano'), // Vynutit změnu hesla ano/ne
            cc_until: exp.format("YYYY-MM-DD"),//Datum expirace KK
            com_language: rows[i][8],//Komunikační jazyk
            language_id: this.detectLanguage(rows[i][8]),
            organization_helper: this.detectOrganization(rows[i][4])

          })
        }
      }).catch(ex =>{
        console.error( ex)

        toast(ex.toString(), {type: 'error'});
      })
    },


    loadLanguages(){
      axios.get('/languages.json',{}).then(response =>{
        this.langs = response.data.languages
      })
    },

    detectLanguage(s){
      if (!s || s=='') return null
      let ss = s.trim().toLowerCase()
        for(let i in this.langs){
          const l = this.langs[i]
          if (l.name.trim().toLowerCase() == ss) return l.id
          if (l.code.trim().toLowerCase() == ss) return l.id
        }
    },

    loadOrganizations(){
      axios.get('/conversation_club/organizations.json',{}).then(response =>{
        this.organizations = response.data.organizations
      })
    },
    detectOrganization(o_name){
      if (!o_name) return null

      const org = o_name.trim().toLowerCase()
      for( let i in this.organizations){
        const o = this.organizations[i].name.trim().toLowerCase()
        if (org == o) return this.organizations[i].id
      }
    },

    runImport() {
      axios.post("/conversation_club/students/batch-import", {rows: this.rows}).then(response =>{

        const u = response.data.updated
        const c = response.data.created
        const er = response.data.err
        if (u>0 || c>0) {
          toast(`Import dokončen, aktualizováno ${u}, vytvořeno ${c} studentů.`, {type: 'success'});
        }
        if (er>0){
          toast(`U ${er} studentů se vyskytla chyba.`, {type: 'error'});
        }

        this.$emit('onImported')
        this.hideModal()

      })
    },



  }
}
</script>

<template>
<vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f  w90" >
    <div class="modal-header">
      <b>Import KK študentov</b>
        <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>

    </div>

    <div class="modal__content">
           <div class="row">

          <div class="col-md-4">
              soubor xlsx: <input type="file" id="file_input_import_xlsx" @change="fileChanged"  class="form-control"/>
          </div>
      </div>

      <table class="table table-stripped mt-2" v-if="rows.length>0">
        <thead>
          <tr>
            <th>Email</th>
            <th>Jméno</th>
            <th>Příjmení</th>
            <th>Přezdívka</th>
            <th>Firma</th>
            <th>Požadované heslo</th>
            <th>Vynutit změnu hesla</th>
            <th>Datum expirace KK</th>
            <th>Komunikační jazyk</th>
          </tr>
        </thead>
        <tr v-for="r in rows">
          <td><input  class="form-control" v-model="r.email" /></td>
          <td><input  class="form-control" v-model="r.name" /></td>
          <td><input  class="form-control" v-model="r.surname" /></td>
          <td><input  class="form-control" v-model="r.nickname" /></td>
          <td><select v-model="r.organization_helper" class="form-control">
            <option v-for="o in organizations" :value="o.id">{{o.name}}</option>
          </select></td>
          <td><input type="text"  class="form-control"v-model="r.password_helper" /></td>
          <th><input type="checkbox" v-model="r.change_password_helper" /></th>
          <th><input  class="form-control" v-model="r.cc_until" type="date" /></th>
          <th><select class="form-control" v-model="r.language_id">
            <option v-for="l in langs" :value="l.id">{{l.name}}</option>
          </select></th>
        </tr>
      </table>

      <div class="d-flex" v-if="rows.length>0">
        <div class="ml-auto">
          <button class="btn btn-success" @click.prevent="runImport">Potvrdit import</button>
        </div>
      </div>

    </div>
</vue-final-modal>
</template>

<style scoped>

</style>