<script>

import {VueFinalModal} from "vue-final-modal";
import FUsers from "../fields/FUsers.vue";
export default {
  name: "FGUsers",
  props: ['options'] ,
  components: {
    VueFinalModal, FUsers
  },
  data(){
    return({
      showM: false
    })
  },
  methods:{
    hideModal(){
      this.showM=false

    }
  }
}

</script>

<template>
<div>
  <div class="form-group">
      <input type="text" class="form-control" v-model="options.label"/>
  </div>
  <div class="form-group">
    <label><input type="checkbox" v-model="options.multiple"/> Multiple</label>
  </div>
  <div class="form-group">
    <label class="cb"><input type="checkbox" name="required" v-model="options.required" /> Required field </label>
  </div>


</div>
</template>

<style scoped>

</style>