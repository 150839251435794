<template>
<div class="text-right">
  <button @click.prevent="showDialog">OJ kurzy</button>


  <vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f w600">

      <div class="modbod text-left">

        <h3>Kontrola kurzu v OJ</h3>
        <table class="table table-striped">
          <tr v-for="c in ojc">
            <td>{{ c.productName }}</td>
            <td>{{ c.expireDate }}</td>
            <td>{{ c.isTrial ? "Trial" : '' }}</td>
          </tr>
        </table>
        <div>
          <button class="btn-outline-dark" @click.prevent="runSync"> synchronizaovat do OJ</button>
        </div>


      </div>
  </vue-final-modal>



</div>
</template>

<script>
import {VueFinalModal} from "vue-final-modal";
import axios from "axios";

export default {
  name: "ojsyncshow",
  props:['student'],

  data(){
    return ({
      ojc:[],
      showM:false
    })
  },

  components: {VueFinalModal},
  methods:{

    showDialog(){
      this.showM = true


      axios.get(`/students/${this.student.id}/oj-courses`).then(response => {
        this.ojc = response.data.courses
      })

    },

    runSync(){
      axios.get(`/students/${this.student.id}/oj-courses-sync`).then(response => {
         this.showDialog()
      })
    }



  }
}
</script>

<style scoped>
.modbod{
  text-align: left;
  height:450px;
  overflow-y: scroll;
  margin:20px;

}
</style>