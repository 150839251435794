<script>

import axios from "axios";
import OjPagination from "../OjPagination.vue";
import ModalTemplates from "./modal-templates.vue";

import translatorMixin from "./translator-mixin";

import {VueFinalModal} from "vue-final-modal";

export default {
  name: "explanations",
  props:['course', 'status'],
  mixins:[translatorMixin],
  components:{ModalTemplates, OjPagination, VueFinalModal },
  data(){
    return{

      show_translation_modal:false,
      items:[],
      lb_ids:[],
      all_ids:[],
      translator_evnt_id: null,
      active_template:{},
      current_item:{}

    }
  },
  mounted() {
    this.load_explanations()
  },

  watch:{
    course(){
      this.load_explanations()
    },
    per(){
       this.load_explanations()
    },
    status(){
      this.page=1
      this.load_explanations()
    },
  },

  methods:{
    loadItems() {
      this.load_explanations()
    },




    load_explanations() {

      axios.get('/paja/courses_translator/explanation', {
        params:
            {
              status: this.status,
              page: this.page,
              search: this.search,
              course_id: this.course,
              per: this.per
            }
      }).then(response => {
        this.items = response.data.items
        this.total = response.data.total
        this.all_ids = response.data.all_ids

        let ids = []
        for(let i in this.items){
          ids.push(this.items[i].lb_id)
        }
        this.lb_ids = ids

      })
    },


    runTranslation_zob() {
      this.runTranslation( this.lb_ids, 'explanations')
    },
    runTranslation_all() {
      this.runTranslation( this.all_ids, 'explanations')
    },

    pajaTranslationChange(item, paja_col){
      let id =''
      const content = item[paja_col]
      if (paja_col == 'paja_lesson') id = item.paja2_id
      if (paja_col == 'paja_text') id = item.paja1_id

      if (id && content) {
        axios.put(`/paja/courses_translator/translation/${id}`, {translation: content})
      }else{
        alert('neni obsah')
      }
    },

    hideModal(){
      this.show_translation_modal=false
    },
  }

}
</script>

<template>
  <div>
    <modal-templates ref="aiTemplatesModal" v-on:detectTemplate="detectTpl" :kind="'courses_explanations'"></modal-templates>

    <div class="row mt-2">
      <div class="col-sm-4">
        <a @click.prevent="handleTplModal" href="#">nastavení AI dotazu</a>
        šablona dotazu: <b>{{active_template.name}}</b>
      </div>
      <div class="col-sm-5">
          <button @click.prevent="runTranslation_zob" class="btn btn-sm btn-info">prelozit zobrazene ({{lb_ids.length}})</button>
          <button @click.prevent="runTranslation_all"  class="btn btn-sm btn-info">prelozit vybrane ({{all_ids.length}})</button>
      </div>
    </div>

    <div class="d-flex" v-if="items.length>0">
      <div class=" mr-3">
        <select v-model="per" class="form-control w-auto mt-2">
          <option>10</option>
          <option>20</option>
          <option>30</option>
          <option>40</option>
          <option>50</option>
        </select>
      </div>
        <OjPagination
          :totalItems="total"
          :currentPage="page"
          :perPage="per" @pageChanged="pageChanged" class="mt-2"/>
    </div>

  <table class="table table-bordered">
    <thead>
    <tr>
      <th>lessob block ID</th>
      <th >Lekce</th>
      <th>chapter</th>
      <th >perex</th>

      <th >text</th>

    </tr>
    </thead>
    <tr v-for="item in  items">
      <td>{{item.lb_id}}</td>
      <td>{{item.lesson}}

      </td>
      <td>{{item.chapter}}</td>
      <td>   {{item.perex}}
      </td>
      <td>{{item.text}}</td>

      <td><a v-if="item.paja_text || item.paja_lesson" href="#"
             @click.prevent="show_translation_modal=true; current_item = item">Překlad</a></td>
    </tr>
  </table>





    <vue-final-modal v-model="show_translation_modal"  classes="modal-container"  content-class="modal-content-f w900">
      <div class="modal-header">
        <b>Šablony AI dotazů</b>
        <button
            type="button"
            class="close"
            @click.prevent="hideModal"
            aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal__content">
        <div class="row">
          <div class="col-md-12 text-center">lesson</div>
          <div class="col-md-6">{{current_item.lesson}}</div>
          <div class="col-md-6"><input class="form-control" type="text" v-model="current_item.paja_lesson"
                                       @change="pajaTranslationChange(current_item, 'paja_lesson')" /></div>
        </div>



        <div class="row">
          <div class="col-md-12 text-center">perex</div>
          <div class="col-md-6">
            <textarea readonly class="form-control" rows="8">{{current_item.perex}}</textarea>
          </div>
          <div class="col-md-6"><textarea  rows="8" class="transtlation form-control"
                v-model="current_item.paja_perex"
                @change.prevent="pajaTranslationChange(current_item, 'paja_perex')"></textarea>
          </div>
        </div>



        <div class="row">
          <div class="col-md-12 text-center">text</div>
          <div class="col-md-6">
            <textarea readonly class="form-control"  rows="8">{{current_item.text}}</textarea>
          </div>
          <div class="col-md-6">
            <textarea class="transtlation form-control"  rows="8"
                  v-model="current_item.paja_text"
                  @change.prevent="pajaTranslationChange(current_item, 'paja_text')"></textarea>
          </div>
        </div>

      </div>


    </vue-final-modal>


  </div>
</template>

<style scoped>
.transtlation{
  background: #eeffee;
  padding: 5px ; width:100%
}
textarea.translation{ min-height:200px;}
</style>