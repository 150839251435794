<script>
export default {
  name: "product-table-kk",
  props: {
    items: Array,
    cart: Object,
  },
  data() {
    return {
      discount: false
    }
  },
  methods:{
    zaokrouhleni(c){
      return Math.round(c)
    },
    perioda(m){
      if (m==1) return 'měsíčně'
      if (m==3) return 'čtvrtletně'
      if (m==6) return 'půlročně'
      if (m==12) return 'ročně'
    },


    changeProduct(it){
      this.$emit('changeProduct', it)
    },
  },

  watch: {
    items: {
      handler: function (val) {
        for(let i in this.items){
          this.items[i].selected_variant = this.items[i].product.id
          let it = this.items[i]
          if (it.discounted_price!=it.price_with_tax){
            this.discount = true
          }
        }

        this.cart.get_total_price = parseFloat(this.cart.get_total_price)
        this.cart.get_discounted_price = parseFloat(this.cart.get_discounted_price)
        console.log('ceny,',this.cart.get_total_price , this.cart.get_discounted_price )
      },
      deep: true
    }
  },
}
</script>

<template>
  <table class="item table">
    <thead>
    <tr>
      <th>Produkt</th>
      <th>Počet lekcí za týden</th>
      <th>Předplatné</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="it in items">
      <td>{{ it.product.name }}</td>
      <td>
        <select v-if="it.product_variants" @change="changeProduct(it)" v-model="it.selected_variant">
          <option  v-for="p in it.product_variants" :value="p.id">{{p.variant_name}}</option>
        </select>
      </td>
      <td>{{ it.price_with_tax }} Kč {{perioda(it.months)}}</td>

    </tr>
    <tr v-if="discount">
      <td> </td>
      <td> Sleva </td>
      <td>-{{ zaokrouhleni(cart.get_total_price - cart.get_discounted_price) }} Kč </td>
    </tr>
    </tbody>
    <tfoot v-if="discount">
    <tr id="discount-price">
      <td> </td>
      <td> <b>Cena po slevě</b> </td>
      <td> <b>{{ zaokrouhleni(cart.get_discounted_price)  }} Kč </b> </td>
    </tr>
    </tfoot>
  </table>
</template>

<style scoped>

</style>