<script>

import axios from "axios";

import {toast} from "vue3-toastify";

export default {
  name: "billing-fields",
  data() {
    return {
      billing:{
        email: '',
        first_name: '',
        last_name: '',
        company_box: false,
        company: '',
        ic: '',
        dic: '',
        dic_dph: '',
      },
      company_box: false,
    }
  },
  computed: {
    codeIsFilled() {
      return this.discount_code
    }
  },
  methods: {
    fieldsAreFilled() {
      return !(this.billing.email != "" && this.billing.first_name != ""  && this.billing.last_name != "")
    },
    checkStudentAccount() {

      axios.get(`/shopping_cart/` ).then( response =>{
        this.subs = response.data.subscriptions
      })

    },
     isValidEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email.toLowerCase());
      },

    handleNextStep() {
      // validovat email, format
      if (!this.billing.email) {
        //this.$toasted.show("Vyplňte email", {type: 'error'});
        toast('Vyplňte email', {type: 'error'});
        return
      }
      if (!this.isValidEmail(this.billing.email)) {
        //this.$toasted.show("Neplatný formát emailu", {type: 'error'});
        toast('Neplatný formát emailu', {type: 'error'});
        return;
      }
      if (!this.billing.first_name) {
        //this.$toasted.show("Vyplňte jméno", {type: 'error'});
        toast('Vyplňte jméno', {type: 'error'});
        return
      }
      if (!this.billing.last_name) {
        //this.$toasted.show("Vyplňte příjmení", {type: 'error'});
        toast('Vyplňte příjmení', {type: 'error'});
        return
      }
      this.$emit('next-step', this.billing);
    }
  }
}

</script>

<template>
  <div id="shoping-cart-step-1">
    <div class="row">

      <div class="itemFU1 col-md-12">
        <div class="hh">Fakturační údaje (povinné)</div>
      </div>

      <div class="item col-md-6" id="billing_first_name_field">
        <label for="billing_first_name" class="">Jméno&nbsp;<abbr class="required"
                                                                  title="vyžadováno"> </abbr></label>
        <span class="woocommerce-input-wrapper"><input type="text" class="input-text" v-model="billing.first_name"
                                                       autocomplete="given-name"/></span>
      </div>

      <div class="item col-md-6" id="billing_last_name_field">
        <label for="billing_last_name" class="">Příjmení&nbsp;<abbr class="required"
                                                                    title="vyžadováno"> </abbr></label>
        <input type="text" class="input-text" v-model="billing.last_name" autocomplete="family-name"/>
      </div>

      <div class="item col-md-6" id="billing_email_field">
        <label for="billing_email" class="">Email&nbsp;<abbr class="required" title="vyžadováno"> </abbr></label>
        <span class="woocommerce-input-wrapper"><input type="email" class="input-text" v-model="billing.email"
                                                       autocomplete="email username"/></span>
      </div>

    </div>

    <div class="itemFU2 mt-3">
      <a href="#" @click.prevent="company_box=!company_box">Zadat firemní údaje (nepovinné)</a>
    </div>

    <div class="row" v-if="company_box">

      <div class="item col-md-6" id="billing_company_field" data-priority="">
        <label for="billing_company" class="screen-reader-text">Společnost&nbsp;<span
            class="optional"></span></label>
        <span class="woocommerce-input-wrapper"><input type="text" class="input-text" v-model="billing.company"
                                                       id="billing_company" value=""/></span>
      </div>

      <div class="item col-md-6" id="billing_ic_field" data-priority="31">
        <label for="billing_ic" class="">IČO&nbsp;<span class="optional"> </span></label>
        <span class="woocommerce-input-wrapper"><input type="text" class="input-text"
                                                       v-model="billing.ic"/></span>
      </div>

      <div class="item col-md-6" id="billing_dic_field" data-priority="31">
        <label for="billing_dic" class="">DIČ&nbsp;<span class="optional"> </span></label>
        <span class="woocommerce-input-wrapper"><input type="text" class="input-text" v-model="billing.dic"
                                                       id="billing_dic" value=""/></span>
      </div>

      <div class="item col-md-6" id="billing_dic_dph_field" data-priority="31">
        <label for="billing_dic_dph" class="">IČ DPH&nbsp;<span class="optional"> </span></label>
        <span class="woocommerce-input-wrapper"><input type="text" class="input-text" v-model="billing.dic_dph"/></span>
      </div>

    </div>


    <div class="row">

      <div class="col-md-12" style="margin-top: 40px; margin-bottom: 40px; padding-right: 60px">
        <hr>
      </div>

    </div>

    <div class="row" style="padding-right: 50px">
      <div class="item text-right">
        <button type="button" @click="handleNextStep" :disabled="fieldsAreFilled()">Pokračovat  </button>
      </div>
    </div>



  </div><!-- shoping-cart-step-1 -->

</template>

<style scoped lang="scss">

@media only screen and (max-width: 768px) {
  label {
    text-align: right;
  }
}

label {
  width: 100px;
  text-align: right;
  display: inline-flex;
}

.container {
  display: flex;
  flex-wrap: wrap;
}

.item {
  flex-grow: 1;
  padding: 10px 10px 0px 10px;
}

.itemFU1 {
  flex-grow: 1;
  padding: 0px 10px 10px 13px;
}

.itemFU2 {
  flex-grow: 1;
  padding: 10px 10px 10px 0px;
}

.paymentData {
  background-color: lightcyan;
  padding-bottom: 20px;
}

input {
  background-color: white;
  width: 200px;
}

input:focus {
  outline: none !important;
  border-color: #37A8D6;
  border-style: solid;
  box-shadow: 0 0 2px #37A8D6;
}

.input-code {
  width: 120px;
}

.code-button {
  background-color: #37A8D6;
  border-radius: 2px;
  border: none;
  height: 28px;
  color: white;
  text-align: center;
  font-family: 'Proxima Nova', sans-serif;
  font-size: 0.8rem;
  //font-weight: bold;
  //text-transform: uppercase;
  margin-left: 10px;
  padding: 0px 9px 0px 9px;
}

button {
  background-color: #37A8D6;
  color: white;
  font-size: 1rem;
  border-radius: 20px;
  border: none;
  padding: 5px 20px 5px 20px;
  margin-left: 5px;
  //text-transform: uppercase;
  font-weight: bold;
}

button:disabled,
button[disabled] {
  background-color: lightgrey;
  color: white;
}

select {
  padding: 3px 7px 3px 7px;
}

input[type="checkbox"] {
  width: 10px;
  margin-left: 4px;
}


</style>