<script>
export default {
  name: "AssignTextToImage" ,
  props: ['lesson'],
  data() {
    return {
      d: this.lesson.exercise.data_object,
    }
  },
  watch:{
    lesson(){
      this.d = this.lesson.exercise.data_object
    }
  },


}
/*
* {
  "images": {
    "1": {
      "id": 1,
      "image": "/galleries/users/55/Pixmac000053768945.jpg",
      "text": [
        "sushi with tuna and salmon"
      ],
      "wrong": [
        "sushi with pork"
      ]
    },
    "2": {
      "id": 2,
      "image": "/galleries/users/55/Pixmac000055021005.jpg",
      "text": [
        "crispy fried chicken"
      ],
      "wrong": [
        "crispy baked chicken"
      ]
    },
    "3": {*/

</script>

<template>
  <div class="well">
    <div class="row-fluid">
      <div class="span6">
        <div class="control-group"><label for="aboveText" class="control-label optional">Text nad cvičením</label>
          <div class="controls">
            <textarea v-model="lesson.exercise.aboveText"  name="aboveText" id="aboveText" rows="8" class="span12" cols="80">Je čas, abyste si nacvičili správný pravopis nových slov. Poslechněte si nahrávku a napište slova do křížovky.</textarea>
            <p class="help-block" style="color: #999;">Text, který se zobrazí nad cvičením. Měl by obsahovat zadání cvičení, kde studenta informujeme o tom, co od něho ve cvičení požadujeme a jak má postupovat.</p></div></div>
      </div>
      <div class="span6">
        <div class="control-group"><label for="secondaryAboveText" class="control-label optional">Sekundární text nad cvičením</label>
          <div class="controls">
            <textarea v-model="lesson.exercise.secondaryAboveText" name="secondaryAboveText" id="secondaryAboveText" rows="8" class="span12" cols="80"></textarea>
            <p class="help-block" style="color: #999;">Sekundární text nad cvičením - slouží zejména pro text ve vyučovaném jazyce</p></div></div>
      </div>
    </div>
  </div>


  <div class="controls">
    <select name="type" id="type" v-model="d.type">
      <option value="fillin"             label="Vyplnění bez možností">Vyplnění bez možností</option>
      <option value="dragdrop" label="Vyplnění pomocí drag &amp; drop">Vyplnění pomocí drag &amp; drop</option>
    </select>
    <p class="help-block" style="color: #999;">Touto volbou můžete ovlivnit, jakým způsobem bude student vyplňovat vybrané mezery v textu.</p>
  </div>



<div id="exerciseImages" class="clearfix">



  <div class="imageWrapper" v-for="t in d.images">
    <div class="btn-group">
      <a data-imageid="1" data-valueelmid="imageSrc_1" href="/image-gallery/modal" title="Vybrat obrázek" class="selectImageAction btn btn-info btn-mini"><i class="icon-picture icon-white"></i></a>
      <a href="#" title="Odebrat obrázek a text ze cvičení" class="deleteImageAction btn btn-danger btn-mini"><i class="icon-remove-sign icon-white"></i></a>
    </div>

    <input id="imageSrc_1" class="imageSrc" type="hidden" name="imageSrc[1]" value="/galleries/users/55/Pixmac000053768945.jpg">
    <div class="image">
      <a href="" class="cboxElement">
        <img :src="`https://editor.onlinejazyky.cz${t.image}`" width="250" height="150" />
      </a>
    </div>
    <label for="imageText_1">Správné volby:</label>
    <textarea id="imageText_1" name="imageText[1]" v-model="t.text[0]"></textarea>
    <label for="wrongText_1">Špatné volby:</label>
    <textarea id="wrongText_1" name="wrongText[1]" v-model="t.wrong[0]"></textarea>

  </div>


</div>


  <pre>
  {{d}}

    </pre>




</template>

<style scoped>
.imageWrapper {
  padding: 10px;
  margin: 5px 10px 10px 0;
  width: 250px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  background: #fafafa;
  position: relative;
  float: left;
}
.imageWrapper .btn-group {

    position: absolute;
    right: 5px;

}
.imageWrapper .image {

    height: 150px;
    margin: 5px 0 10px 0;
    overflow: hidden;

}
.imageWrapper .imageText, .imageWrapper textarea {

    width: 242px;

}
.btn-mini {

    padding: 2px 6px;
    font-size: 11px;
    line-height: 17px;

}
</style>