import {add_vueApp2} from "@/packs/vue_helper.js";

import OjCourses from "./courses/courses.vue"
import OjCourse from "./courses/course.vue";
import FloatingVue from 'floating-vue'
import "./editor.scss"
import 'floating-vue/dist/style.css'
import { createRouter, createWebHistory } from 'vue-router';

import routes from './editor-routes.js'; // Assuming you have a routes.js file

const router = createRouter({
  history: createWebHistory(),
  routes
});




add_vueApp2('app-oj-courses', OjCourses)
add_vueApp2('app-oj-course' , OjCourse, [FloatingVue, router])