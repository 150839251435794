<script>
import axios from "axios";
import SelectCourse from "../../select-course.vue";

export default {
  name: 'FilterCourseId',
  props: ['value', 'filter'],
  components: {SelectCourse},
  data(){
    return {
      courses: [],
      selected_courses: [],
      courses_id_in: []
    }
  },
  mounted() {

  },
  watch:{
    selected_courses(){
      //this.$emit('input', this.selected_courses)
    },
    value(){
      this.selected_courses = this.value
    }
  },
  methods:{
    couseSelect(kurzy){
      console.log('kurzy on select v kompon', kurzy)
      let a = []
      for(let  i in kurzy){
        a.push(kurzy[i].id)
      }
      this.courses_id_in = a

      this.$emit('input', this.filter, a)
    }

  }
}


</script>

<template>
  <div class="form-group">
    <label>Obsahuje kurz</label>
    <select-course  @couseSelect="couseSelect"   :multiple="true" :course-id="courses_id_in"  name="q[courses_id_in][]" ref="q_courses_id_in"></select-course>
  </div>
</template>

<style scoped>

</style>