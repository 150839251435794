<template>
<div class="cc-calendar">

  <div class="top-button-group" >
      <div v-if="admin">
       <!-- verejny (modre, bile pismo), zverejnit upravy (zlute, bile pismo)-->
        <button class="btn btn-public" v-if="allPublic"><span class="material-icons-outlined mia">schedule</span>veřejný </button>

        <span v-else-if="canBublish">

          <span class="scheduled-public" v-if="publish_plan"><span class="material-icons-outlined mia">watch_later</span> Zveřejní se {{enqueued_at_formated()}} </span>
          <button v-else class="btn btn-can-public"  @click.prevent="handlePublicate">
            <span class="material-icons-outlined mia">watch_later</span>Zveřejnit</button>
        </span>

        <button class="btn btn-neverejny" v-else><span class="material-icons-outlined mia">visibility_off</span> Neveřejný</button>
      </div>

      <div v-if="student_id">
          <a :href="`https://student.onlinejazyky.cz?token=${this.usertoken}`" target="_blank" class="btn btn-light">studovna</a>
          <button class="btn btn-public mr-2" @click.prevent="handleStudentProfil">Profil</button>
          <button class="btn btn-public mr-2" @click.prevent="handleStudentLessons">Moje lekce</button>
          <button class="btn btn-small mr-2" @click.prevent="handleLogout">Odhlásit</button>
      </div>

      <div v-if="!student_id && !user_id">
          <a href="/login" class="btn btn-public" >Přihlásit se</a>
      </div>

  </div>



  <div class="week-navigator" >
    <div class="cursor-pointer" >
      <a href="#" @click.prevent="weekBack"><span class="material-symbols-outlined">arrow_back</span> Předchozí týden</a>
    </div>
    <div class="text-center" :key="hu">{{thisWeekName()}}</div>
    <div class="text-right cursor-pointer" >
      <a href="#" @click.prevent="weekForward"> Další týden <span class="material-symbols-outlined">arrow_forward</span></a>
    </div>
  </div>


  <div class="calnendar-header">
    <div style="margin:10px 10px 10px 20px">
      Úroveň: <select class="border-0" v-model="filterLevel">
      <option value="0">Všechny</option>
      <option value="1">Level 1</option>
      <option value="2">Level 2</option>
      <option value="3">Level 3</option>
      <option value="4">Level 3-4</option>
    </select>
    </div>

      <div class="ml-4">
          Lekce:
          <select class="border-0" style="margin:10px" v-model="filterMy">
              <option value="all">Všechny</option>
              <option value="my">Moje</option>
          </select>

      </div>

    <div class="ml-auto" v-if="admin">

      <button class="btn btn-sm " @click.prevent="handleSaveAsTemplate">Uložit rozvrh jako vzor</button>
      <button class="btn btn-sm " @click.prevent="handlLoadTemplate">Přidat existující rozvrh</button>
      <button class="btn  btn-sm btn-info pr-3" @click.prevent="handleCreateLesson">
        <span class="material-icons  mia">add_circle</span>  Vytvořit lekci</button>

    </div>

    <div class="ml-auto" v-if="student_id">

      <div class="d-flex">
      <div style="margin:10px 20px 10px 10px; font-weight: bolder">Počet využitých lekcí tento týden: {{lessons_used()}} / {{current_student_week_limit}}</div>
     <!-- <button class="btn  btn-sm btn-info pr-3" style="margin:4px" @click.prevent="calendarSync">Google kalendář</button>-->
      </div>

    </div>

  </div>

  <div class="alldays">
    <div class="hour-axis">
      <div class="day-header"></div>
      <div v-for="h in 14" class="hour-row">
        <span class="hour-name">{{h + 7}}:00</span>
      </div>
    </div>
    <div class="week-day " v-for="d in wdays()">
      <div class="day-header">{{d.format('dddd  DD.')}}</div>
      <div v-for="h in 14" :class="`hour-row hour-box hour-box-h${h}`" @click.prevent="handleHourBoxClick(d, h+7)">
        <div v-for="l in filteredLessons(d, h+7)" :class="`lesson-item level${l.level} ${l.conversation_club_topic_id > 0 ? '':'notopic'}  ${!admin && user_id>0 && user_id!=l.lector_id && !student_id ? 'cizi-lekce':''}  ${current_student_subscribed_css(l)}`" @click.stop="handleLessonClicked(l)">
            <div class="lesson-text">
                <vue-custom-tooltip :label="`${l.topic_name} ${l.native_speaker ? ', rodilý mluvčí':''}`"  ><div class="lesson-topic">{{l.topic_name}}</div></vue-custom-tooltip>
            <div>
              <i>{{ l.lector_name || '?' }}</i>
            </div>
              level {{l.level}}
          </div>

          <div class="lesson-icobox">
            <img :src="l.profile_image_url" />
            <div>
              {{l.students_count}}/{{ l.capacity }}
            </div>
            <span class="lesson-subscribed-indicator">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round">
  <polyline points="20 6 9 17 4 12"></polyline>
</svg>
            </span>
          </div>
        </div>
      </div>
    </div>

  </div>

    <lesson-form ref='modelLesson' v-if="user_id>0"
                 :lesson="current_l"
                 @close="lesson_students=[]"
                 @calendarUpdated="loadLessons"
                 :lesson_students="lesson_students"
                 :student_id="student_id"
                 :admin="admin"
                 :wdays="wdays()"></lesson-form>


    <lesson-detail-student ref="modalLessonStudent"
                           :lesson="current_l2"
                           :student_id="student_id"
                           :lesson_students="lesson_students"
                           :wdays="wdays()"
                           :jsem_hocikde="jsem_hocikde()"
        @subscriptionsChanged="onSubscriptionsChanged"
    ></lesson-detail-student>

 <modal-save-template ref="modalSave" :lessons="lessons" :week="current_week_start"></modal-save-template>
 <modal-load-template ref="modalLoad" @select="onLoadTemplateSelect" :week="current_week_start"></modal-load-template>
 <modal-kk-student :student="current_student" ref="studentDetail" :admin="false"></modal-kk-student>
 <modal-sudent-lessons ref="studentLessons"></modal-sudent-lessons>
  <begginers ref="dialog_first_contact" @openProfil="handleStudentProfil" :student="student_id"  ></begginers>
  <modal-publicate ref="modalPublicate" @submit="submitPublicate"></modal-publicate>
  <a @click.prevent="connectGoogleCalendar">.</a>
</div>


</template>

<script>

// https://www.sketch.com/s/9ace4a9d-ef82-437d-b2f6-cbc08c891407

import moment from "moment/moment";
import LessonForm from "./lesson-form.vue";
import { VueFinalModal } from 'vue-final-modal'
import axios from "axios";
import ModalSaveTemplate from "./modal-save-template.vue";
import ModalLoadTemplate from "./modal-load-template.vue";
import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip'
import LessonDetailStudent from "./lesson-detail-student.vue";
import modalKkStudent from "./modal-kk-student.vue";
import ModalSudentLessons from "./modalSudentLessons.vue";
import lessonFormMixin from "./lesson-form-mixin";
import Begginers from "./Begginers.vue";
import ModalPublicate from "./modal-publicate.vue";
export default {
  name: "cc-calendar",
  props:['usertoken'],
  components: {
    ModalSudentLessons,
    modalKkStudent,
    LessonDetailStudent, ModalLoadTemplate, ModalSaveTemplate, LessonForm, VueFinalModal,VueCustomTooltip, Begginers, ModalPublicate },
  mixins:[lessonFormMixin],
  data(){
      return {
        current_student_week_limit: 1,
        filterMy:'all',
        lesson_students:[],
        student_name:'',
        current_l: {},
        current_l2:{},
        lessons: [],
        admin: false,
        student_id: null,
        user_id: null,
        hu:9000,
        filterLevel:0,
        current_student:{},
        publish_plan: null,

        current_week_start: moment().startOf('week'),
        google_calendar: {}
      }
  },
  computed:{
    canBublish(){
      let ok = true
      for(let i in this.lessons){
        const l = this.lessons[i]
        if (!l.conversation_club_topic_id) ok = false
      }
      return ok
    },
    allPublic(){
      if (this.lessons.length == 0) return false
      for(let i in this.lessons){
        const l = this.lessons[i]
        if (!l.public) return false
      }
      return true
    }


  },
  mounted(){



  },
  created() {
    this.loadLessons()
  },
  watch:{
    filterLevel(){
      this.loadLessons()
    }
  },

  methods:{
    connectGoogleCalendar(){
      window.open(this.google_calendar.connect_url, '_blank')
    },
    enqueued_at_formated(){
      if (!this.publish_plan) return ''
      return moment(this.publish_plan.enqueued_at).format('DD.MM. HH:mm')
    },
    jsem_hocikde(){
      let c = 0
      for(let j in this.lessons){
        if (this.lessons[j].current_student_subscribed) c++
      }
      return c >= this.current_student_week_limit
    },
    current_student_subscribed_css(l){
      if (this.admin) return ""

      let s =''
      if (l.current_student_subscribed){
        s = 'subscribed'
      }

      if (!this.jsem_hocikde()) return s
      if (!l.current_student_subscribed) return "not-subscribed"

      return s
    },

    onSubscriptionsChanged(students, subs){
      this.current_l.students_count = students.length
      this.current_l.current_student_subscribed=subs
    },

    handleStudentProfil(){

      axios.get(`/conversation_club/students/me`).then(response => {
        this.current_student = response.data.student
        this.$refs.studentDetail.showModal()
      })
      //this.current_student = s

    },

    handleStudentLessons(){
      this.$refs.studentLessons.showStudent({id: this.student_id})
    },

    handleHourBoxClick(d, h){
      if (! this.admin) return

      this.current_l = {
        level: this.filterLevel>0 ? this.filterLevel : 1,
        name:"Lekce",
        capacity:  5,
        students:[],
        hour: h,
        day: d.format('YYYY-MM-DD'),
      }

      this.$refs.modelLesson.showModal()


    },
    onLoadTemplateSelect(created){

      for (let c in created){
        this.lessons.push(created[c])
      }

    },

    handleSaveAsTemplate() {
      this.$refs.modalSave.showModal()
    }
    ,

    handlLoadTemplate(){
      this.$refs.modalLoad.showModal()
    }
,
    handleLessonClicked(l){
      this.current_l=l

      if (!this.user_id && !this.student_id) return

      if (l.id > 0) {
        axios.get(`/conversation-clubs/lesson/${l.id}`).then(response => {
          this.lesson_students = response.data.students
          this.current_l2= response.data.lesson
        })
      }

      if (this.user_id>0) {
        this.$refs.modelLesson.showModal()
      }else{
        this.$refs.modalLessonStudent.showModal()
      }
    },

    thisWeekName(){
      var d1 = this.current_week_start
      var d2 = this.current_week_start.clone().endOf('week');

      return `${d1.format('DD. MMMM')} - ${d2.format('DD. MMMM YYYY')}`
    },




    weekBack(){
      this.current_week_start.add(-1, 'week')
      this.hu++

      this.loadLessons()
    },
    weekForward(){
      this.current_week_start.add(1, 'week')
      this.hu++
      this.loadLessons()
    },

    filteredLessons(d, h){
      const arr = []
      for(let i in this.lessons){
        const l = this.lessons[i]
        if (l.day == d.format('YYYY-MM-DD')  && h == l.hour){

          if (this.filterMy=='my' && !l.current_student_subscribed && l.lector_id!=this.user_id) continue

          arr.push( l)
        }
      }
      return arr
    },


    handleCreateLesson(){
      this.current_l = {
        level:1,
        name:"Lekce",
        capacity:  5,
        students:[]
      }

      this.$refs.modelLesson.showModal()
    },

    loadLessons(){
      axios.get('/conversation-clubs/lessons', {
        params:{
          week: this.current_week_start.format('YYYY-MM-DD'),
          level: this.filterLevel
        }
      }).then(response => {
        this.admin = response.data.adm
        this.user_id = response.data.current_user_id
        this.student_id = response.data.current_student
        this.student_name = response.data.student_name
        //this.current_student_introflag = response.data.current_student_introflag
        this.current_student_week_limit = response.data.current_student_week_limit


        this.publish_plan = response.data.plans.length >0 ? response.data.plans[0] : null

        this.lessons = response.data.lessons
        for(let i in this.lessons ){
          this.lessons[i].students=[]
        }


        if (!response.data.adm && !response.data.current_user_id && !response.data.current_student_introflag && this.student_id) {
          this.$refs.dialog_first_contact.showModal()
        }

        this.google_calendar = response.data.google_calendar

      })
    },

    handlePublicate(){
      /*

        Tlačítko Zveřejnit rozvrh by rozvrh rovnou nezveřejnilo, ale zobrazilo by se popup okno, ve kterém bychom mohli nastavit datum a čas toho, kdy se to zveřejní.
        Příklad: Bude pondělí, zadáme si rozvrh a víme že je správně, zároveň víme že nebudeme ve čtvrtek u počítače, protože máme dovolenou (nebo na to zapomeneme), ale chceme, aby se studentům zobrazil až ve čtvrtek od 13:00 a naechceme rozvrh udělat viditelný už v to pondělí. Klikneme tedy na zveřejnit, kde by byla volba:
        1) "Zveřejnit hned"
        2) "Nastavit datum a čas zveřejnění".
      */
      this.$refs.modalPublicate.showModal()
    },

    submitPublicate( publishType, publishTime) {
      axios.put('/conversation_club/lessons/gopublic', {
        week: this.current_week_start.format('YYYY-MM-DD'),
        publish_type: publishType,
        publish_time: publishTime
      }).then(response => {

        this.lessons = response.data.lessons
        for(let i in this.lessons ){
          this.lessons[i].students=[]
        }

        if (publishType == 'later') {
          this.publish_plan = {
            enqueued_at: publishTime
          }
        }


        this.$refs.modalPublicate.hideModal()
      })
    }


,
    handleLogout(){
      axios.get('/student_session/destroy').then(response => {
        location.href='/'
      })
    },

    calendarSync() {
      axios.get('/conversation_club/students/google_connect_url.json').then(response => {
        window.open(response.data.url, '_blank')
      })
    },
    lessons_used(){
      let c = 0
      for(let i in this.lessons){
        if (this.lessons[i].current_student_subscribed) c++
      }
      return c
    }


  }
}
</script>
