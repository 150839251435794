<script>
export default {
  name: "top30sellers",
  props: ['items'],
}
</script>

<template>
    <table class="table" v-if="items">
      <thead>
      <tr>
        <th></th>
        <th>Produkt</th>
        <th>počet</th>
        <th>suma</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in items">
        <td>{{index+1}}</td>
        <td>{{ item[1].name }}</td>
        <td>{{ item[1].count }}</td>
        <td>{{ item[1].total }}</td>
      </tr>
      </tbody>
    </table>
</template>

<style scoped>

</style>