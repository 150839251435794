<script>

import AppTable from "../form-generator/AppTable.vue";
export default {
  name: "students",
  components: {
    AppTable,
  },
  data() {
    return {
      app:{
        name:'students',
        data_url: '/students2.json',
        hidden_id: true,
      },
      fields: [
        {
          label: 'Jméno',
          codename: 'name',
          display_filter:'link',
          link_url: '/students/:id'
        },
        {
          label: 'Příjmení',
          codename: 'surname',
          display_filter:'link',
          link_url: '/students/:id'
        },
        {
          label: 'E-mail',
          codename: 'email',
          display_filter:'link',
          link_url: '/students/:id'
        }, // E-mail 	Kurzy 	Komunikační jazyk 	Brand 	Zdroj 	Akce


        {
          label: 'Komunikační jazyk',
          codename: 'language_name',
        },
        {
          label: 'Brand',
          codename: 'brand',
        },
        {
          label: 'Zdroj',
          codename: 'source',
        },
        {
          label: 'Kurzy',
          codename: 'courses_count',
        }

      ],
      filters:[
        {
          type: 'search'
        },
        {
          label: "V organizaci",
          type: "select",
          codename: "in_organization",
          default:'',
          options: [
              {label: '- nefiltrovat -', value: ''},
              {label: 'Ano', value: 'true'},
              {label: 'Ne', value: 'false'}
          ]
        },

        {
          label: "Partner",
          type: "select",
          codename: "partner_company",
          default:'',
          options: [
              {label: '- nefiltrovat -', value: ''},
              {label: 'glossa', value: 'glossa'},
              {label: 'rentel', value: 'rentel'}
          ]
        }
      ]
    }
  },
  methods: {
    openDetail(item) {
      window.location.href = '/students/' + item.id
    }
  }

}
</script>

<template>
  <div class="card card-body index p-0">
    <AppTable :app="app"
              :fields="fields"
              :default_per="50"
              :enable_search="true"
              :filters="filters"
              :actions="[{label:'Detail', method: openDetail}]" />

  </div>
</template>

<style scoped>

</style>