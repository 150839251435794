<script>
import axios from 'axios'
import ChapterForm from "@/components/oj/courses/chapter-form.vue";
import WeekForm from "@/components/oj/courses/week-form.vue";

export default {
  name: "structure",
  props:['course'],
  components: {ChapterForm, WeekForm},
  data(){
    return {

      chapters:[],
      current_chapter:{},
      current_week:{},
    }
  },
  methods:{
    loadChapters(){
      if (!this.course.id) return
      axios.get('/oj/courses/'+this.course.id+'/chapters.json').then(r=>{
        this.chapters = r.data.chapters
      })
    }
    ,
    handleEditChapter(chapter){
      this.$emit('edit-chapter',chapter)
      this.current_chapter = chapter
      this.$refs.modalChapter.showModal()

    },
    handleEditWeek(week){
      this.current_week = week
      this.$refs.modalWeek.showModal()
      this.$emit('edit-week',week)
    },
    editWeekLessons(week){
      this.current_week = week
      this.$emit('editWeekLessons',week)
    }
  },
  mounted(){
    this.loadChapters()
  },
  watch:{
    course(){
      this.loadChapters()
    }
  }
}
/* chapter:   {
    "id": 577,
    "course_id": 62,
    "name": "1. Travelling",
    "author_id": 56,
    "approver_id": null,
    "created": "2012-08-08T13:53:15.000+02:00",
    "approved": null,
    "is_approved": false,
    "is_exam": false,
    "lesson_id": 1823,
    "image": "/galleries/users/56/Pixmac000083946937.jpg",
    "description": null,
    "is_last_in_course": false,
    "order": null,
    "adaptive_exercise_count": null,
    "adaptive_exercise_success": null,
    "is_active": 1,
    "allow_feedback": 1
  }

 */
</script>

<template>
  structure:
  <table>

    <h3>{{course.name}}</h3>

    <tbody>
      <tr v-for="chapter in chapters" :key="chapter.id" class="chapter">
        <td class="images">

          <img :src="`https://editor.onlinejazyky.cz${chapter.image}`" alt="chapter.name" width="100" v-if="chapter.image"/>
          <div v-else class="nahrada-za-obrazek"></div>

        </td>
        <td><h3><a href="#" @click.prevent="handleEditChapter(chapter)">{{chapter.name}}</a></h3></td>
        <td>
          <div v-for="week in chapter.weeks">
            <a :href="`#week${week.id}`" @click.prevent="handleEditWeek(week)">{{week.name}}</a>
            <router-link  :to="{ name: 'lessons', params: { cid: course.id, wid: week.id }}">
              <span class="material-icons-outlined">mode_edit_outline</span>
            </router-link>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <pre>
  {{chapters}}

</pre>


  <chapter-form :chapter="current_chapter" ref="modalChapter"></chapter-form>
  <week-form :week="current_week" ref="modalWeek"></week-form>
  <lessons :week="current_week"></lessons>
</template>

<style scoped lang="scss">

tr.chapter{
  border:1px solid #ccc;
  margin:10px;
  padding:10px;
  td{
    padding:10px;
    h3{
      margin:0 20px;
    }
  }
}

.nahrada-za-obrazek{
  width:100px;
  height:100px;
  background-color: #eee;
}

td.images{
  padding:10px;
}
</style>