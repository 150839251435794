<script>
import axios from "axios";
import CourseOptions from "./course-options.vue";
import CourseStructure from "./structure.vue";
import Lessons from "@/components/oj/courses/lessons.vue";
import {LineWrapper} from "vue-advanced-cropper";
export default {
  name: "course",

  components: {LineWrapper, CourseOptions, CourseStructure, Lessons},
  data(){
    return {
      cid: this.$route.params.cid,
      week:{},
      course:{},
    }
  },
  watch: {
    '$route.params.cid': 'loadCourse'
  },
  methods:{
    async loadCourse(){

      let cid = this.$route.params.cid
      if (!cid) return

      console.log('loadCourse' , cid)
      const r2 = await axios.get(`/oj/courses/${cid}.json`,{ params:{}})
      this.course = r2.data
    },


    handleEditLessons(week){
      this.week = week
      this.p = 3
    }


  },
  mounted() {
    this.loadCourse()
  }
}

</script>
<template>
  <div class="oj-editor">
    <ul class="nav">
      <li class="nav-item">
         <router-link class="nav-link "  :to="`/oj/courses/${this.course.id}`" active-class="active">Struktura</router-link>
      </li>

      <li class="nav-item">
        <router-link class="nav-link " :to="`/oj/courses/${this.course.id}/options`" active-class="active">Nastavení</router-link>
      </li>
    </ul>
    <router-view :course="course"></router-view>
  </div>
</template>

<style scoped>

a.active {
  background-color: #5aacff;
  color: white;
}
</style>