<script>
import SubscriptionStats from "./subscription-stats.vue";
import axios from "axios";
import Subscription1m from "./subscription-1m.vue";
import Subscriptions6m from "./subscriptions-6m.vue";
import moment from "moment/moment";
export default {
  name: "page-subscriptions",
  components: {Subscriptions6m, Subscription1m, SubscriptionStats},
  data() {
    return {
      subs: [],
      subs_eur: [],
    };
  },
  created() {
    this.fetchSubs()
  },
  methods:{
    fetchSubs() {
      axios.get("/students_stats/subs")
          .then(response => {
            this.subs = response.data.months;
            this.subs_eur = response.data.months_eur;
          })
          .catch(error => {
            console.log(error);
          });
    },
  }
}
</script>

<template>
  <div>

    <div class="row" style="padding-top: 50px;">
      <h1 class="col flex-grow-1 heading">Předplatné - přehled a analýza životnosti</h1>
    </div>

    <hr>


    <h4 class="title pad20">Měsíční předplatné v CZK</h4>
    <subscription1m :stats="subs"></subscription1m>
<!--
    <h4 class="title pad20">Měsíční předplatné v EUR</h4>
    <subscription1m :stats="subs_eur"></subscription1m>
-->

    <h4 class="title pad20">Půlroční předplatné v CZK</h4>
    <subscriptions6m :stats="subs" :period="6"></subscriptions6m>
    <h4 class="title pad20">Půlroční předplatné v EUR </h4>
    <subscriptions6m :stats="subs_eur" :period="6"></subscriptions6m>


    <h4 class="title pad20">Roční předplatné v CZK</h4>
    <subscriptions6m :stats="subs" :period="12"></subscriptions6m>

    <h4 class="title pad20">Roční předplatné v EUR</h4>
    <subscriptions6m :stats="subs_eur" :period="12"></subscriptions6m>
<!--
    <h4 class="title pad20">Předplatné v CZK</h4>

    <subscription-stats :stats="subs"></subscription-stats>



    <h4 class="title pad20">Předplatné v EUR</h4>

    <subscription-stats :stats="subs_eur"></subscription-stats>
-->


  </div>
</template>

<style scoped>

.pad20 {
  padding-top: 20px;
}

</style>