<script>


import TopSellingTable from "./top-selling-table.vue";
import studentStatsMixin from "./student-stats-mixin";
import axios from "axios";
import Top30sellers from "./top30sellers.vue";
export default {
  name: "page-topsellers",
  components: {Top30sellers, TopSellingTable},
  mixins: [studentStatsMixin],
  data() {
    return {
      top_selling_czk:[],
      top_selling_eur:[],
      y_czk:[],
      y_eur:[],
      loading: true,
    };
  },
  created() {
    this.fetch_top_selling()
  },
  methods:{
    fetch_top_selling() {
      axios.get("/students_stats/top_selling")
          .then(response => {
            this.top_selling_czk = response.data.months_czk;
            this.top_selling_eur = response.data.months_eur;

            this.y_eur = response.data.y_eur;
            this.y_czk = response.data.y_czk;
            this.loading = false;

          })
          .catch(error => {
            console.log(error);
          });
    },

  }
}
</script>

<template>
  <div class="col-md-auto box">

    <div class="row" style="padding-top: 50px;">
      <h1 class="col flex-grow-1 heading">Nejprodávanější produkty</h1>
    </div>

    <hr>

    <h4 class="title pad20">Za poslední 3 měsíce v CZK</h4>
    <div class="card card-body">
      <top-selling-table :top_selling="top_selling_czk" v-if="!loading"></top-selling-table>
    </div>


    <h4 class="title pad20">Za poslední 3 měsíce v EUR</h4>
    <div class="card card-body">
      <top-selling-table :top_selling="top_selling_eur" v-if="!loading"></top-selling-table>
    </div>


    <h4 class="title pad20">Za posledních 365 dní v CZK</h4>

    <div class="card card-body">
      <top30sellers :items="y_czk"></top30sellers>
    </div>

    <h4 class="title pad20">Za posledních 365 dní v EUR</h4>

    <div class="card card-body">
      <top30sellers :items="y_eur"></top30sellers>
    </div>

  </div>
</template>

<style scoped>

.pad20 {
  padding-top: 20px;
}

</style>