// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
//console.log('Vite ⚡️ Rails')

document.addEventListener('DOMContentLoaded', () => {
    const submenus = document.querySelectorAll('.collapse');

    submenus.forEach(submenu => {
        const links = submenu.querySelectorAll('a');

        links.forEach(link => {
            link.addEventListener('click', () => {
                localStorage.setItem(`${submenu.id}-open`, 'true');
            });
        });

        if (localStorage.getItem(`${submenu.id}-open`) === 'true') {
            submenu.classList.add('show');
        }

        // Clear the state after the page has loaded
        localStorage.removeItem(`${submenu.id}-open`);
    });
});


// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

//console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import Rails from 'rails-ujs';
Rails.start();
//import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
//Turbolinks.start();
ActiveStorage.start();
//require("@rails/ujs").start()
//require("@rails/activestorage").start()


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//import "../controllers/index.js"




// JS - bootstrap
import "bootstrap/js/dist/collapse.js"
import "bootstrap/js/dist/dropdown.js"
import "bootstrap/js/dist/modal.js"
import "bootstrap/js/dist/button.js"

// JS - Font Awesome icons
import "@fortawesome/fontawesome-free/js/all"

// all things css
//import "../controllers/index.js"


//  3rd party / other code
//import "../lib/index.js"

// Bootstrap 4 datetime picker https://tempusdominus.github.io/bootstrap-4/
// requires moment.js package, imported as a webpacker plugin in /config/webpack/environment



import "../lib/listpress.js"


// import './new.scss'

import '../components/conversation-club/kk.scss'
import '../packs/vue_components.js'
import '../components/statistics/init.js'
import '../components/init.js'
import '../components/oj/init.js'

import 'vue-final-modal/style.css'
import 'vue-select/dist/vue-select.css';
import 'vue3-toastify/dist/index.css';


