<script >

import {VueFinalModal} from "vue-final-modal";
import FInputImage from "../fields/FInputImage.vue";
export default {
  name: "FGInputImage",
  props: ['options'] ,
  components: {
    VueFinalModal, FInputImage
  },
  data(){
    return({
      showM: false
    })
  },
  methods:{
    hideModal(){
      this.showM=false

    }
  }
}

</script>

<template>
<div>

      <input type="text" class="form-control" v-model="options.label"/>

</div>
</template>

<style scoped>

</style>