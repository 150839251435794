<template>
  <div>
[ {{pass}} / {{updated}} / {{student_id}} ]
      <div class="item" v-if="pass=='true' || updated">
        <h2>Pro přihlášení <a href="https://kk.onlinejazyky.cz/" target=”_blank”>klikněte sem.</a><br></h2>
      </div>
      <div v-else>
          <p>Dosud nemáte účet, zadejte prosím heslo, kterým se budete přihlašovat</p>

          <label>Heslo</label>
          <input type="password"  class="form-control" v-model="p1"/>


          <label>Opakovat heslo</label>
          <input type="password"  class="form-control" v-model="p2"/>

        <div class="alert alert-danger" v-if="errors.length>0">
          {{errors.join(', ')}}
        </div>

          <button class="btn btn-primary mt-1" @click.prevent="handleSetPassword" :disabled="!valid">Nastavit heslo</button>
      </div>

  </div>

</template>

<script>
import axios from "axios";

export default {
  name:'set-password',
  props: ["student_id", 'pass'],

  data() {
    return {
      updated:false,
      valid: false,
      p1:'',
      p2:'',
      errors:[]
    }
  },
  watch:{
    p1: 'validator',
    p2: 'validator'
  }
  ,
  methods:{

    validator(){
      this.errors=[]
      let v = true
      if (this.p1=='' || this.p1=='') {
        v=false
        this.errors.push('Heslo nesmí být prázdné')
      }

      if (this.p1.length<6) {
        this.errors.push('Heslo musí mít alespoň 6 znaků')
        v=false
      }

      if (this.p1!=this.p2) {
        this.errors.push('Hesla se neshodují')
        v=false
      }

      this.valid = v
    },

    handleSetPassword(){
      axios.put(`/shopping_cart/set-password`, {
        pass: this.p1,
        student_id: this.student_id
      }).then((response) => {
        this.updated=response.data.change_studovna_password

        this.$emit('set-password', 'true')
      }).catch((error) => {
        console.log(error)

      });
    }
  }



}



</script>


<style scoped>

</style>